import * as React from "react";
import SkilledProfessionalsImage from "../../../../assets/icons/Skilled Professionals1.png";
import Support24x7Image from "../../../../assets/icons/24x7-0.png";
import CareerGrowthImage from "../../../../assets/icons/Career Growth0.png";

import Nencontrollericon from "../../../../assets/images/newwhitesensexr.png";

interface WhyUsMainCompProps {}

export const WhyUsMainComp: React.FunctionComponent<WhyUsMainCompProps> = (
  props
) => {
  return (
    // <!--Why Us Start-->
    <div className="why-us py-100 bg-dark-blue">
      <div className="common-container">
        <div className="why__us__flex_box">
          <div className="why_us_main_title">
            <div className="common-row">
              <div className="common-col-12 common-sm-12 ">
                <div className="common-heading-home ">
                  <h2>
                    <span>Why</span>
                    Us?
                  </h2>
                </div>
                <p className="pwhy-text mt-10">
                  The AR-VR Course has been designed by the best team of
                  metaverse experts and developers in collaboration with iHUB
                  @IIT Roorkee. Learn on your Smartphone with the Patented XR
                  Controller. The Controller acts as 3D mouse which can be
                  programmed to become a laser pointer, a gun, a sword, a
                  paint-brush or whatever you may like.
                </p>
              </div>
            </div>
          </div>

          <div className="new__whyus_block">
            <div className="why_us_card_row d-flex align-items">
              <div className="why_before_effect"></div>
              <div className="why_us_card_col">
                <div className="common-row d-flex-between w-100">
                  <div className="common-col-9">
                    <div className="heading_set">
                      <h3>Skilled Professionals</h3>
                    </div>
                    <p className="normal-para">
                      Course Designed By IITians And Vetted By IIT Roorkee
                      Faculty
                    </p>
                  </div>
                  <div className="common-col-3">
                    <img
                      src={SkilledProfessionalsImage}
                      alt="SkilledProfessionals"
                      className="why_img"
                    />
                  </div>
                </div>
              </div>

              <div className="why_us_card_col">
                <div className="common-row d-flex-between w-100">
                  <div className="common-col-9">
                    <div className="heading_set">
                      <h3>Developer Kit</h3>
                    </div>
                    <p className="normal-para">
                      Learn With Patented 6 DoF XR Controller
                    </p>
                  </div>
                  <div className="common-col-3">
                    <img
                      src={Nencontrollericon}
                      alt="VRImage1"
                      className="why_img"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="why_us_card_row d-flex align-items flex-start">
              <div className="why_us_card_col  mb-0">
                <div className="common-row d-flex-between w-100 mb-0">
                  <div className="common-col-9">
                    <div className="heading_set">
                      <h3>Get certified </h3>
                    </div>
                    <p className="normal-para">By iHub IIT Roorkee</p>
                  </div>
                  <div className="common-col-3">
                    <img
                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/iB5cKeEwYJU6TNUU_2024-05-03T081609940296.diploma.png"
                      alt="VRImage1"
                      className="why_img"
                    />
                  </div>
                </div>
              </div>

              <div className="why_us_card_col mb-0">
                <div className="common-row d-flex-between w-100">
                  <div className="common-col-9">
                    <div className="heading_set">
                      <h3>Career Growth</h3>
                    </div>
                    <p className="normal-para">
                      Maximize Your Future Potential
                    </p>
                  </div>
                  <div className="common-col-3">
                    <img
                      src={CareerGrowthImage}
                      alt="VRImage1"
                      className="why_img"
                    />
                  </div>
                </div>
              </div>
              <div className="why_before_effect"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
