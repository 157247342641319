import { useState } from "react";
import { Link, parsePath } from "react-router-dom";
import { AuthService } from "../../../apiServices/Services/AuthServices";
import toast from "react-hot-toast";
import "../../../assets/css/required.css";
import { HandleAuthToken } from "../../core/Utils/HandleAuthToken";

import { useAppDispatch } from "../../../app/hooks";
import { lms, login } from "../../../features/auth/AuthSlice";
import { Formik, ErrorMessage, Form, Field } from "formik";
import * as Yup from "yup";
import { Login } from "../../../apiServices/Models/Auth/LoginRequest";
import {
  toggleLoader,
  handleLearn,
  sendGAEvent,
} from "../../../apiServices/Services/CommonServices";

const objAuthService = new AuthService();
const objAuthHandler = new HandleAuthToken();

interface LoginProps {
  userName: string;
  password: string;
}

const LoginComponent = (props: any) => {
  const [isLoading, setLoader] = useState(false);
  const returnUrl = parsePath(window.location.href);

  const dispatch = useAppDispatch();

  const loginUser = (values: LoginProps) => {
    const userData: Login = {
      email: values.userName,
      password: values.password,
    };
    return objAuthService.login(userData);
  };

  const closeModal = () => {
    (document.querySelector('button[id="closeModal"]') as any).click();
  };

  const handleSubmit = (values: LoginProps) => {
    setLoader(true);
    loginUser(values).subscribe({
      next: (LoggedInUser) => {
        objAuthHandler.setItem(JSON.stringify(LoggedInUser));
        dispatch(login(JSON.stringify(LoggedInUser)));
        setLoader(false);
      },
      error: (err) => {
        sendGAEvent("Login Failed", "Login_Failed", "Auth Actions");
        setLoader(false);
        if (err.response.data) {
          toast.error(err.response.data);
        } else {
          toast.error("Something went wrong, please try again!");
        }
      },
      complete: () => {
        sendGAEvent("Login Successful", "Login_Successful", "Auth Actions");
        closeModal();
        props.reRender();
        handleLearn().then((lmsUrl: string) => {
          dispatch(lms(lmsUrl));
          console.log(returnUrl.search);
          if (returnUrl.search) {
            window.location.href = lmsUrl;
          }
          window.location.href = returnUrl.pathname ? returnUrl.pathname : "/";
        });
        // }
        setLoader(false);
        toast.success("User successfully logged in!!");
      },
    });
  };

  const toggleState = (event: any) => {
    event.preventDefault();
    props.toggle("signup");
    props.toggleTitle("Sign Up");
  };

  const toggleForgot = (event: any) => {
    event.preventDefault();
    props.toggle("forgot");
    props.toggleTitle("Forgot Password");
  };

  const LoginSchema = Yup.object().shape({
    userName: Yup.string()
      .min(2, "Too Short!")
      .max(100, "Too Long!")
      .required("Required"),
    password: Yup.string()
      .min(1, "Please enter your password.")
      .required("Required"),
  });

  return (
    <>
      {toggleLoader(isLoading)}
      <div className="login-account-middle">
        <Formik
          initialValues={{
            userName: "",
            password: "",
          }}
          validationSchema={LoginSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ errors, touched }) => (
            <Form className="login-form">
              <div className="form-block">
                <label htmlFor="username" className="after-required">
                  Email Address / Mobile
                </label>
                <Field
                  name="userName"
                  type="text"
                  placeholder="email@example.com or Mobile number (10 Digits - XXXXXXXXXX)"
                  className={
                    errors.userName && touched.userName ? "error-field" : ""
                  }
                />
                <ErrorMessage
                  component="div"
                  className="errormsglabel"
                  name="userName"
                />
              </div>
              <div className="form-block">
                <label htmlFor="password" className="after-required">
                  Password
                </label>
                <Field
                  name="password"
                  type="password"
                  placeholder="Password"
                  className={
                    errors.password && touched.password ? "error-field" : ""
                  }
                />
                <ErrorMessage
                  component="div"
                  className="errormsglabel"
                  name="password"
                />
              </div>

              <div className="create-btn">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={sendGAEvent(
                    "Login Attempted",
                    "Login_Attempted",
                    "Auth Actions"
                  )}
                >
                  Sign In
                </button>
              </div>

              <div className="forget-pass-main">
                {props.hide ? (
                  <>
                    <div className="dropdown-divider"></div>
                    <div className="forget-pass">
                      <Link className="dropdown-item" to="/signup">
                        New around here?{" "}
                        <span style={{ color: "#2CABDE" }}>Sign up</span>
                      </Link>
                      <button
                        className="dropdown-item"
                        onClick={props.setShowforgotpass}
                      >
                        Forgot password?
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="dropdown-divider"></div>
                    <div className="forget-pass">
                      <button className="dropdown-item" onClick={toggleState}>
                        New around here?{" "}
                        <span style={{ color: "#007bff" }}>Sign up</span>
                      </button>
                      <button className="dropdown-item" onClick={toggleForgot}>
                        Forgot password?
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default LoginComponent;
