import * as React from "react";
import { EventMedia } from "../../../../apiServices/Models/WebsiteContent/EventPageModels/EventPageMediaResponse";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { FreeMode, Navigation, Pagination } from "swiper";
// // Import Swiper styles
import "swiper/css";
import "swiper/css/bundle";

interface EventsGalleryMainCompProps {
  eventMediaDetails: EventMedia[];
}

export const EventsGalleryMainComp: React.FunctionComponent<
  EventsGalleryMainCompProps
> = (props) => {
  const eventMediaDetails: EventMedia[] = props.eventMediaDetails;
  // console.log(eventMediaDetails);
  return (
    <Swiper
      slidesPerView={4}
      spaceBetween={10}
      freeMode={true}
      pagination={{
        clickable: true,
        type: "progressbar",
      }}
      modules={[FreeMode, Pagination, Navigation]}
      className="mySwiper"
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        640: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
      }}
    >
      {eventMediaDetails.map((eventImage, key) => {
        return (
          <SwiperSlide key={key}>
            <div className="gallery_img_col">
              <div className="inner_gallery_col">
                <img src={eventImage.imgUrl} alt="" />
              </div>
            </div>
          </SwiperSlide>
        );
      })}
      <div className="swiper-button-next"></div>
      <div className="swiper-button-prev"></div>
      <div className="swiper-pagination"></div>
    </Swiper>
  );
};
