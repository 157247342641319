import * as React from "react";
import { Link } from "react-router-dom";

export interface IThankYouPageCompoProps {}

export function ThankYouPageCompo(props: IThankYouPageCompoProps) {
  return (
    <>
      <div className="thankyoupageCompo py-100">
        <div className="innner-thankyou">
          <div className="thankyou-card">
            <div className="img-thankyou">
              <img
                src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/E6yhxftybfJaUFyD_2023-02-01T105657441277.check.png"
                alt=""
              />
            </div>
            <h2>Thank You</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit rerum
              nulla earum nobis quam eos.
            </p>
            <Link to="/">Back to home</Link>
          </div>
        </div>
      </div>
    </>
  );
}
