import * as React from "react";
import { CourseAboveFoldCardComp } from "./CourseAboveFoldCardComp";
import { CoursesAboveFoldLeftComp } from "./CoursesAboveFoldLeftComp";

interface CourseAboveFoldMainCompProps {
  courseId: string | undefined;
}

export const CourseAboveFoldMainComp: React.FunctionComponent<
  CourseAboveFoldMainCompProps
> = (props) => {
  const courseId = props.courseId;
  return (
    <div className="course-section py-100">
      <div className="common-container">
        <div className="common-row d-flex-between">
          <div className="common-col-6 common-md-7 common-sm-12">
            <CoursesAboveFoldLeftComp
              courseId={courseId}
            ></CoursesAboveFoldLeftComp>
          </div>

          <div className="common-col-5 d-flex-end common-sm-12">
            <CourseAboveFoldCardComp
              courseId={courseId}
            ></CourseAboveFoldCardComp>
          </div>
        </div>
      </div>
    </div>
  );
};
