import { Type } from "class-transformer";
import { PlayControls } from "./PlayControlsRequestModel";

export class PlayControlsResponse {
  constructor() {
    this.id = "";
    this.controls = new Array<PlayControls>();
  }
  public id: string;
  @Type(() => PlayControls)
  public controls: Array<PlayControls>;
}
