import { Type } from "class-transformer";
import { Profile } from "../Auth/ProfileModel";
import { ProductMedia } from "./ProductMediaModel";
import { ProductRelease } from "./ProductReleaseModel";

export class ProductSummary extends Array<ProductSummary> {
  constructor() {
    super();
    this.id = "";
    this.developerId = 0;
    this.name = "";
    this.developerName = "";
    this.description = "";
    this.isPublished = false;
    this.profile = new Profile();
    this.productTypeList = [];
    this.gitHubLink = "";
  }
  public id: string;
  public developerId: number;
  public name: string;
  public developerName: string;
  public description: string;
  public price?: number | undefined;
  public isPublished: boolean;
  public profile: Profile;
  public productTypeList: Array<string>;
  @Type(() => ProductMedia)
  public productMediaDto?: Array<ProductMedia> | undefined;
  public gitHubLink: string;
  @Type(() => ProductRelease)
  public releases?: Array<ProductRelease> | undefined;
}
