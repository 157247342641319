import * as React from "react";
import { Constant } from "../../../apiServices/Services/WebServiceConstants";

interface ForumProps {}
const Forum: React.FunctionComponent<ForumProps> = (props) => {
  return (
    <>
      <div className="forumBlock mx-w-1440">
        <iframe
          title="Fsorum"
          src={Constant.forumUrl}
          frameBorder="0"
          width="100%"
          className="forum-frame"
        ></iframe>
      </div>
    </>
  );
};

export default Forum;
