export class UploadPackage {
  constructor() {
    this.file = new Blob();
    this.Productid = "";
    this.releaseVersion = "";
    this.changeLog = "";
  }
  public file: Blob;
  public Productid: string;
  public releaseVersion: string;
  public changeLog: string;
}

export class UploadPackageResponse {
  constructor() {
    this.id = "";
    this.productid = "";
    this.releaseVersion = "";
    this.fileSize = "";
    this.pckg = new pckg();
    this.changeLog = "";
  }
  public id: string;
  public productid: string;
  public releaseVersion: string;
  public fileSize: string | null;
  public pckg: pckg = new pckg();
  public changeLog: string;
}

export class pckg {
  constructor() {
    this.url = "";
    this.documentType = null;
  }
  public url: string;
  public documentType: string | null;
}
