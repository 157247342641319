import { useRef, useState } from "react";
import { LocalStorage } from "../../core/Storage/LocalStorage";
import { AuthService } from "../../../apiServices/Services/AuthServices";
import toast from "react-hot-toast";
import "../../../assets/css/required.css";
import { HandleAuthToken } from "../../core/Utils/HandleAuthToken";
import * as Yup from "yup";
import { Formik, ErrorMessage, Form, Field, FormikProps } from "formik";
import { signUpUser, login, lms } from "../../../features/auth/AuthSlice";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { VerifyOtp } from "../../../apiServices/Models/Auth/VerifyOtpRequest";
import {
  handleLearn,
  sendGAEvent,
  toggleLoader,
} from "../../../apiServices/Services/CommonServices";
import { useLocation } from "react-router";

const objAuthService = new AuthService();
const objLocalStorage = new LocalStorage();
const objAuthHandler = new HandleAuthToken();

interface VerifyOtpProps {
  first: string;
  second: string;
  third: string;
  fourth: string;
}
const VerifyOTP = (props: any) => {
  const [isLoading, setLoader] = useState(false);
  // const [otp, setOtp] = useState("");
  const signUpUserData: VerifyOtp = useAppSelector(signUpUser);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const callVerifyOTP = (values: VerifyOtpProps, signUpUserData: VerifyOtp) => {
    sendGAEvent(
      "OTP Verification Started",
      "OTP_Verification_Started",
      "Auth Actions"
    );
    const otp =
      values.first +
      "" +
      values.second +
      "" +
      values.third +
      "" +
      values.fourth;
    return objAuthService.verifyOtp(signUpUserData, { otp: otp });
  };

  const validateUser = (values: VerifyOtpProps) => {
    setLoader(true);
    if (signUpUserData) {
      callVerifyOTP(values, signUpUserData).subscribe({
        next: (verifiedUser) => {
          //objLocalStorage.setItem("userData", JSON.stringify(verifiedUser))
          objAuthHandler.setItem(JSON.stringify(verifiedUser));
          objLocalStorage.removeItem("registeredUserData");
          dispatch(login(JSON.stringify(verifiedUser)));
          setLoader(false);
        },
        error: (err) => {
          sendGAEvent(
            "OTP Verification Failed",
            "OTP_Verification_Failed",
            "Auth Actions"
          );
          // props.isLoader(false);
          setLoader(false);
          if (err.response.status === 500) {
            toast.error("Service unavailable.");
          } else {
            toast.error("OTP you've entered is incorrect.", err.response.data);
          }
        },
        complete: () => {
          sendGAEvent(
            "OTP Verification Successful",
            "OTP_Verification_Successful",
            "Auth Actions"
          );
          // props.isLoader(false);
          closeModal();
          // if (props.hide) {
          handleLearn().then((lmsUrl: string) => {
            dispatch(lms(lmsUrl));
            // console.log(location.pathname);
            if (location.pathname === "/signup") {
              window.location.href = lmsUrl;
            }
          });
          setLoader(false);
          toast.success("User registration successful!!");
          // }
        },
      });
    }
  };

  const closeModal = () => {
    (document.querySelector('button[id="closeModal"]') as any).click();
  };

  const VerifyOtpSchema = Yup.object().shape({
    first: Yup.string()
      .min(1, "Too Short!")
      .max(1, "Too Long!")
      .required("Required"),
    second: Yup.string()
      .min(1, "Too Short!")
      .max(1, "Too Long!")
      .required("Required"),
    third: Yup.string()
      .min(1, "Too Short!")
      .max(1, "Too Long!")
      .required("Required"),
    fourth: Yup.string()
      .min(1, "Too Short!")
      .max(1, "Too Long!")
      .required("Required"),
  });
  const ref = useRef<FormikProps<VerifyOtpProps>>(null);

const nextBtn=()=>{
  console.log('hi');
}

  return (
    <>
      {toggleLoader(isLoading)}
      <div className="login-account-middle">
        <h2>Verify OTP</h2>
        <Formik
          initialValues={{
            first: "",
            second: "",
            third: "",
            fourth: "",
          }}
          validationSchema={VerifyOtpSchema}
          onSubmit={(values) => {
            validateUser(values);
            // same shape as initial values
            // console.log(values);
          }}
          innerRef={ref}
        >
          
          {({ errors, touched }) => (
            <Form className="verify-form">
              <div className="form-block">
                <label htmlFor="otp" className="after-required">
                  Please enter the OTP to verify your account
                </label>
                <div className="otpflex">
                  <Field
                    name="first"
                    id="first"
                    type="text"
                    maxLength="1"
                    className={
                      errors.first && touched.first ? "error-field" : ""
                    }
                    onKeyUp={nextBtn}
                  />
                  <ErrorMessage
                    component="div"
                    className="errormsglabel"
                    name="first"
                  />
                  <Field
                    name="second"
                    id="second"
                    type="text"
                    maxLength="1"
                    className={
                      errors.second && touched.second ? "error-field" : ""
                    }
                  />
                  <ErrorMessage
                    component="div"
                    className="errormsglabel"
                    name="second"
                  />
                  <Field
                    name="third"
                    id="third"
                    type="text"
                    maxLength="1"
                    className={
                      errors.third && touched.third ? "error-field" : ""
                    }
                  />
                  <ErrorMessage
                    component="div"
                    className="errormsglabel"
                    name="third"
                  />
                  <Field
                    name="fourth"
                    id="fourth"
                    type="text"
                    maxLength="1"
                    className={
                      errors.fourth && touched.fourth ? "error-field" : ""
                    }
                  />
                  <ErrorMessage
                    component="div"
                    className="errormsglabel"
                    name="fourth"
                  />
                </div>
              </div>
              {/* <div className="counter">60.00</div> */}
              <div className="clear"></div>
              <p className="otp-msg">
                The OTP has been sent to your Phone number{" "}
                <span>{signUpUserData.phoneNumber}</span>
              </p>
              <div className="create-btn">
                <button type="submit" className="btn btn-primary">
                  Validate
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default VerifyOTP;
