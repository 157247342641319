import React, { useState } from "react";
import CourseBlockImage1 from "../../../../assets/images/course-block-image-1.png";
import TimeIcon from "../../../../assets/images/course-card/time.png";
import IHubIcon from "../../../../assets/images/course-card/iHUB.png";
import { HomePageCoursesResponse } from "../../../../apiServices/Models/WebsiteContent/HomePageModels/HomePageCoursesResponse";

interface SingleCourseCardCompProps {
  course: HomePageCoursesResponse;
}

export const SingleCourseCardComp: React.FunctionComponent<
  SingleCourseCardCompProps
> = (props) => {
  const [course, setcourse] = useState(props.course);
  return (
    <>
      <div className="course-inner" key={course.id}>
        <div className="course-thumb">
          <a href={"courses/" + course.id}>
            <img
              src={course.imageUrl ? course.imageUrl : CourseBlockImage1}
              alt="CourseCardVRImage"
              className="frame_inner_img"
            />
          </a>
        </div>

        <div className="course-list-content">
          <h2 className="course-title">
            <a href={"courses/" + course.id}>{course.name}</a>
          </h2>
          <div className="course_listing">
            <p>{course.description.substring(0, 90).concat("...")}</p>
          </div>
          <div className="course_flex_box">
            <div className="span_left">
              <img src={TimeIcon} alt="TimeIcon" />
              <strong>{course.duration}</strong>
            </div>

            {/* {course.partnerName === "" ? (
              <div className="span_left"></div>
            ) : (
              <div className="span_left">
                <img src={IHubIcon} alt="IHubIcon" />
                <strong>{course.partnerName}</strong>
              </div>
            )} */}

            {course.coursePrice === "" ? (
              <div className="span_left"></div>
            ) : (
              <div className="span_left">
                {/* <img src={IHubIcon} alt="IHubIcon" /> */}
                <strong>{course.coursePrice}</strong>
              </div>
            )}
          </div>

          <div className="course_flex_box flex-end">
            {/* {course.inclusions.vrHeadset === true ? (
              <>
                <span className="left_bottom_span">VR headset included</span>
              </>
            ) : (
              <></>
            )} */}

            <span className={getCourseCssClass(course.courseLevel)}>
              {course.courseLevel}
            </span>
          </div>
          {/* 
          <div className="course_flex_box">
            {course.inclusions.controller === true ? (
              <>
                <span className="left_bottom_span">
                  6 Dof Controller included
                </span>
              </>
            ) : (
              <></>
            )}

            {course.inclusions.coding === true ? (
              <>
                <span className="left_bottom_span">Coding</span>
              </>
            ) : (
              <></>
            )}
          </div>

          <div className="course_flex_box">
            {course.inclusions.devcube === true ? (
              <>
                <span className="left_bottom_span">
                  Developer Cube included
                </span>
              </>
            ) : (
              <></>
            )}
          </div> */}

          <div className="ul_flex__core">
            <ul>
              {course.inclusions.vrHeadset === true ? (
                <>
                  <li>
                    {" "}
                    <span className="left_bottom_span">VR headset</span>
                  </li>
                </>
              ) : (
                <></>
              )}

              {course.inclusions.controller === true ? (
                <>
                  <li>
                    {" "}
                    <span className="left_bottom_span">6 DoF Controller</span>
                  </li>
                </>
              ) : (
                <></>
              )}

              {course.inclusions.coding === true ? (
                <>
                  <li>
                    {" "}
                    <span className="left_bottom_span">Coding</span>
                  </li>
                </>
              ) : (
                <></>
              )}

              {course.inclusions.devcube === true ? (
                <>
                  <li>
                    <span className="left_bottom_span">Developer Cube</span>
                  </li>
                </>
              ) : (
                <></>
              )}
            </ul>
          </div>
          <div className="course_know_btn">
            <a href={"courses/" + course.id}>Know More</a>
          </div>
        </div>
      </div>
    </>
  );
};

function getCourseCssClass(courseLevel: string): string {
  if (courseLevel.toLowerCase() === "beginner") {
    return "right_bottom_span";
  } else if (courseLevel.toLowerCase() === "intermediate") {
    return "right_bottom_span Intermediate_span";
  } else if (courseLevel.toLowerCase() === "advance") {
    return "right_bottom_span Advanced_span";
  } else if (courseLevel.toLowerCase() === "expert") {
    return "right_bottom_span expert_span";
  } else if (courseLevel.toLowerCase() === "professional") {
    return "right_bottom_span professional_span";
  }
  return "right_bottom_span";
}
