import * as React from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export const RouteChangeTracker = () => {
  const location = useLocation();
  React.useEffect(
    function () {
      const path = location.pathname + location.search;
      ReactGA.set({ page: path });
      // ReactGA.pageview(path); // Record a pageview for the given page
      // console.log(ReactGA);
      ReactGA.send({ hitType: "pageview", page: path }); // Record a pageview for the given page
    },
    [location]
  );
  return <></>;
};
