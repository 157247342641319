import * as React from "react";
import { LeadRequest } from "../../../../apiServices/Models/Leads/LeadRequest";
import { Formik, ErrorMessage, Form, Field } from "formik";
import * as Yup from "yup";
import { AuthService } from "../../../../apiServices/Services/AuthServices";
import toast from "react-hot-toast";
import { HandleAuthToken } from "../../../core/Utils/HandleAuthToken";
import {
  sendGAEvent,
  toggleLoader,
} from "../../../../apiServices/Services/CommonServices";
// import { parsePath } from "react-router-dom";

const objAuthService = new AuthService();
const objAuthHandler = new HandleAuthToken();
export interface IBookCallFormMainCompProps {
  name: string;
  email: string;
  mobile: string;
  source: string;
}

function BookCallFormMainComp(props: IBookCallFormMainCompProps) {
  const gaCategory = "HomePage Lead Form Activity";
  const gaLabel = "Lead Form Events";

  const [isLoading, setLoader] = React.useState(false);
  // const returnUrl = parsePath(window.location.href);

  const leadCapture = (values: IBookCallFormMainCompProps) => {
    const userData: LeadRequest = {
      fullName: values.name,
      email: values.email,
      phone: values.mobile,
      source: values.source,
    };
    return objAuthService.leadCapture(userData);
  };

  const handleSubmit = (values: IBookCallFormMainCompProps) => {
    setLoader(true);
    // isLoader(true);
    leadCapture(values).subscribe({
      next: (LeadResponse) => {
        objAuthHandler.setItem(JSON.stringify(LeadResponse));
        sendGAEvent(gaCategory, "HomePage_Lead_Form_Submitted", gaLabel);
        setLoader(false);
      },
      error: (err) => {
        setLoader(false);
        if (err.response.data) {
          toast.error(err.response.data);
        } else if (err.response.status === 500) {
          toast.error("Service unavailable.");
        } else {
          toast.error("Something went wrong, please try again!");
        }
      },
      complete: () => {
        toast.success("Your enquiry has been submitted successfully!");
        setLoader(false);
        // navigate("/courses", { replace: true });
        window.location.href = "/Thankyou-registration";
      },
    });
  };

  const leadCaptureSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, "Too Short!")
      .max(100, "Too Long!")
      .matches(/^[A-Za-z\s][A-Za-z\s]*$/, "Invalid Name")
      .required("Required"),
    email: Yup.string()
      .min(1, "Too Short!")
      .max(100, "Too Long!")
      .email("Invalid Email")
      .required("Required"),
    mobile: Yup.string()
      .min(10, "Too Short!")
      .max(10, "Too Long!")
      .matches(/^\d{10}$/, "Invalid Mobile Number")
      .required("Required"),
  });

  return (
    <>
      {toggleLoader(isLoading)}
      <div className="banner-area common-container d-flex">
        <div className="banner-content pt-50">
          <div className="banner-form">
            <div className="banner-form-title">
              <div className="d-flex align-items mb-10">
                <div className="banner-form-title-icon"></div>
                <div className="banner-form-title-text">
                  Interested In Our <span>Courses?</span>
                </div>
              </div>

              <div className="e-form">
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    mobile: "",
                    source: props.source,
                  }}
                  validationSchema={leadCaptureSchema}
                  onSubmit={(values: IBookCallFormMainCompProps, actions) => {
                    handleSubmit(values);
                    // new code
                    actions.setSubmitting(false);
                    actions.resetForm({
                      values: {
                        name: "",
                        email: "",
                        mobile: "",
                        source: "",
                      },
                    });
                    // new code
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="input-field">
                        <Field
                          name="name"
                          type="text"
                          placeholder="Name"
                          required=""
                          className={
                            errors.name && touched.name ? "error-field" : ""
                          }
                          onClick={
                            touched.name
                              ? sendGAEvent(
                                  gaCategory,
                                  "HomePage_LeadForm_Name_Field_Touched",
                                  gaLabel
                                )
                              : () => {}
                          }
                        />
                        <ErrorMessage
                          component="div"
                          className="errormsglabel"
                          name="name"
                        />
                      </div>

                      <div className="input-field">
                        <Field
                          name="email"
                          type="text"
                          placeholder="email@example.com"
                          required=""
                          // value=""
                          className={
                            errors.email && touched.email ? "error-field" : ""
                          }
                          onClick={
                            touched.email
                              ? sendGAEvent(
                                  gaCategory,
                                  "HomePage_LeadForm_Email_Field_Touched",
                                  gaLabel
                                )
                              : () => {}
                          }
                        />
                        <ErrorMessage
                          component="div"
                          className="errormsglabel"
                          name="email"
                        />
                      </div>

                      <div className="input-field">
                        <Field
                          name="mobile"
                          type="text"
                          placeholder="Mobile number (10 Digits - XXXXXXXXXX)"
                          required=""
                          // value=""
                          className={
                            errors.mobile && touched.mobile ? "error-field" : ""
                          }
                          onClick={
                            touched.mobile
                              ? sendGAEvent(
                                  gaCategory,
                                  "HomePage_LeadForm_Mobile_Field_Touched",
                                  gaLabel
                                )
                              : () => {}
                          }
                        />
                        <ErrorMessage
                          component="div"
                          className="errormsglabel"
                          name="mobile"
                        ></ErrorMessage>
                      </div>
                      <button type="submit" className="btn btn-primary">
                        Book a Call
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default BookCallFormMainComp;
