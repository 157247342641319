export class HomePageNewsResponse extends Array<HomePageNewsResponse> {
    constructor() {
      super();
      this.id = 0;
      this.newstitle = "";
      this.newsthumb = "";
      this.newsUrl = "";
    }
    public id: number;
    public newstitle: string;
    public newsthumb: string;
    public newsUrl: string;
  }
  