export class CourseDetailResponse {
  constructor() {
    this.id = "0";
    this.name = "";
    this.rating = 5;
    this.totalEnrollments = 0;
    this.duration = "";
    this.languages = "English & Hindi";
    this.courseLevel = "Intermediate";
    this.affiliation = "Certificate of completion by iHUB IIT Roorkee";
    this.startDate = "";
    this.courseMode = "Self Paced";
    this.timing = "";
    this.eligibility = "";
    this.developerKitUrl = "";
    this.placementSupportUrl = "";
    this.seoDescripton = "";
    this.keyWords = "";
  }
  public id: string;
  public name: string;
  public rating: number;
  public totalEnrollments: number;
  public duration: string;
  public languages: string;
  public courseLevel: string;
  public affiliation: string;
  public startDate: string;
  public courseMode: string;
  public timing: string;
  public eligibility: string;
  public developerKitUrl: string;
  public placementSupportUrl: string;
  public seoDescripton: string;
  public keyWords: string;
}
