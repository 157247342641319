import { EventPageAboveFold } from "./EventPageAboveFoldResponse";
import { EventPageAbout } from "./EventPageAboutResponse";
import { EventMedia } from "./EventPageMediaResponse";

export class EventDetailsResponse extends Array<EventDetailsResponse> {
  constructor() {
    super();
    this.id = "";
    this.name = "";
    this.shortDescription = "";
    this.eventImageUrl = "";
    this.date = "";
    this.aboveFold = new EventPageAboveFold();
    this.aboutEvent = new EventPageAbout();
    this.media = new Array<EventMedia>();
  }
  public id: string;
  public name: string;
  public shortDescription: string;
  public eventImageUrl: string;
  public date: string;
  public aboveFold: EventPageAboveFold;
  public aboutEvent: EventPageAbout;
  public media: Array<EventMedia>;
}
