import * as React from "react";
import { useRef } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import { ContentServices } from "../../../../apiServices/Services/ContentServices";

import { OurAppsResponse } from "../../../../apiServices/Models/WebsiteContent/OurAppsModels/OurAppsResponse";
import { AppsYoutubeVideoModal } from "../../Header/Appsyoutube";
const contentService = new ContentServices();

interface OurAppsHomeCompProps {}

export const OurAppsHomeComp: React.FunctionComponent<OurAppsHomeCompProps> = (
  props
) => {
  const videoModalRefapp: any = useRef();
  const setAppsData = (link: string, appsname: string) => {
    videoModalRefapp.current.setData(link, appsname);
  };

  const [appList, setappList] = React.useState<OurAppsResponse[]>([]);
  const getProjectsData = (): OurAppsResponse[] => {
    return contentService.getAllapps();
  };

  React.useEffect(() => {
    setappList(getProjectsData());
  }, [setappList]);

  return (
    <>
      <div className="testimonial py-100 white-bg">
        <div className="common-container">
          <div className="common-heading-home">
            <h2 className="text-center">
              <span>Our</span> Apps
            </h2>
          </div>

          <div className="ourappshome">
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              freeMode={true}
              navigation={true}
              pagination={{
                clickable: true,
              }}
              modules={[FreeMode, Pagination, Navigation, Autoplay]}
              className="mySwiper"
              autoplay={{
                delay: 8000,
                disableOnInteraction: false,
              }}
              loop={true}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 4,
                },
              }}
            >
              {appList.map((apps, key) => {
                return (
                  <SwiperSlide key={key}>
                    <div className="apps-card">
                      <button
                        className="dropdown-item usecase__btn"
                        data-bs-toggle="modal"
                        data-bs-target="#youtubeVideoModal3"
                        onClick={() =>
                          setAppsData(
                            apps.videoUrl,
                            apps.appName ? apps.appName : ""
                          )
                        }
                      >
                        <div className="appcard-img">
                          <img
                            src={apps.imageUrl}
                            alt=""
                            className="thum_img"
                          />
                          <img src={apps.appgif} alt="" className="Gif_img" />
                        </div>
                      </button>

                      <div className="aaps-description-block">
                        <div className="__flex-box ourhomeflex">
                          <div className="___content__block">
                            <h2>{apps.appName}</h2>
                            <div className="all-icons-list">
                              <ul>
                                {apps.AppsInclusion.gaming === true ? (
                                  <>
                                    <li>
                                      <div className="inner-icons-list">
                                        <div className="img-block-icon">
                                          <img
                                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/a9Qkkv4Nxl28ivmv_2022-11-29T071224505757.Gaming_icon.png"
                                            alt=""
                                            className="normal-img"
                                          />
                                          <img
                                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/d30OAJqr06d7POcI_2022-11-29T071303990552.Gaming_white_icon.png"
                                            alt=""
                                            className="onhover-img"
                                          />
                                        </div>
                                        <span>Gaming</span>
                                      </div>
                                    </li>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {apps.AppsInclusion.vrHeadset === true ? (
                                  <>
                                    <li>
                                      <div className="inner-icons-list">
                                        <div className="img-block-icon">
                                          <img
                                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/kDqGQAFRitfTbIpV_2022-11-29T071404116218.VR_icon.png"
                                            alt=""
                                            className="normal-img"
                                          />
                                          <img
                                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/q9sOMKC9YEsYdfcF_2022-11-29T071435305587.VR_white_icon.png"
                                            alt=""
                                            className="onhover-img"
                                          />
                                        </div>
                                        <span>VR</span>
                                      </div>
                                    </li>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {apps.AppsInclusion.Experience === true ? (
                                  <>
                                    <li>
                                      <div className="inner-icons-list">
                                        <div className="img-block-icon">
                                          <img
                                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/nWo9CnNj1wjYKVAv_2022-11-29T071537132537.Experience_icon.png"
                                            alt=""
                                            className="normal-img"
                                          />
                                          <img
                                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/p8REhLcEImaY9XNM_2022-11-29T071610067956.Experience_white_icon.png"
                                            alt=""
                                            className="onhover-img"
                                          />
                                        </div>
                                        <span>Experience</span>
                                      </div>
                                    </li>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {apps.AppsInclusion.arHeadset === true ? (
                                  <>
                                    <li>
                                      <div className="inner-icons-list">
                                        <div className="img-block-icon">
                                          <img
                                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/9dMv0mrjPKAMZaJt_2022-11-29T071731551047.Ar-icon.png"
                                            alt=""
                                            className="normal-img"
                                          />
                                          <img
                                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/DvJJ4xvobud9ROSs_2022-11-29T071811463223.AR-white-icon.png"
                                            alt=""
                                            className="onhover-img"
                                          />
                                        </div>
                                        <span>AR</span>
                                      </div>
                                    </li>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {apps.AppsInclusion.glass === true ? (
                                  <>
                                    <li>
                                      <div className="inner-icons-list">
                                        <div className="img-block-icon">
                                          <img
                                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/eZu8OAbeexbBtVca_2022-12-02T114258494171.3D-glasses.png"
                                            alt=""
                                            className="normal-img"
                                          />
                                          <img
                                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/eZu8OAbeexbBtVca_2022-12-02T114258494171.3D-glasses.png"
                                            alt=""
                                            className="onhover-img"
                                          />
                                        </div>
                                        <span>3D</span>
                                      </div>
                                    </li>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </ul>
                            </div>
                          </div>
                          {/* <a href={apps.appUrl} className="install_btn">
                            Install
                          </a> */}
                        </div>
                        <p>{apps.appDescription}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <AppsYoutubeVideoModal ref={videoModalRefapp} />
          </div>
        </div>
      </div>
    </>
  );
};
