import * as React from "react";
import NiftKangraLogo from "../../../../assets/images/partners-logo/NIFT.png";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Grid, Pagination, Autoplay, Navigation } from "swiper";
import { ContentServices } from "../../../../apiServices/Services/ContentServices";
import { HomePartnersResponse } from "../../../../apiServices/Models/WebsiteContent/HomePageModels/HomePartnersResponse";

interface OurPartnersCompProps {}

const contentService = new ContentServices();
export const OurPartnersComp: React.FunctionComponent<OurPartnersCompProps> = (
  props
) => {
  const [Partner, setPartner] = React.useState<HomePartnersResponse[]>([]);
  const getProjectsData = (): HomePartnersResponse[] => {
    return contentService.getAllpartner();
  };
  React.useEffect(() => {
    setPartner(getProjectsData());
  }, [setPartner]);

  return (
    <div className="partners main_news_content">
      {/* <h2 className="partners-title">
        Our <span></span>
      </h2> */}
      <div className="common-heading-home">
        <h2 className="text-center">
          <span>Our</span>
          Partners
        </h2>
      </div>
      <div className="mt-70">
        <div className="partners-logo common-row">
          <Swiper
            slidesPerView={4}
            grid={{
              rows: 2,
            }}
            autoplay={{
              delay: 8000,
              disableOnInteraction: true,
            }}
            navigation={true}
            spaceBetween={20}
            pagination={{
              clickable: true,
            }}
            // loop={true}
            modules={[Grid, Pagination, Autoplay, Navigation]}
            className="mySwiper"
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
          >
            {Partner?.map((PartnerFetch, key) => {
              return (
                <SwiperSlide key={key}>
                  <div className="parters-logo-img">
                    <img src={PartnerFetch.partnerthumb} alt="partner-thumb" />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>

          {/* <div className="p-logo common-col-3 common-md-4 common-sm-12">
            <img src={NiftKangraLogo} alt="Nift-Kangra-Logo" />
          </div>
          <div className="p-logo common-col-4 common-md-4 common-sm-12">
            <img src={RGPVLogo} alt="RGPV-Logo" />
          </div>
          <div className="p-logo common-col-2 common-md-4 common-sm-12">
            <img src={IITRLogo} alt="IITR-Logo" />
          </div>
          <div className="p-logo common-col-3 common-md-4 common-sm-12">
            <img src={AuroLogo} />
          </div> */}
        </div>
      </div>
    </div>
  );
};
