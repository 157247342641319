import { useState, useImperativeHandle, forwardRef } from "react";
import { LocalStorage } from "../../core/Storage/LocalStorage";
import ChangePassword from "./ChangePassword";
import ForgotPassword from "./ForgotPassword";
import LoginComponent from "./LoginComponent";
import SignupComponent from "./SignupComponent";
import VerifyOTP from "./VerifyOtp";
import { GrClose } from "react-icons/gr";
import { HandleAuthToken } from "../../core/Utils/HandleAuthToken";
import { sendGAEvent } from "../../../apiServices/Services/CommonServices";

const objLocalStorage = new LocalStorage();
const objAuthHandler = new HandleAuthToken();

export const Modal = forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    handleChangePassword() {
      handleChangePassword();
    },
  }));

  const [isLoader, setisLoader] = useState(false); // Working fine
  const toggleisLoader = (status: boolean) => {
    if (status === true) {
      setisLoader(status);
    } else {
      setisLoader(status);
    }
  };

  const [changeCompo, setChangeCompo] = useState("login");

  const [modalTitle, setModalTitle] = useState("Sign In"); // Working fine
  const toggleTitle = (title: string) => {
    setModalTitle(title);
  };

  const [value, setValue] = useState(0); // Working fine
  const ForceUpdate = () => {
    sendGAEvent("Auth Modal Closed", "Auth_Modal_Closed", "Auth Actions");
    closeModal();
    setValue((value) => value + 1);
    setChangeCompo("login");
    setModalTitle("Sign In");
    showcompo = renderCompo(changeCompo);
  };

  const closeModal = () => {
    if (!objAuthHandler.getToken() && !objLocalStorage.getItem("leadData")) {
      objLocalStorage.deleteCookie("popup");
    }
  };

  const updateChangeCompo = (compo: string) => {
    setChangeCompo(compo);
    showcompo = renderCompo(changeCompo);
  };

  const renderCompo = (compo: string) => {
    switch (compo) {
      case "signup":
        sendGAEvent(
          "SignUp Form Rendered",
          "SignUp_Form_Rendered",
          "Auth Actions"
        );
        return (
          <SignupComponent
            toggle={updateChangeCompo}
            toggleTitle={toggleTitle}
            reRender={ForceUpdate}
            isLoader={toggleisLoader}
          />
        );
      case "forgot":
        sendGAEvent(
          "Forgot Password Form Rendered",
          "ForgotPassword_Form_Rendered",
          "Auth Actions"
        );
        return (
          <ForgotPassword
            reRender={ForceUpdate}
            toggle={updateChangeCompo}
            toggleTitle={toggleTitle}
            isLoader={toggleisLoader}
          />
        );
      case "changepassword":
        sendGAEvent(
          "Change Password Form Rendered",
          "ChangePassword_Form_Rendered",
          "Auth Actions"
        );
        return <ChangePassword isLoader={toggleisLoader} />;
      case "verifyotp":
        sendGAEvent(
          "Verify OTP Form Rendered",
          "VerifyOTP_Form_Rendered",
          "Auth Actions"
        );
        return <VerifyOTP reRender={ForceUpdate} isLoader={toggleisLoader} />;
      default:
        sendGAEvent(
          "Login Form Rendered",
          "Login_Form_Rendered",
          "Auth Actions"
        );
        return (
          <LoginComponent
            toggle={updateChangeCompo}
            toggleTitle={toggleTitle}
            reRender={ForceUpdate}
            isLoader={toggleisLoader}
            afterLogin={props.afterLogin}
          />
        );
    }
  };
  let showcompo = renderCompo(changeCompo);

  const handleChangePassword = () => {
    setChangeCompo("changepassword");
    setModalTitle("Change Password");
    showcompo = renderCompo(changeCompo);
  };

  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {modalTitle}
              </h5>
              <GrClose
                type="button"
                id="closeModal"
                data-bs-dismiss="modal"
                onClick={ForceUpdate}
              />
            </div>
            <div className="modal-body">{showcompo}</div>
          </div>
        </div>

        <button
          style={{ display: "none" }}
          type="button"
          id="closeModal"
          data-bs-dismiss="modal"
        ></button>
      </div>
    </>
  );
});
