import { LocalStorage } from "../Storage/LocalStorage";

const objLocalStorage = new LocalStorage();

export class HandleAuthToken {
  public setItem(response: string): boolean {
    // Store auth token in cookie with 20 days expiry
    if (this.setTokenInCookie(response)) {
      // console.log(response);
      return true;
    }
    return false;
  }

  public getItem(): string | null {
    const userDataFromCookie = getTokenFromCookie();
    // console.log(userDataFromCookie);
    if (Object.keys(userDataFromCookie).length === 0) {
      return "";
    }
    return userDataFromCookie;
  }

  public getProfile = () => {
    let userData = this.getItem();
    // console.log(userData);
    let profile_id = JSON.parse(userData || "{}").profile.id;
    // console.log(profile_id);
    return profile_id;
  };

  public getToken() {
    let userData = this.getItem();
    let token = JSON.parse(userData || "{}").access_token;
    return token;
  }

  private setTokenInCookie(response: string) {
    this.setAuthCookie("userData", response, 20);
    return true;
  }

  private setAuthCookie = (cname: string, cvalue: string, exdays: number) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = d.toUTCString();
    const domain = `${process.env.REACT_APP_COOKIE_DOMAIN}`;
    // console.log("Domain:", domain);
    document.cookie =
      cname +
      "=" +
      cvalue +
      ";expires=" +
      expires +
      ";domain=" +
      domain +
      ";path=/";
  };

  private deleteAuthCookie = (cname: string) => {
    //console.log("delete cookie");
    //console.log(document.cookie);
    const domain = `${process.env.REACT_APP_COOKIE_DOMAIN}`;
    //console.log(cname + "=;" + "domain=" + domain + ";path=/; expires = Thu, 01 Jan 1970 00:00:00 GMT");
    document.cookie =
      cname +
      "=;domain=" +
      domain +
      ";path=/; expires = Thu, 01 Jan 1970 00:00:00 GMT";
  };

  public deleteUserSession = (cname: string) => {
    this.deleteAuthCookie(cname);
  };
}

function getTokenFromCookie() {
  const userData = objLocalStorage.getCookie("userData");
  //console.log(userData);
  return userData;
}
