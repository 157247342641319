import * as React from "react";
import { EventPageAboveFold } from "../../../../../apiServices/Models/WebsiteContent/EventPageModels/EventPageAboveFoldResponse";
import EventBanner1 from "../../../../../assets/images/our-events-img/eventbanner.jpg";
const Calendar = require("../../../../../assets/images/our-events-img/Calendar.png");
const Time = require("../../../../../assets/images/our-events-img/Time.png");
const Location = require("../../../../../assets/images/our-events-img/Location.png");

interface PastEventCompProps {
  eventAboveFoldDetails: EventPageAboveFold;
}

const PastEventComp: React.FunctionComponent<PastEventCompProps> = (props) => {
  const eventAboveFoldDetails = props.eventAboveFoldDetails;
  return (
    <div className="top_event_banner">
      <img
        src={
          eventAboveFoldDetails.abovefoldImageUrl
            ? eventAboveFoldDetails.abovefoldImageUrl
            : EventBanner1
        }
        alt="Main Banner"
        className="top_main_banner"
      />
      <div className="top_baner_con">
        <div className="common-container">
          <div className="common-row">
            <div className="event-col common-sm-10">
              <h1>{eventAboveFoldDetails.name}</h1>

              <div className="event_date_time">
                <ul>
                  <li>
                    <div className="event-small-box">
                      <img src={Calendar} alt="Calendar" />
                      <span>{eventAboveFoldDetails.displayDate}</span>
                    </div>
                  </li>

                  <li>
                    {eventAboveFoldDetails.time === "" ? (
                      <></>
                    ) : (
                      <>
                        <div className="event-small-box">
                          <img src={Time} alt="Time" />
                          <span>{eventAboveFoldDetails.time}</span>
                        </div>
                      </>
                    )}
                  </li>

                  <li>
                    <div className="event-small-box">
                      <img src={Location} alt="Location" />
                      <span>
                        <a href="#">{eventAboveFoldDetails.location}</a>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="event-btn">
                {eventAboveFoldDetails.pastEventLabel === "" ? (
                  <>
                    <p>{eventAboveFoldDetails.pastEventLabel}</p>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PastEventComp;
