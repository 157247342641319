import { useState } from "react";
import { Link } from "react-router-dom";
import { AuthService } from "../../../apiServices/Services/AuthServices";
import toast from "react-hot-toast";
import "../../../assets/css/required.css";
import { HandleAuthToken } from "../../core/Utils/HandleAuthToken";
import { VerifyOtp } from "../../../apiServices/Models/Auth/VerifyOtpRequest";
import * as Yup from "yup";
import { Formik, ErrorMessage, Form, Field } from "formik";
import { useAppDispatch } from "../../../app/hooks";
import { signUp } from "../../../features/auth/AuthSlice";
import { Profile } from "../../../apiServices/Models/Auth/ProfileModel";
import {
  sendGAEvent,
  toggleLoader,
} from "../../../apiServices/Services/CommonServices";
import { Constant } from "../../../apiServices/Services/WebServiceConstants";
import { CountryContent } from "../../../apiServices/Models/WebsiteContent/CountryContent/CountryContent";
import { ContentServices } from "../../../apiServices/Services/ContentServices";
import React from "react";

const contentService = new ContentServices();

const objAuthService = new AuthService();
const booleanHandleAuthToken = new HandleAuthToken();

interface SignUpProps {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  country: string;
}

const SignupComponent = (props: any) => {
  const [isLoading, setLoader] = useState(false);
  const dispatch = useAppDispatch();

  // for hiding switching links in the component

  const toggleState = (event: any) => {
    event.preventDefault();
    props.toggle();
    props.toggleTitle("Sign In");
  };

  const registerUser = (values: SignUpProps) => {
    sendGAEvent("Signup Process Started", "SignUp_Started", "Auth Actions");
    const userData: Profile = {
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      email: values.email.trim(),
      phone: {
        countryCode: values.country,
        callStatus: "VERIFIED",
        whatsappStatus: "VERIFIED",
        phoneNumber: values.mobile.trim(),
      },
      roles: ["STUDENT"],
    };
    const appId = Constant.applicationId
      ? parseInt(Constant.applicationId)
      : -1;
    // console.log("AppliationId = " + Constant.applicationId);
    // return objAuthService.register(userData);
    // console.log("countryCode = " + values.country);
    return objAuthService.registernew(userData, appId);
  };

  const handleVerifyOTP = (values: SignUpProps) => {
    setLoader(true);
    registerUser(values).subscribe({
      next: (registeredUser) => {
        saveRegisteredUserData(registeredUser);
        setLoader(false);
      },
      error: (err) => {
        sendGAEvent("Signup Failed", "SignUp_Failed", "Auth Actions");
        // props.isLoader(false);
        setLoader(false);
        if (err.response.data) {
          toast.error(err.response.data);
        } else if (err.response.status === 500) {
          toast.error("Service unavailable.");
        } else {
          toast.error("Something went wrong, please try again!");
        }
      },
      complete: () => {
        toast.success("OTP sent to your registered mobile number.");
        sendGAEvent("Signup Successful", "SignUp_Successful", "Auth Actions");
        props.isLoader(false);
        if (props.hide) {
          props.toggleCompo();
        }
      },
    });
  };

  const saveRegisteredUserData = (registeredUser: VerifyOtp) => {
    const tokenStatus: boolean = booleanHandleAuthToken.setItem(
      JSON.stringify(registeredUser)
    );
    dispatch(signUp(JSON.stringify(registeredUser)));

    props.isLoader(false);
    // setFName("");
    // setLName("");
    // setEmail("");
    // setMobile("");
    props.toggleTitle("Please enter the OTP to verify your account");
    props.toggle("verifyotp");
  };

  const SignUpSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(1, "Too Short!")
      .max(100, "Too Long!")
      .matches(/^[A-Za-z\s]+$/, "Invalid First Name")
      .required("Required"),
    lastName: Yup.string()
      .min(1, "Too Short!")
      .max(100, "Too Long!")
      .matches(/^[A-Za-z\s]+$/, "Invalid Last Name")
      .required("Required"),
    email: Yup.string()
      .min(1, "Too Short!")
      .max(100, "Too Long!")
      .email("Invalid Email")
      .required("Required"),
    mobile: Yup.string()
      .min(8, "Too Short!")
      .max(12, "Too Long!")
      // .matches(/^\d{10}$/, "Invalid Mobile Number")
      .required("Required"),
    country: Yup.string().required("Required"),
  });

  const [newsList, setnewsList] = React.useState<CountryContent[]>([]);
  const getProjectsData = (): CountryContent[] => {
    return contentService.getAllcountry();
  };
  // sort code
  const firstTwoCountries = newsList.slice(0, 2);

  const sortedCountries = newsList
    .slice(2)
    .sort((a, b) => a.name.localeCompare(b.name));

  const finalData = firstTwoCountries.concat(sortedCountries);
  // sort code

  React.useEffect(() => {
    setnewsList(getProjectsData());
  }, [setnewsList]);

  return (
    <>
      {toggleLoader(isLoading)}
      <div className="login-account-middle">
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
            country: "",
          }}
          validationSchema={SignUpSchema}
          onSubmit={(values: SignUpProps) => {
            handleVerifyOTP(values);
            // same shape as initial values
            // console.log(values);
          }}
        >
          {({ errors, touched }) => (
            <Form className="registration">
              <div className="form-block">
                <label htmlFor="firstName" className="after-required">
                  First Name
                </label>
                <Field
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  className={
                    errors.firstName && touched.firstName ? "error-field" : ""
                  }
                />
                <ErrorMessage
                  component="div"
                  className="errormsglabel"
                  name="firstName"
                />
              </div>
              <div className="form-block">
                <label htmlFor="lastName" className="after-required">
                  Last Name
                </label>
                <Field
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  className={
                    errors.lastName && touched.lastName ? "error-field" : ""
                  }
                />
                <ErrorMessage
                  component="div"
                  className="errormsglabel"
                  name="lastName"
                />
              </div>
              <div className="form-block">
                <label htmlFor="email" className="after-required">
                  Email
                </label>
                <Field
                  name="email"
                  type="text"
                  placeholder="email@example.com"
                  className={errors.email && touched.email ? "error-field" : ""}
                />
                <ErrorMessage
                  component="div"
                  className="errormsglabel"
                  name="email"
                />
              </div>
              <div className="form-block">
                <label htmlFor="mobile" className="after-required">
                  Mobile Number
                </label>
                <div className="country__box">
                  <div className="c-field">
                    <Field
                      name="country"
                      as="select"
                      // className={"Country-field"}
                      className={
                        errors.mobile && touched.mobile ? "error-field" : ""
                      }
                    >
                      {finalData
                        ?.map((news, key1) => {
                          return (
                            <>
                              <option value={news.code}>{news.name}</option>
                            </>
                          );
                        })
                        .sort()}
                    </Field>
                    <ErrorMessage
                      component="div"
                      className="errormsglabel"
                      name="country"
                    />
                  </div>
                  <div className="mob">
                    <Field
                      name="mobile"
                      type="text"
                      placeholder="Mobile number (10 Digits - XXXXXXXXXX)"
                      className={
                        errors.mobile && touched.mobile ? "error-field" : ""
                      }
                    />
                    <ErrorMessage
                      component="div"
                      className="errormsglabel"
                      name="mobile"
                    />
                  </div>
                </div>
              </div>

              <div className="create-btn">
                <button type="submit" className="btn btn-primary">
                  Sign Up
                </button>
              </div>
              {props.hide ? (
                <>
                  <div className="dropdown-divider"></div>

                  <div className="forget-pass">
                    <Link className="dropdown-item" to={"/login"}>
                      Already an User?{" "}
                      <span style={{ color: "#2CABDE" }}>Sign-in</span>
                    </Link>

                    {/* <button
                      className="dropdown-item"
                      onClick={props.setShowforgotpass}
                    >
                      Forgot password?
                    </button> */}
                  </div>
                </>
              ) : (
                <>
                  <div className="dropdown-divider"></div>
                  <div className="forget-pass">
                    <button className="dropdown-item" onClick={toggleState}>
                      Already an User?{" "}
                      <span style={{ color: "#007bff" }}>Sign-in</span>
                    </button>
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SignupComponent;
