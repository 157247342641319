export class EventPageAboveFold {
  constructor() {
    this.name = "Dummy Event";
    this.displayDate = "December 31, 2022";
    this.time = "12:00 Pm (IST)";
    this.location = "Online";
    this.zoomUrl = "";
    this.futureButtonLabel = "Register Now";
    this.futureButtonUrl = "";
    this.pastEventLabel = "";
    this.abovefoldImageUrl = "";
  }
  public name: string;
  public displayDate: string;
  public time: string;
  public location: string;
  public zoomUrl: string;
  public futureButtonLabel: string;
  public futureButtonUrl: string;
  public pastEventLabel: string;
  public abovefoldImageUrl: string;
}
