import * as React from "react";
// import { PageBackgroundLeft } from "../CommonComponents/PageBackgroundLeft";
// import { PageBackgroundRight } from "../CommonComponents/PageBackgroundRight";
import Bgimage from "../../../assets/images/auth-pages-background/bg_png.png";
import Bgimage2 from "../../../assets/images/auth-pages-background/bg_png2.png";
import { Helmet, HelmetProvider } from "react-helmet-async";

interface PrivacyPolicyProps {}

const PrivacyPolicy: React.FunctionComponent<PrivacyPolicyProps> = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Privacy Policy | TechXR</title>
          <meta name="title" content="Privacy Policy | TechXR" />
          <meta
            name="description"
            content="Techxr is value your privacy and it is most recognizing the importance of maintaining your privacy. To know more about our Privacy Policy visit us now."
          />
          <meta
            property="image"
            content="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/uD41orAQL8hE2zva_2023-05-30T064448436786.Img web.png"
          />
        </Helmet>
      </HelmetProvider>
      <div className="main-section mx-w-1440">
        <div className="Privacy-main py-100">
          <div className="common-container">
            {/* <Bgimage/> */}
            <img src={Bgimage} alt="" className="bg-overlay" />
            <img src={Bgimage2} alt="" className="bg-overlay second" />
            <div className="Privacy-row">
              <div className="common-heading privacy-heading">
                <h1 className="mb-10">Privacy Policy</h1>
                <h4>This Privacy Policy applies to the techxr.co</h4>
              </div>

              <div className="privacy-content-block">
                <p>
                  <strong>
                    Techxr.co recognises the importance of maintaining your
                    privacy.
                  </strong>{" "}
                  We value your privacy and appreciate your trust in us. This
                  Policy describes how we treat user information we collect on{" "}
                  <a href="http://techxr.co/"> http://www.techxr.co</a> and
                  other offline sources. This Privacy Policy applies to current
                  and former visitors to our website and to our online
                  customers. By visiting and/or using our website, you agree to
                  this Privacy Policy.
                </p>
                <p>
                  <strong>
                    <i>www.techxr.co</i>
                  </strong>{" "}
                  is a property of Techxr Innovations Private Limited, an Indian
                  Company registered under the Companies Act, 2013 having its
                  registered office at F-601, Virasha Heights, Danish Kunj,
                  Bawadia Kalan, Bhopal - 462026.
                </p>
              </div>

              <div className="privacy-content-block">
                <h2>Information we collect</h2>
                <p>
                  <strong>Contact information.</strong> We might collect your
                  name, email, mobile number, phone number, street, city, state,
                  pincode, country, college name, field of study, graduation
                  date, and ip address.
                </p>
                <p>
                  We might also collect your billing name, billing address and
                  payment method when you enrol for a course or purchase any
                  other goods or services from our website. We NEVER collect
                  your credit card number or credit card expiry date or other
                  details pertaining to your credit card on our website. Credit
                  card information, if at all, will be obtained and processed by
                  our online payment provider/partner.
                </p>
                <p>
                  <strong>Information you post.</strong> We collect information
                  you post in a public space on our website or on a third-party
                  social media site belonging to techxr.co
                </p>
                <p>
                  <strong>Demographic information.</strong> We may collect
                  demographic information about you, events you like, events you
                  intend to participate in, or any other information provided by
                  you during the use of our website. We might collect this as a
                  part of a survey also.
                </p>
                <p>
                  <strong>Other information.</strong> If you use our website, we
                  may collect information about your IP address and the browser
                  you're using. We might look at what site you came from,
                  duration of time spent on our website, pages accessed or what
                  site you visit when you leave us. We might also collect the
                  type of mobile device you are using, or the version of the
                  operating system your computer or device is running.
                </p>
                <p className="s-title">
                  <strong>We collect information in different ways.</strong>
                </p>
                <p>
                  We collect information directly from you. We collect
                  information directly from you when you register for a program.
                  We also collect information if you post a comment on our
                  websites or ask us a question through phone or email.
                </p>
                <p>
                  We collect information from you passively. We use tracking
                  tools like Google Analytics, Google Webmaster, browser cookies
                  and web beacons for collecting information about your usage of
                  our website.
                </p>
                <p>
                  We get information about you from third parties. For example,
                  if you use an integrated social media feature on our websites.
                  The third-party social media site will give us certain
                  information about you. This could include your name and email
                  address.
                </p>
              </div>

              <div className="privacy-content-block">
                <h2>Use of your personal information</h2>
                <p>
                  <strong>We use information to contact you:</strong> We might
                  use the information you provide to contact you for
                  confirmation of a purchase on our website or for other
                  promotional purposes. By providing your contact details you
                  allow us to contact you over phone, even if it is registered
                  in the Do Not Disturb registry, send you SMS, or send you
                  email for the purposes of such interest, as shown by you, on
                  our website, or for the transaction undertaken, as the case
                  may be.
                </p>
                <p>
                  <strong>
                    We use information to respond to your requests or questions.
                  </strong>{" "}
                  We might use your information to confirm your registration for
                  an event or contest.
                </p>
                <p>
                  <strong>
                    We use information to improve our products and services.
                  </strong>{" "}
                  We might use your information to customize your experience
                  with us. This could include displaying content based upon your
                  preferences.
                </p>
                <p>
                  <strong>
                    We use information to look at site trends and customer
                    interests.
                  </strong>{" "}
                  We may use your information to make our website and products
                  better. We may combine information we get from you with the
                  information we get from third parties.
                </p>
                <p>
                  <strong>We use information for security purposes.</strong> We
                  may use information to protect our company, our customers, or
                  our websites.
                </p>
                <p>
                  <strong>We use information for marketing purposes.</strong> We
                  might send you information about special promotions or offers.
                  We might also tell you about new features or products. These
                  might be our own offers or products, or similar products we
                  think you might find interesting.
                </p>
                <p>
                  <strong>
                    We use information to send you transactional communications.
                  </strong>{" "}
                  We might send you emails or SMS about your account or
                  purchase.
                </p>
                <p className="s-title">
                  <strong>
                    We use information as otherwise permitted by law.
                  </strong>
                </p>
                <p className="s-title">
                  <strong>Sharing of information with third-parties</strong>
                </p>
                <p>
                  <strong>
                    We will share information with third parties who perform
                    services on our behalf.
                  </strong>{" "}
                  We share information with vendors who help us manage our
                  online registration process or payment processors or
                  transactional message processors. Some vendors may be located
                  outside of India.
                </p>
                <p>
                  <strong>
                    Will share information with the event organizers.
                  </strong>{" "}
                  We share your information with event organizers and other
                  parties responsible for fulfilling the purchase obligation.
                  The event organizers and other parties may use the information
                  we give them as described in their privacy policies.
                </p>
                <p>
                  <strong>
                    Will share information with our business partners.
                  </strong>{" "}
                  This includes a third party who provide or sponsor an event,
                  or who operates a venue where we hold events. Our partners use
                  the information we give them as described in their privacy
                  policies.
                </p>
                <p>
                  <strong>
                    May share information if we think we have to in order to
                    comply with the law or to protect ourselves.
                  </strong>{" "}
                  We will share information to respond to a court order or
                  subpoena. We may also share it if a government agency or
                  investigatory body requests. Or, we might also share
                  information when we are investigating potential fraud.
                </p>
                <p>
                  <strong>
                    We may share information with any successor to all or part
                    of our business.
                  </strong>{" "}
                  For example, if part of our business is sold we may give our
                  customer list as part of that transaction.
                </p>
                <p>
                  <strong>
                    We may share your information for reasons not described in
                    this policy.
                  </strong>{" "}
                  We will tell you before we do this.
                </p>
              </div>

              <div className="privacy-content-block">
                <h2>Protection of Personal Information</h2>
                <p>
                  We use technical, administrative, and physical controls to
                  safeguard your information. We retain your information for as
                  long as you maintain a relationship with us, and beyond that
                  as required by law.
                </p>
              </div>

              <div className="privacy-content-block">
                <h2>Third party sites</h2>
                <p>
                  If you click on one of the links to third party websites, you
                  may be taken to websites we do not control. This policy does
                  not apply to the privacy practices of those websites. Read the
                  privacy policy of other websites carefully. We are not
                  responsible for these third party sites.
                </p>
              </div>

              <div className="privacy-content-block">
                <h2>Grievance Officer</h2>
                <p>
                  In accordance with Information Technology Act 2000 and rules
                  made there under, the name and contact details of the
                  Grievance Officer are provided below:
                </p>
                <p>Mr. Shubhanshu Shukla</p>
                <p>F 601, Virasha Heights, Danish Kunj</p>
                <p>Bawadia Kalan, Bhopal - 462026</p>
                <p>Phone: +91-9109131271 </p>
                <p>Email: sensexr@techxr.co</p>
                <p>
                  <strong>
                    If you have any questions about this Policy or other privacy
                    concerns, you can also email us at sensexr@techxr.co
                  </strong>
                </p>
              </div>

              <div className="privacy-content-block">
                <h2>Updates to this policy</h2>
                <p>
                  This Privacy Policy was last updated on 18.01.2022. From time
                  to time we may change our privacy practices. We will notify
                  you of any material changes to this policy as required by law.
                  We will also post an updated copy on our website. Please check
                  our site periodically for updates.
                </p>
              </div>

              <div className="privacy-content-block">
                <h2>Jurisdiction</h2>
                <p>
                  you choose to visit the website, your visit and any dispute
                  over privacy is subject to this Policy and the website's terms
                  of use. In addition to the foregoing, any disputes arising
                  under this Policy shall be governed by the laws of India.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
