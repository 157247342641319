import React from "react";
import ReactGA from "react-ga4";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./assets/css/main.css";
import "./assets/css/responsive.css";
import Footer from "./shared/components/Footer/Footer";
import Header from "./shared/components/Header/Header";
import * as HomePage from "./shared/components/HomePage";
import { EventsPageMainComp } from "./shared/components/EventPage/EventPageMainComp";
import Policy from "./shared/components/PrivacyPolicyPage/PrivacyPolicyMainComp";
import Signup from "./shared/components/AuthPages/SignUpPage";
import { Toaster } from "react-hot-toast";
import Logout from "./shared/components/AuthPages/Logout";
import { CoursePageMainComp } from "./shared/components/CoursePage/CoursePageMainComp";
import { AllCoursesMainComp } from "./shared/components/AllCoursesPage/AllCoursesMainComp";
import LoginPage from "./shared/components/AuthPages/LoginPage";
import { FaqComp } from "./shared/components/FaqPage/Faq";
import AboutUs from "./shared/components/AboutUsPage/AboutsUsMainComp";
import ProductsMainComp from "./shared/components/ProductPages/AllProductsPage/AllProductsMainComp";
import ProducDetailsComp from "./shared/components/ProductPages/ProductDetailsPage/ProductDetailsComp";
import Page404 from "./shared/components/404Page/Page404";
import { AllEventsMainComp } from "./shared/components/AllEventsPage/AllEventsMainComp";
import RefundPageComp from "./shared/components/RefundPage/RefundPageComp";
import TermsPageComp from "./shared/components/TermsAndConditionsPage/TermsPageComp";
import SenseXr from "./shared/components/SenseXR/SenseXr";
import EditProductMainComp from "./shared/components/ProductPages/UploadProductPage/EditProductMainComponent";
import UploadProductMainComp from "./shared/components/ProductPages/UploadProductPage/UploadProductMainComp";

import MyProductsMainComp from "./shared/components/ProductPages/MyProductPage/MyProduct";

import { RouteChangeTracker } from "./shared/core/Utils/RouteChangeTracker";
import CampusAmbassador from "./shared/components/CampusAmbassador/CampusAmbassador";
import Forum from "./shared/components/Forum/Forum";
import BackendErrorComp from "./shared/components/CommonComponents/BackendErrorComp";
import OurAppCompo from "./shared/components/OurApps/OurAppCompo";
import { SdkDownalod } from "./shared/components/SdkDownload/SdkDownloadCompo";
import { BlockEngine } from "./shared/components/BlockEngine/BlockEngineCompo";
import { BuyProduct } from "./shared/components/Buycontroller/Buycontroller";
import { ContactUsCompo } from "./shared/components/ContactUs/ContactUsCompo";
import ShippingAddress from "./shared/components/ShippingAddress/ShippingAddressCompo";
import XrBlockEngineCompo from "./shared/components/XrBlockEngineCompo/XrBlockEngineCompo";
import { ThankYouPageCompo } from "./shared/components/ThankYouPage/ThankYouPage";
import LandingPage from "./shared/components/LandingPage/LandingPage";
import AboutUsSecond from "./shared/components/AboutUsPage/AboutsUsMainCompSecond";
import ThankYouRegistration from "./shared/components/ThankYouRegistration/ThankYouRegistration";
import LandingPageTwitter from "./shared/components/LandingPage/LandingPageTwitter";
import { Webarvr } from "./shared/components/RedirectPage/WebArVr";
import LandingPageNew from "./shared/components/LandingPage/LandingPageNew";

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_CODE;
console.log(TRACKING_ID);
// const TRACKING_ID = "UA-220406613-1";
if (TRACKING_ID) {
  ReactGA.initialize(TRACKING_ID, {});
}

const App: React.FC = () => {
  return (
    <>
      <Router>
        {<Header />}
        <div>
          <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              // Define default options
              className: "",
              duration: 5000,
              style: {
                background: "#363636",
                color: "#fff",
              },
              success: {
                duration: 7000,
                className: "successToast",
              },
              error: {
                duration: 8000,
                className: "errorToast",
              },
            }}
          />
        </div>
        <Routes>
          <Route path="/" element={<HomePage.HomePageMainComponent />} />
          <Route path="/courses" element={<AllCoursesMainComp />} />
          <Route path="/courses/:id" element={<CoursePageMainComp />} />
          <Route path="/events" element={<AllEventsMainComp />} />
          <Route
            path="/events/:id/:event_name"
            element={<EventsPageMainComp />}
          />
          <Route path="/privacy-policy" element={<Policy />} />
          <Route path="/about-us" element={<AboutUsSecond />} />
          <Route path="/projects" element={<ProductsMainComp />} />
          <Route
            path="/projects/:project_id/:project_name"
            element={<ProducDetailsComp />}
          />
          <Route
            path="/project/edit/:project_id"
            element={<EditProductMainComp />}
          />
          <Route path="/project/add" element={<UploadProductMainComp />} />
          <Route
            path="/Thankyou-registration"
            element={<ThankYouRegistration />}
          />

          <Route path="/faqs" element={<FaqComp />} />
          <Route path="/sdkdownload" element={<SdkDownalod />} />
          <Route path="/xrblocks" element={<BlockEngine />} />

          <Route path="/sensexr" element={<SenseXr />} />
          <Route path="/forum" element={<Forum />} />

          <Route path="/myprojects" element={<MyProductsMainComp />} />

          <Route path="/refund-policy" element={<RefundPageComp />} />
          <Route path="/brand-ambassador" element={<CampusAmbassador />} />
          <Route path="/terms" element={<TermsPageComp />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<LoginPage />} />

          <Route path="/ourapps" element={<OurAppCompo />} />

          <Route path="/contact" element={<ContactUsCompo />} />

          <Route path="/buyproduct" element={<BuyProduct />} />
          <Route path="/web-ar-vr" element={<Webarvr />} />

          <Route path="/landing-page" element={<LandingPage />} />
          <Route path="/course-registration" element={<LandingPage />} />
          <Route path="/landing-page-1" element={<LandingPageNew />} />
          <Route
            path="/course-registration-form"
            element={<LandingPageTwitter />}
          />
          <Route
            path="/shipping-address"
            element={
              <ShippingAddress firstName={""} lastName={""} email={""} />
            }
          />

          <Route path="/xrblock-project" element={<XrBlockEngineCompo />} />
          <Route path="/thank-you" element={<ThankYouPageCompo />} />

          <Route path="/logout" element={<Logout />} />
          <Route path="/*" element={<Page404 />} />
          <Route path="/backend-error" element={<BackendErrorComp />} />
          {/* <Route path="/testing" element={<TestProduct />} /> */}
        </Routes>
        {<Footer />}
        <div>
          <RouteChangeTracker />
        </div>
      </Router>
    </>
  );
};

export default App;
