export class HomePageAchievementsResponse extends Array<HomePageAchievementsResponse> {
    constructor() {
      super();
      this.id = 0;
      this.achiveThumblogo = "";
      this.achiveDescription = "";
    }
    public id: number;
    public achiveThumblogo: string;
    public achiveDescription: string | any;
  }
// Achievements