import Card0 from "./0-Card.json";
import Card1 from "./1-Card.json";
import Card2 from "./2-Card.json";
import Card3 from "./3-Card.json";
import Card4 from "./4-Card.json";
import Card5 from "./5-Card.json";
import Card6 from "./6-Card.json";
import Card7 from "./7-Card.json";
import Card8 from "./8-Card.json";
import Card9 from "./9-Card.json";

let allCoursesCardJson = [Card0, Card1, Card2, Card3, Card4, Card5, Card6, Card7, Card8, Card9];

export default allCoursesCardJson;
