import { LeadRequest } from "../Models/Leads/LeadRequest";
import { NetworkService } from "./NetworkServices";
import { WebServiceConstant } from "./WebServiceConstants";
import { Profile } from "../Models/Auth/ProfileModel";
import { Observable } from "rxjs";
import { Login } from "../Models/Auth/LoginRequest";
import { LoginResponse } from "../Models/Auth/LoginResponse";
import { ForgotPasswordRequest } from "../Models/Auth/ForgotPasswordRequest";
import { ChangePasswordRequest } from "../Models/Auth/ChangePasswordRequest";
import { VerifyOtp } from "../Models/Auth/VerifyOtpRequest";
import { Lms } from "../Models/Auth/LmsRequest";
import { LmsResponse } from "../Models/Auth/LmsResponse";
import { LeadResponse } from "../Models/Leads/LeadResponse";

export class AuthService {
  public readonly networkService: NetworkService;

  constructor() {
    this.networkService = new NetworkService();
  }

  // public hello(): Observable<Login> {
  //   return this.networkService.getRequest(WebServiceConstant.AUTH_HELLO, {}, {}, Login);
  // }

  public login = (data: Login): Observable<LoginResponse> => {
    return this.networkService.postNewRequest(
      WebServiceConstant.AUTH_LOGIN,
      data,
      {},
      {},
      LoginResponse
    );
  };

  // public register = (data: Profile): Observable<VerifyOtp> => {
  //   return this.networkService.postNewRequest(
  //     WebServiceConstant.AUTH_REGISTER_USER,
  //     data,
  //     {},
  //     {},
  //     VerifyOtp
  //   );
  // };

// new

public registernew = (data: Profile, applicationId: number): Observable<VerifyOtp> => {
  return this.networkService.postNewRequest(
    WebServiceConstant.AUTH_REGISTER_USER_NEW + "?applicationId=" + applicationId,
    data,
    {},
    {},
    VerifyOtp
  );
};

// new



  public forgotpassword = (
    data: ForgotPasswordRequest
  ): Observable<Profile> => {
    return this.networkService.postNewRequest(
      WebServiceConstant.AUTH_FORGOT_PASSWORD,
      data,
      {},
      {},
      Profile
    );
  };

  public changePassword(data: ChangePasswordRequest): Observable<Profile> {
    return this.networkService.postNewRequest(
      WebServiceConstant.AUTH_CHANGE_PASSWORD,
      data,
      {},
      {},
      Profile
    );
  }

  public verifyOtp(data: VerifyOtp, params: {}): Observable<LoginResponse> {
    return this.networkService.postNewRequest(
      WebServiceConstant.AUTH_VERIFY_OTP_CREATE_DEVELOPER,
      data,
      {},
      params,
      LoginResponse
    );
  }

  public leadCapture(data: LeadRequest): Observable<LeadResponse> {
    return this.networkService.postNewRequest(
      WebServiceConstant.LEAD_CAPTURE,
      data,
      {},
      {},
      LeadResponse
    );
  }

  public lms(data: Lms): Observable<LmsResponse> {
    return this.networkService.postNewRequest(
      WebServiceConstant.LMS,
      data,
      {},
      {},
      LmsResponse
    );
  }
}
