import * as React from "react";
import { ProductRelease } from "../../../../../apiServices/Models/Products/ProductReleaseModel";
import { toggleLoader } from "../../../../../apiServices/Services/CommonServices";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { SaveMedia } from "../../../../../apiServices/Models/Products/SaveMediaRequestModel";
import { ProductService } from "../../../../../apiServices/Services/ProductServices";
import { ProductPackage } from "../../../../../apiServices/Models/Products/ProductPackageModel";
import toast from "react-hot-toast";

const objProductService = new ProductService();

interface UploadApplicationCompProps {
  productId?: string;
  releasesDto?: ProductRelease[];
  releaseId?: string;
}

const UploadApplicationComp: React.FunctionComponent<
  UploadApplicationCompProps
> = (props) => {
  const [isLoading, setLoader] = React.useState(false);
  const [isEditModeOn, setEditModeOn] = React.useState(true);
  const [releaseList, setReleaseList] = React.useState(
    new Array<ProductRelease>()
  );

  React.useEffect(() => {
    setReleaseList(props.releasesDto ? props.releasesDto : []);
  }, [props.releasesDto]);
  const productId: string = props.productId ? props.productId : "";

  const releaseId: string = props.releaseId ? props.releaseId : "";

  const toggleFormEdit = () => {
    setEditModeOn(false);
  };

  const initialValues: SaveMedia = {
    file: "",
    unityPlatform: "ANDROID",
  };

  const deleteApplication = (packageid: string) => {
    if (packageid !== undefined) {
      setLoader(true);

      objProductService.deletePackage(packageid).subscribe({
        next: (response: ProductPackage) => {},
        error: (err) => {
          setLoader(false);

          if (err.response.data) {
            toast.error(err.response.data);
          } else if (err.response.status === 500) {
            toast.error("Service unavailable.");
          } else {
            toast.error("Something went wrong, please try again!");
          }
        },
        complete: () => {
          setLoader(false);
          window.location.reload();
        },
      });
    }
  };

  const getAppListBlocks = (appList: ProductRelease[]) => {
    const appListBlocksArray: JSX.Element[] = [];
    if (appList.length > 0) {
      appList.map((app, key) => {
        return app.productPackages?.map((releasePackage, key) => {
          return appListBlocksArray.push(
            <div className="appliction">
              <span className="plateform-app">
                {releasePackage.unityPlatform}
              </span>
              <span className="size-app">
                {displaySize(releasePackage.document.size)}
              </span>
              <button
                className="app-dismis"
                onClick={() => deleteApplication(releasePackage.id)}
              >
                delete
              </button>
            </div>
          );
        });
      });
    }
    return appListBlocksArray;
  };

  const displaySize = (size: number | undefined) => {
    if (!size) return "Size Unknown";

    if (size < 1024 * 1024) return Math.round(size / 1024) + " Kb";
    else return Math.round(size / (1024 * 1024)) + " Mb";
  };

  const getUploadedApps = () => {
    return <>{getAppListBlocks(releaseList)}</>;
  };

  const handleSaveAppProduct = (values: SaveMedia) => {
    if (releaseId !== "") {
      const header = "multipart/form-data";
      const params = {
        productReleaseId: releaseId,
        unityPlatform: values.unityPlatform,
      };
      const buildData: SaveMedia = {
        file: values.file,
      };

      setLoader(true);
      const saveImage = objProductService.saveBuild(buildData, header, params);

      saveImage.subscribe({
        next: (response: ProductRelease) => {},
        error: (err) => {
          if (err.response.data) {
            toast.error(err.response.data);
          } else if (err.response.status === 500) {
            toast.error("Service unavailable.");
          } else {
            toast.error("Something went wrong, please try again!");
          }
          setLoader(false);
        },
        complete: () => {
          setLoader(false);
          window.location.reload();
        },
      });
    }
  };
  const handleCancel = (resetForm: () => void) => {
    resetForm();
    if (productId) {
      setEditModeOn(true);
    }
  };
  const projectAppsSchema = Yup.object().shape({
    unityPlatform: Yup.string().required(),
  });

  return (
    <>
      {toggleLoader(isLoading)}

      <div className="submission-section">
        <div className="submision-sub-headig">
          <h2>
            Upload<span>Application</span>
          </h2>
          {isEditModeOn && (
            <button className="edit-btn" onClick={toggleFormEdit}>
              Edit
            </button>
          )}
        </div>
        {!isLoading && (
          <Formik
            initialValues={initialValues}
            validationSchema={projectAppsSchema}
            onSubmit={() => {}}
          >
            {({
              errors,
              touched,
              values,
              setFieldTouched,
              handleChange,
              setValues,
              setFieldValue,
              resetForm,
              submitForm,
            }) => (
              <Form>
                <div className="application-upload-section">
                  <div className="upload-result">
                    {isEditModeOn === false ? (
                      getUploadedApps()
                    ) : (
                      <>
                        <div className="disablebaladiv"></div>
                        <div className="pointopacity">{getUploadedApps()}</div>
                      </>
                    )}
                  </div>

                  <div className="app-platemorm-section">
                    <div className="common-row">
                      <div className="input-inner common-col-6 common-xs-12">
                        <label>platform</label>
                        <Field
                          name="unityPlatform"
                          as="select"
                          className={
                            errors.unityPlatform && touched.unityPlatform
                              ? "error-field"
                              : ""
                          }
                          onChange={(e: any) => {
                            setFieldTouched("unityPlatform");
                            handleChange(e);
                          }}
                          disabled={isEditModeOn}
                        >
                          <option value="ANDROID">Android</option>
                          <option value="IOS">iOS</option>
                          <option value="WINDOWS">Windows</option>
                          <option value="WEBGL">WebGL</option>
                          <option value="MACOS">MacOs</option>
                        </Field>
                        <ErrorMessage
                          component="div"
                          className="errormsglabel"
                          name="mediaType"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {isEditModeOn === false ? (
                  <>
                    {" "}
                    <div className="submission-btn">
                      <div className="upload-button">
                        <div className="upload-photos">
                          <input
                            id="file"
                            name="file"
                            type="file"
                            onChange={(e: any) => {
                              setFieldValue("file", e.target.files[0]);
                              handleChange(e);
                              submitForm();
                              handleSaveAppProduct({
                                file: e.target.files[0],
                                unityPlatform: values.unityPlatform,
                              });
                            }}
                            className="form-control"
                            disabled={isEditModeOn}
                          />
                          <span>add App</span>
                        </div>
                      </div>
                      <button
                        className="cancel"
                        type="button"
                        disabled={isEditModeOn}
                        onClick={handleCancel.bind(null, resetForm)}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default UploadApplicationComp;
