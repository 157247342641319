import * as React from "react";
import { Link } from "react-router-dom";
import { EventDetailsResponse } from "../../../../apiServices/Models/WebsiteContent/EventPageModels/EventPageMainResponse";
import { getSlug } from "../../../../apiServices/Services/CommonServices";
import EventImage1 from "../../../../assets/images/our-events-img/01.png";

interface EventsCardsCompProps {
  eventsList: EventDetailsResponse[];
  limit: number;
}

export const EventsCardsComp: React.FunctionComponent<EventsCardsCompProps> = (
  props
) => {
  const eventsList = props.eventsList;
  const limit = props.limit;

  return (
    <>
      <div className="compnent-7-block-right">
        {eventsList.slice(0, limit).map((event, key) => {
          return (
            // <a
            //   href={"events/" + event.id + "/" + getSlug(event.name)}
            //   className="event-block-section"
            //   key={key}
            // >
            // <a
            //   href={
            //     process.env.REACT_APP_BASE_URL +
            //     "/events/" +
            //     event.id +
            //     "/" +
            //     getSlug(event.name)
            //   }
            //   className="event-block-section "
            //   key={key}
            // >

            <Link
              to={"/events/" + event.id + "/" + getSlug(event.name)}
              className="event-block-section "
              key={key}
            >
              <div className="event-block-image-section">
                <img
                  src={event.eventImageUrl ? event.eventImageUrl : EventImage1}
                  alt="EventImage1"
                />
              </div>

              <div className="event-block-section-inner">
                <div className="event-block-section-inner-title">
                  {event.name}
                </div>

                <div className="event-block-section-inner-text">
                  <p>{event.shortDescription.substring(0, 50).concat("...")}</p>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
};
