import * as React from "react";
import { XrBlocksModels } from "../../../apiServices/Models/WebsiteContent/XrBlockModels/XrBlocksModels";
import { ContentServices } from "../../../apiServices/Services/ContentServices";
const contentService = new ContentServices();
const getxrblocksData = (): XrBlocksModels[] => {
  return contentService.getAllxrBlocks();
};
export interface IBlockEngineDownloadCompoProps {}

export default function BlockEngineDownloadCompo(
  props: IBlockEngineDownloadCompoProps
) {
  const [xrblockList, setxrblockList] = React.useState<XrBlocksModels[]>(
    getxrblocksData()
  );

  const getFaqStucture = (id: string) => {
    if (id) {
      var intId: number = +id + 1;
      return "faq-" + intId;
    }
  };
  const getIcon = (platform: string): string => {
    switch (platform) {
      case "Windows":
        return "https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/kBZ79IE6J5iwajdk_2022-12-13T062202123871.windows.png";
      case "Android":
        return "https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/CTvd9t8OYhl4RtpK_2022-12-13T053126885043.android.png ";
      case "Mac":
        return "https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/1WS3TR8GB1FyM1pz_2022-12-13T062128060817.apple.png";
      case "MacOS":
        return "https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/1WS3TR8GB1FyM1pz_2022-12-13T062128060817.apple.png";

      case "Mac (Intel)":
        return "https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/1WS3TR8GB1FyM1pz_2022-12-13T062128060817.apple.png";
    }

    return "";
  };

  return (
    <>
      <div
        className="controller-section py-100 controller-white-bg"
        id="downloadSection"
      >
        <div className="common-container">
          <div className="common-row">
            <div className="common-sm-12 common-col-4">
              <div className="common-heading-home mb-50">
                <h2>
                  <span>Download</span> <br />
                  XR Blocks
                </h2>
              </div>
            </div>

            <div className="common-sm-12 common-col-12">
              <div className="d-none">
                {/* <div className="version-list-block">
                  <div className="virsion-title-top">
                    <strong>
                      {" "}
                      <span className="color-dark">Latest release:</span> 1.1.5
                    </strong>
                  </div>
                  <ul className="version-list">
                    <li>
                      <div className="version-inner-box">
                        <div className="left_version">
                          <img
                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/CTvd9t8OYhl4RtpK_2022-12-13T053126885043.android.png"
                            alt=""
                            className="version-icon"
                          />
                          <div className="version-data">
                            <h3>Android </h3>
                            <p>(Android 11+)</p>
                          </div>
                        </div>
                        <a href="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/hpFH7yntZjvV3JZp_2022-12-05T132121523601.UAT build 1.apk">
                          download{" "}
                          <img
                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/1srlDeAmHmkMu0Ha_2022-12-13T052920828890.Download icon.png"
                            alt=""
                          />
                        </a>
                      </div>
                    </li>

                    <li className="d-none">
                      <div className="version-inner-box">
                        <div className="left_version">
                          <img
                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/1WS3TR8GB1FyM1pz_2022-12-13T062128060817.apple.png"
                            alt=""
                            className="version-icon"
                          />
                          <div className="version-data">
                            <h3>iOS </h3>
                            <p>(..............)</p>
                          </div>
                        </div>
                        <a href="#">
                          download{" "}
                          <img
                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/1srlDeAmHmkMu0Ha_2022-12-13T052920828890.Download icon.png"
                            alt=""
                          />
                        </a>
                      </div>
                    </li>

                    <li>
                      <div className="version-inner-box">
                        <div className="left_version">
                          <img
                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/kBZ79IE6J5iwajdk_2022-12-13T062202123871.windows.png"
                            alt=""
                            className="version-icon"
                          />
                          <div className="version-data">
                            <h3>Windows </h3>
                            <p>(Windows 10+)</p>
                          </div>
                        </div>
                        <a href="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/beoMeCBeDR1Kwuzq_2022-12-12T115459963584.windowsBuild.zip">
                          download{" "}
                          <img
                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/1srlDeAmHmkMu0Ha_2022-12-13T052920828890.Download icon.png"
                            alt=""
                          />
                        </a>
                      </div>
                    </li>

                    <li className="d-none">
                      <div className="version-inner-box">
                        <div className="left_version">
                          <img
                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/1WS3TR8GB1FyM1pz_2022-12-13T062128060817.apple.png"
                            alt=""
                            className="version-icon"
                          />
                          <div className="version-data">
                            <h3>Mac (Intel) </h3>
                            <p>(Mac OSX 10.11+)</p>
                          </div>
                        </div>
                        <a href="#">
                          download{" "}
                          <img
                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/1srlDeAmHmkMu0Ha_2022-12-13T052920828890.Download icon.png"
                            alt=""
                          />
                        </a>
                      </div>
                    </li>

                    <li className="d-none">
                      <div className="version-inner-box">
                        <div className="left_version">
                          <img
                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/1WS3TR8GB1FyM1pz_2022-12-13T062128060817.apple.png"
                            alt=""
                            className="version-icon"
                          />
                          <div className="version-data">
                            <h3>Mac (Apple M1) </h3>
                            <p>(..............)</p>
                          </div>
                        </div>
                        <a href="#">
                          download{" "}
                          <img
                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/1srlDeAmHmkMu0Ha_2022-12-13T052920828890.Download icon.png"
                            alt=""
                          />
                        </a>
                      </div>
                    </li>
                  </ul>
                </div> */}
              </div>

              <div className="updated-xrBlock">
                {xrblockList?.map((xrblocks, key) => (
                  <div className="collapse_main" key={key}>
                    <input type="checkbox" id={getFaqStucture(xrblocks.id)} />
                    <div className="collapse__title">
                      <div className="sdk_top_block">
                        <span className="sdk_item">{xrblocks.versionName}</span>
                        <span className="sdk_version">
                          {xrblocks.releaseDate}
                        </span>
                        <div className="iconsListxtblock">
                          <ul>
                            {xrblocks.downloads.map((download, keydownload) => {
                              return (
                                <>
                                  <li key={xrblocks.id}>
                                    <img
                                      src={getIcon(download.platform)}
                                      alt=""
                                      className="newListicon"
                                    />
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="collapse_body">
                      <div className="xrblockversion-list">
                        <ul>
                          {xrblocks.downloads.map((list, index) => {
                            return (
                              <>
                                <li key={index}>
                                  <div className="versionlist_inner">
                                    <span className="versionPlateform">
                                      {list.platform}
                                    </span>
                                    <span className="versionsize">
                                      {list.size}
                                    </span>
                                    <a href={list.downloadUrl}>Download</a>
                                  </div>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
