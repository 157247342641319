import * as React from "react";
import SwiperCore, { EffectFade, FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/effect-fade";
import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet, HelmetProvider } from "react-helmet-async";

interface PropsBuyProduct {}

export const BuyProduct: React.FunctionComponent<PropsBuyProduct> = (
  props: any
) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [thumbsSwiper, setThumbsSwiper] = React.useState<SwiperCore>();

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>TechXR - Buy</title>
          <meta
            name="title"
            content="Augmented & Technologies SenseXR | TechXR"
          />
          <meta
            name="description"
            content=" To know about XR Controller and how it works. Explore its different types of hardware. We can use it in different areas like gaming, education, architecture and 3D modelling."
          />
        </Helmet>
      </HelmetProvider>

      <div className="project-main block_engine mx-w-1440">
        <div className="common-container">
          <div className="common-row top_bar_row">
            <div className="project-details-left_side">
              <div className="product-left-inner">
                <div className="slider-section">
                  <Swiper
                    loop={true}
                    spaceBetween={10}
                    navigation={true}
                    thumbs={{
                      swiper:
                        thumbsSwiper && !thumbsSwiper.destroyed
                          ? thumbsSwiper
                          : null,
                    }}
                    effect={"fade"}
                    fadeEffect={{ crossFade: true }}
                    modules={[FreeMode, Navigation, Thumbs, EffectFade]}
                    className="mySwiper2"
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                      },
                      640: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 1,
                      },
                      1024: {
                        slidesPerView: 1,
                      },
                    }}
                  >
                    <SwiperSlide>
                      <div className="xr_imgblock">
                        <img
                          src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/X6eWUA5Rhr3zbZJe_2022-12-06T055353447726.Block-1.jpg"
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="xr_imgblock">
                        <img
                          src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/WT8ylpno57JHmdaU_2022-12-06T055541916897.Block-2.jpg"
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="xr_imgblock">
                        <img
                          src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/oRMQrK7lvcwkwnV5_2022-12-06T055617767200.Block-3.jpg"
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="xr_imgblock">
                        <img
                          src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/sXHyxuBKG5rKBqYC_2022-12-06T055644624910.Block-4.jpg"
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="xr_imgblock">
                        <img
                          src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/ds59SVpjN6KW4OaA_2022-12-06T055742966367.Block-5.jpg"
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="xr_imgblock">
                        <img
                          src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/K89tA81ciCe10uM4_2022-12-06T055810944282.Block-6.jpg"
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="video_section_blockxr">
                        <iframe
                          width="942"
                          height="530"
                          src="https://www.youtube.com/embed/3bzcVaXNJ0o"
                          title="XR Blocks"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                  <Swiper
                    style={{ width: "100%" }}
                    onSwiper={setThumbsSwiper}
                    spaceBetween={0}
                    slidesPerView={5}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[Thumbs]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <div className="xr_imgblock">
                        <img
                          src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/X6eWUA5Rhr3zbZJe_2022-12-06T055353447726.Block-1.jpg"
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="xr_imgblock">
                        <img
                          src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/WT8ylpno57JHmdaU_2022-12-06T055541916897.Block-2.jpg"
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="xr_imgblock">
                        <img
                          src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/oRMQrK7lvcwkwnV5_2022-12-06T055617767200.Block-3.jpg"
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="xr_imgblock">
                        <img
                          src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/sXHyxuBKG5rKBqYC_2022-12-06T055644624910.Block-4.jpg"
                          alt=""
                        />
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div className="xr_imgblock">
                        <img
                          src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/ds59SVpjN6KW4OaA_2022-12-06T055742966367.Block-5.jpg"
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="xr_imgblock">
                        <img
                          src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/K89tA81ciCe10uM4_2022-12-06T055810944282.Block-6.jpg"
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="xr_imgblock">
                        <img
                          src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/TqeqTsLxdGS0wpxm_2022-12-07T123142947163.video.jpg"
                          alt=""
                        />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
            <div className="project-details-right_side">
              <div className="product-right-inner">
                <h1>XR blocks (Alpha Release)</h1>
                <div className="buynowPrice">
                  <p>
                    <del>₹ 4999.00 </del> <strong>₹ 2,999.00</strong>
                  </p>
                </div>
                <div className="buyNowButton">
                  <a href="#">Buy Now</a>
                  <a href="#">Buy On Amazon</a>
                </div>
                <div className="right_content_details">
                  <p>
                    XR-blocks is a platform where users can create AR-VR
                    experiences on their own. It provides users with an
                    interface to build their scene, by introducing assets from a
                    provided library. On top of it, they can also implant
                    intelligence to the assets by dragging appropriate
                    logical/action blocks to the particular assets.
                  </p>
                </div>

                <div className="right_content_details">
                  <div className="pro_details_sub first">
                    <h2>
                      <span>About </span> This Item
                    </h2>
                  </div>
                  <div className="item-list">
                    <ul>
                      <li>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Quibusdam, atque!
                      </li>
                      <li>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Quibusdam, atque!
                      </li>
                      <li>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Quibusdam, atque!
                      </li>
                      <li>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Quibusdam, atque!
                      </li>
                      <li>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Quibusdam, atque!
                      </li>
                      <li>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Quibusdam, atque!
                      </li>
                      <li>
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Quibusdam, atque!
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="common-row bottom_bar_row">
            <div className="project-details-left_side">
              <div className="product-left-inner">
                <div className="plateforms_block">
                  <div className="pro_details_sub">
                    <h2>
                      <span>Compatible </span> Platforms
                    </h2>
                  </div>
                  <div className="buycontroller_table_type">
                    <table>
                      <tbody>
                        <tr>
                          <td className="lefttable">Brand</td>
                          <td className="righttable">TECHXR</td>
                        </tr>
                        <tr>
                          <td className="lefttable">Manufacturer</td>
                          <td className="righttable">
                            TechXR Innovations Pvt. Ltd.
                          </td>
                        </tr>
                        <tr>
                          <td className="lefttable">Model</td>
                          <td className="righttable">TXRSENSE2</td>
                        </tr>
                        <tr>
                          <td className="lefttable">Model Name</td>
                          <td className="righttable">SenseXR</td>
                        </tr>
                        <tr>
                          <td className="lefttable">Product Dimensions</td>
                          <td className="righttable">
                            15 x 10 x 10 cm; 800 Grams
                          </td>
                        </tr>
                        <tr>
                          <td className="lefttable">Item model number</td>
                          <td className="righttable">TXRSENSE2</td>
                        </tr>
                        <tr>
                          <td className="lefttable">Operating System</td>
                          <td className="righttable">Android</td>
                        </tr>
                        <tr>
                          <td className="lefttable">Hardware Platform</td>
                          <td className="righttable">PC/Mac, iOS, Android</td>
                        </tr>
                        <tr>
                          <td className="lefttable">Compatible Devices</td>
                          <td className="righttable">
                            Personal Computer, IOS, Mac, Android
                          </td>
                        </tr>
                        <tr>
                          <td className="lefttable">Special Features</td>
                          <td className="righttable">
                            Wireless, Ergonomic, Motion Sensing, Multiuse
                          </td>
                        </tr>
                        <tr>
                          <td className="lefttable">Power Source</td>
                          <td className="righttable">2 AAA Batteries</td>
                        </tr>
                        <tr>
                          <td className="lefttable">Connector Type</td>
                          <td className="righttable"> Bluetooth</td>
                        </tr>
                        <tr>
                          <td className="lefttable">Material</td>
                          <td className="righttable">Plastic</td>
                        </tr>
                        <tr>
                          <td className="lefttable">Country of Origin</td>
                          <td className="righttable">India</td>
                        </tr>
                        <tr>
                          <td className="lefttable">Item Weight</td>
                          <td className="righttable">800 g</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="plateforms_block">
                  <div className="pro_details_sub">
                    <h2>
                      <span>Top </span> reviews from India
                    </h2>
                  </div>
                  <div className="buycontroller_table_type">
                    <div className="reviewList_block">
                      <ul>
                        <li>
                          <div className="inner_reviewList">
                            <div className="usernameAndImg">
                              <div className="userReviewimg">
                                <img
                                  src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/y5Xsnc8yneeUiKxd_2023-01-21T101336850991.Skilled Professionals1.b7a3a75a4f69d8fb63dc.png"
                                  alt=""
                                />
                              </div>
                              <span>Raju lovanshi</span>
                            </div>

                            <div className="userReviewhighlight">
                              <img
                                src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/zSihAQXAv2OTxgN2_2023-01-21T101640760878.star.png"
                                alt=""
                              />
                              <p>
                                <strong>
                                  {" "}
                                  Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Laboriosam, cum?
                                </strong>
                              </p>
                            </div>

                            <div className="reviewdate">
                              <span>Reviewed in India on 10 January 2023</span>
                            </div>

                            <span className="verified">Verified Purchase</span>

                            <div className="mainreview">
                              <p>
                                It's one of its kind... First time a product of
                                this kind in India. My son is engrossed in
                                enjoying AR and VR videos and games after
                                opening it, he is so excited about the product.
                              </p>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="inner_reviewList">
                            <div className="usernameAndImg">
                              <div className="userReviewimg">
                                <img
                                  src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/y5Xsnc8yneeUiKxd_2023-01-21T101336850991.Skilled Professionals1.b7a3a75a4f69d8fb63dc.png"
                                  alt=""
                                />
                              </div>
                              <span>Raju lovanshi</span>
                            </div>

                            <div className="userReviewhighlight">
                              <img
                                src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/zSihAQXAv2OTxgN2_2023-01-21T101640760878.star.png"
                                alt=""
                              />
                              <p>
                                <strong>
                                  {" "}
                                  Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Laboriosam, cum?
                                </strong>
                              </p>
                            </div>

                            <div className="reviewdate">
                              <span>Reviewed in India on 10 January 2023</span>
                            </div>

                            <span className="verified">Verified Purchase</span>

                            <div className="mainreview">
                              <p>
                                It's one of its kind... First time a product of
                                this kind in India. My son is engrossed in
                                enjoying AR and VR videos and games after
                                opening it, he is so excited about the product.
                              </p>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="inner_reviewList">
                            <div className="usernameAndImg">
                              <div className="userReviewimg">
                                <img
                                  src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/y5Xsnc8yneeUiKxd_2023-01-21T101336850991.Skilled Professionals1.b7a3a75a4f69d8fb63dc.png"
                                  alt=""
                                />
                              </div>
                              <span>Raju lovanshi</span>
                            </div>

                            <div className="userReviewhighlight">
                              <img
                                src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/zSihAQXAv2OTxgN2_2023-01-21T101640760878.star.png"
                                alt=""
                              />
                              <p>
                                <strong>
                                  {" "}
                                  Lorem ipsum dolor sit amet consectetur
                                  adipisicing elit. Laboriosam, cum?
                                </strong>
                              </p>
                            </div>

                            <div className="reviewdate">
                              <span>Reviewed in India on 10 January 2023</span>
                            </div>

                            <span className="verified">Verified Purchase</span>

                            <div className="mainreview">
                              <p>
                                It's one of its kind... First time a product of
                                this kind in India. My son is engrossed in
                                enjoying AR and VR videos and games after
                                opening it, he is so excited about the product.
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="how_to_play">
                  <div className="pro_details_sub">
                    <h2>
                      <span>Customer </span> questions & answers
                    </h2>
                  </div>

                  <div className="question_answer_list">
                    <ul>
                      <li>
                        <div className="inner__question__answer">
                          <div className="QAndA_block">
                            <div className="QandA question_block">
                              <strong>Question :</strong>
                              <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Nihil, provident.
                              </p>
                            </div>
                            <div className="QandA">
                              <strong>Answer :</strong>
                              <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Nihil, provident.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="inner__question__answer">
                          <div className="QAndA_block">
                            <div className="QandA question_block">
                              <strong>Question :</strong>
                              <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Nihil, provident.
                              </p>
                            </div>
                            <div className="QandA">
                              <strong>Answer :</strong>
                              <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Nihil, provident.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="inner__question__answer">
                          <div className="QAndA_block">
                            <div className="QandA question_block">
                              <strong>Question :</strong>
                              <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Nihil, provident.
                              </p>
                            </div>
                            <div className="QandA">
                              <strong>Answer :</strong>
                              <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Nihil, provident.
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
