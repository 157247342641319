import * as React from "react";
import { HomePageTestimoniesResponse } from "../../../../apiServices/Models/WebsiteContent/HomePageModels/HomePageTestimoniesResponse";
import { ContentServices } from "../../../../apiServices/Services/ContentServices";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { FreeMode, Pagination, Autoplay, Navigation } from "swiper";

// // Import Swiper styles
import "swiper/css";
import "swiper/css/bundle";

import Sliderbefore from "../../../../assets/images/sliderbefore.png";
const contentService = new ContentServices();
const getTestimoniesData = (): HomePageTestimoniesResponse[] => {
  return contentService.getAllTestimonies();
};

interface TestimoniesCardsCompProps {}

export const TestimoniesCardsComp: React.FunctionComponent<
  TestimoniesCardsCompProps
> = (props) => {
  const [testimoniesList, setTestimonies] = React.useState<
    HomePageTestimoniesResponse[]
  >(getTestimoniesData());

  return (
    <Swiper
      slidesPerView={3}
      spaceBetween={30}
      freeMode={true}
      navigation={true}
      pagination={{
        clickable: true,
      }}
      modules={[FreeMode, Pagination, Autoplay, Navigation]}
      className="mySwiper"
      autoplay={{
        delay: 8000,
        disableOnInteraction: false,
      }}
      loop={true}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        640: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
      }}
    >
      {testimoniesList.map((testimony, key) => {
        return (
          <SwiperSlide key={key}>
            <div className="item">
              <div className="testimony-wrap">
                <span className="quote d-flex align-items-center justify-content-center">
                  <img src={Sliderbefore} alt="" />
                </span>
                <span className="quote1 d-flex align-items-center justify-content-center">
                  <img src={Sliderbefore} alt="" />
                </span>
                <div className="text">
                  <p>{testimony.testimonial}</p>
                </div>
                <div className="align-items-center data">
                  <div className="user-img">
                    <img src={testimony.imageUrl} alt="PersonImage" />
                  </div>
                  <div className="pl-3">
                    <p className="name">{testimony.name}</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
