import axios from "axios";
import { Observable, Observer } from "rxjs";
import { plainToInstance } from "class-transformer";
import { HandleAuthToken } from "../../shared/core/Utils/HandleAuthToken";

export type RequestDataType<T> = T;

export class NetworkService {
  getHeader(requestHeader?: any) {
    const objAuthHandler = new HandleAuthToken();
    const token = JSON.parse(objAuthHandler?.getItem() || "{}").access_token;
    let header: any;
    if (token === undefined) {
      header = {};
    } else if (requestHeader && Object.keys(requestHeader).length === 0) {
      header = {
        Authorization: `Bearer ${token}`,
      };
    } else {
      header = {
        Authorization: `Bearer ${token}`,
        "Content-Type": requestHeader,
      };
    }
    // console.log("Get Token:", header);
    return header;
  }

  // headers: any;
  getRequest = <R extends {}>(
    url: string,
    header: any,
    params: {},
    r: new () => R
  ): Observable<R> => {
    header = this.getHeader();
    // console.log("Get Header:", header);
    return new Observable((observer: Observer<R>) => {
      axios({
        method: "get",
        url: url,
        headers: header,
        params: params,
      })
        .then((data) => {
          observer.next(plainToInstance(r, data.data));
          observer.complete();
        })
        .catch((err) => {
          console.log(err.code);
          if (err.response.status === 500 || err.code === "ERR_NETWORK") {
            window.location.href = "/backend-error";
          }
          observer.error(err);
        });
    });
  };

  postNewRequest = <T extends {}, R extends {}>(
    url: string,
    data: T,
    header: any,
    params: {},
    r: new () => R
  ): Observable<R> => {
    header = this.getHeader(header);
    return new Observable((observer: Observer<R>) => {
      axios({
        method: "post",
        url: url,
        headers: header,
        data: data,
        params: params,
      })
        .then((data) => {
          observer.next(plainToInstance(r, data.data));
          observer.complete();
        })
        .catch((err) => {
          if (err.response.status === 500 || err.code === "ERR_NETWORK") {
            window.location.href = "/backend-error";
          }
          observer.error(err);
        });
    });
  };

  deleteRequest = <R extends {}>(
    url: string,
    header: any,
    params: {},
    r: new () => R
  ): Observable<R> => {
    header = this.getHeader(header);
    // console.log("Get Header:", header);
    return new Observable((observer: Observer<R>) => {
      axios({
        method: "delete",
        url: url,
        headers: header,
        params: params,
      })
        .then((data) => {
          observer.next(plainToInstance(r, data.data));
          observer.complete();
        })
        .catch((err) => {
          if (err.response.status === 500 || err.code === "ERR_NETWORK") {
            window.location.href = "/backend-error";
          }
          observer.error(err);
        });
    });
  };
}
