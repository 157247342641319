import * as React from "react";
import { CourseCardDetailResponse } from "../../../../apiServices/Models/WebsiteContent/CoursePageModels/CourseCardDetailResponse";
import { CourseStructureResponse } from "../../../../apiServices/Models/WebsiteContent/CoursePageModels/CourseStructure/CourseStructureResponse";
import { ContentServices } from "../../../../apiServices/Services/ContentServices";

const contentService = new ContentServices();

interface CourseStructureDetailsCompProps {
  courseId: string | undefined;
}

export const CourseStructureDetailsComp: React.FunctionComponent<
  CourseStructureDetailsCompProps
> = (props) => {
  const courseId = props.courseId;
  const [courseStructureDetail, setCourseStructureDetail] =
    React.useState<CourseStructureResponse>();

  // console.log(courseDetail.findIndex(course, 0));

  React.useEffect(() => {
    const getCourseStructureDetail = (): CourseStructureResponse => {
      return contentService.getCourseStructureDetail(courseId);
    };
    setCourseStructureDetail(getCourseStructureDetail());
  }, [courseId, setCourseStructureDetail]);

  const getStructureContentId = (id: string) => {
    if (id) {
      var intId: number = +id + 1;
      // console.log(intId);
      return "faq-" + intId;
    }
  };
  // console.log(courseStructureDetail);
  return (
    // <!--course structure tab start-->
    <div className="course-structure-tab">
      {courseStructureDetail?.structure?.map((structure, key) => (
        <div className="course-str-tab" key={key}>
          <input type="checkbox" id={getStructureContentId(structure.id)} />
          <h2>
            <label htmlFor={getStructureContentId(structure.id)}>
              {structure.title}
            </label>
          </h2>
          <div className="p">
            {structure.content ? (
              <div
                dangerouslySetInnerHTML={{ __html: structure.content }}
              ></div>
            ) : (
              <div className="clear"></div>
            )}
          </div>
        </div>
      ))}
    </div>

    // <!--course structure  tab  end-->
  );
};
