import Structure0 from "./0-Structure.json";
import Structure1 from "./1-Structure.json";
import Structure2 from "./2-Structure.json";
import Structure3 from "./3-Structure.json";
import Structure4 from "./4-Structure.json";
import Structure5 from "./5-Structure.json";
import Structure6 from "./6-Structure.json";
import Structure7 from "./7-Structure.json";
import Structure8 from "./8-Structure.json";
import Structure9 from "./9-Structure.json";

let allCoursesStructureJson = [Structure0, Structure1, Structure2, Structure3, Structure4, Structure5, Structure6, Structure7, Structure8, Structure9];

export default allCoursesStructureJson;
