import * as React from "react";
import { ContentServices } from "../../../apiServices/Services/ContentServices";
import { toggleLoader } from "../../../apiServices/Services/CommonServices";
import { EventsCardsComp } from "../HomePage/EventComponents/EventsCardsComp";
import { isEventInFuture } from "../EventPage/Helpers/EventPageHelper";
import { EventDetailsResponse } from "../../../apiServices/Models/WebsiteContent/EventPageModels/EventPageMainResponse";
import { Helmet, HelmetProvider } from "react-helmet-async";

const contentService = new ContentServices();

let filteredEvent: EventDetailsResponse[] = new EventDetailsResponse();

interface AllEventsMainCompProps {}

export const AllEventsMainComp: React.FunctionComponent<
  AllEventsMainCompProps
> = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [eventsList, setEvents] = React.useState<EventDetailsResponse[]>([]);
  const [isLoading, setLoader] = React.useState(false);

  const getEventsData = (): EventDetailsResponse[] => {
    setLoader(true);
    return contentService.getAllEvents();
  };

  React.useEffect(() => {
    setEvents(getEventsData());
    setLoader(false);
  }, [setEvents]);

  return (
    <>
      {toggleLoader(isLoading)}
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>All Events | TechXR</title>
          <meta name="title" content="All Events | TechXR" />
          <meta
            name="description"
            content="Visit TechXR to read our new events like our workshop, seminar, opportunity and many more. We provide high level VR experience. Go now and explore more details."
          />
          <meta
            property="image"
            content="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/uD41orAQL8hE2zva_2023-05-30T064448436786.Img web.png"
          />
        </Helmet>
      </HelmetProvider>
      {eventsList.length > 0 ? (
        <div className="main-section mx-w-1440">
          <div className="all-event py-100">
            <div className="common-container">
              <div className="common-heading">
                <h1>All Events</h1>
              </div>

              <div className="event-block">
                {
                  (filteredEvent = eventsList.filter((event) =>
                    isEventInFuture(event.date)
                  ))
                }
                {filteredEvent.length > 0 ? (
                  <div className="upcoming-event-display mb-50">
                    <h2>Upcoming Events</h2>

                    <div className="Event_col_custum">
                      <EventsCardsComp
                        eventsList={filteredEvent}
                        limit={filteredEvent.length}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="all-event-display mb-50">
                  <h2>Past Events</h2>
                  <div className="Event_col_custum">
                    <EventsCardsComp
                      eventsList={
                        (filteredEvent = eventsList.filter(
                          (event) => !isEventInFuture(event.date)
                        ))
                      }
                      limit={filteredEvent.length}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <p>No Events to display at this time.</p>
        </div>
      )}
    </>
  );
};
