import Detail0 from "./0-Detail.json";
import Detail1 from "./1-Detail.json";
import Detail2 from "./2-Detail.json";
import Detail3 from "./3-Detail.json";
import Detail4 from "./4-Detail.json";
import Detail5 from "./5-Detail.json";
import Detail6 from "./6-Detail.json";
import Detail7 from "./7-Detail.json";
import Detail8 from "./8-Detail.json";
import Detail9 from "./9-Detail.json";

let allCoursesDetailJson = [Detail0, Detail1, Detail2, Detail3, Detail4, Detail5, Detail6, Detail7, Detail8, Detail9];

export default allCoursesDetailJson;
