import * as React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import BG1 from "../../../assets/images/auth-pages-background/bg_png.png";
import BG2 from "../../../assets/images/auth-pages-background/bg_png2.png";

interface RefundPageCompProps {}

const RefundPageComp: React.FunctionComponent<RefundPageCompProps> = (
  props
) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Refund Policy | TechXR</title>
          <meta name="title" content="Refund Policy | TechXR" />
          <meta
            name="description"
            content="Our return policy is most reliable. If you’re not satisfied with courses, where a refund request is raised anytime within 7 days of Course Start Date. Visit now to know more!"
          />
          <meta
            property="image"
            content="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/uD41orAQL8hE2zva_2023-05-30T064448436786.Img web.png"
          />
        </Helmet>
      </HelmetProvider>
      <div className="Privacy-main py-100 mx-w-1440">
        <div className="common-container">
          <img src={BG1} alt="" className="bg-overlay" />
          <img src={BG2} alt="" className="bg-overlay second" />
          <div className="Privacy-row">
            <div className="common-heading privacy-heading">
              <h1 className="mb-10">Refund Policy</h1>
              <h4>This Privacy Policy applies to the techxr.co</h4>
            </div>

            <div className="privacy-content-block">
              <ol className="refund_block">
                <li>
                  The refund for the courses joined, and paid for, at Techxr
                  shall be governed by the following policy.
                </li>
                <li>
                  Definition <br></br>
                  <ul className="alfa">
                    <li>
                      The Course price will be referred to as{" "}
                      <strong>“Sale Price”</strong>.
                    </li>
                    <li>
                      The Hard goods which are included in the course will be
                      referred to as
                      <strong>“Kit”</strong>.
                    </li>
                    <li>
                      The date at which course will start will be referred to as{" "}
                      <strong> “Course Start Date”</strong>
                    </li>
                    <li>
                      The <strong>Kit</strong> includes a) Virtual Reality
                      Headset b) Developer Cube which will be shipped on the day
                      of payment.
                    </li>
                    <li>
                      <strong>Processing Fee</strong> shall be taken as Rs. 500
                      when the Kit is returned in original shape and Rs. 2000
                      otherwise.
                    </li>
                  </ul>
                </li>
                <li>
                  Where a user has decided to quit the course he/she can seek a
                  Refund of the course fee, within one week of the course
                  commencement, but not thereafter, by intimating to the course
                  team in writing about the decision to seek an exit.
                </li>
                <li>
                  The amount refunded to the user shall be dependent on the time
                  at which the refund is applied and shall be governed by this
                  refund policy.
                </li>
                <li>
                  Where a refund request is raised{" "}
                  <strong>before the Course Start</strong> Date, the full amount
                  will be refunded, deducting the Processing Fee.
                </li>
                <li>
                  Where a refund request is raised{" "}
                  <strong>anytime within 7 days of Course Start Date</strong>,
                  then full amount will be refunded after deducting the (a)
                  Processing Fee, and (b) Rs. 1000.
                </li>
                <li>
                  Where a refund request is raised{" "}
                  <strong>anytime after the 7 days of Course Start Date</strong>
                  , no amount will be refunded.
                </li>
              </ol>
            </div>

            <div className="privacy-content-block">
              <p>
                For any other queries please contact us at sensexr@techxr.co
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundPageComp;
