export class VerifyOtp {
    constructor(){
        this.id = -1
        this.countryCode = ""
        this.callStatus = ""
        this.whatsappStatus = ""
        this.phoneNumber = ""
    }

    public id:number;
    public countryCode:string;
    public callStatus:string;
    public whatsappStatus:string;
    public phoneNumber:string;
}