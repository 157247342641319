import * as React from "react";
import * as CoursePage from "../CoursePage";
import { useParams } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ContentServices } from "../../../apiServices/Services/ContentServices";
import { CourseDetailResponse } from "../../../apiServices/Models/WebsiteContent/CoursePageModels/CourseDetailResponse";
import { CourseCardDetailResponse } from "../../../apiServices/Models/WebsiteContent/CoursePageModels/CourseCardDetailResponse";

const contentService = new ContentServices();

interface CoursePageMainCompProps {}

export const CoursePageMainComp: React.FunctionComponent<
  CoursePageMainCompProps
> = (props) => {
  const { id } = useParams();
  // console.log(id);
  const [courseDetail, setCourseDetail] =
    React.useState<CourseDetailResponse>();
  const [courseCardDetail, setCourseCardDetail] =
    React.useState<CourseCardDetailResponse>();

  React.useEffect(() => {
    const getCourseDetail = (): CourseDetailResponse => {
      return contentService.getCourseDetail(id);
    };
    setCourseDetail(getCourseDetail());
    const getCourseCardDetail = (): CourseCardDetailResponse => {
      return contentService.getCourseCardDetail(id);
    };
    setCourseCardDetail(getCourseCardDetail());
  }, [id, setCourseDetail]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{"TechXR - " + courseDetail?.name} </title>
          <meta name="title" content={courseDetail?.name} />
          <meta name="description" content={courseDetail?.seoDescripton} />
          <meta name="keywords" content={courseDetail?.keyWords} />
          <meta property="image" content={courseCardDetail?.imageUrl} />
          <link
            rel="canonical"
            href={`https://techxr.co/courses/${courseCardDetail?.id}`}
          />
          <link rel="icon" type="image" href="./favicon.png" />
        </Helmet>
      </HelmetProvider>
      <div className="main-section mx-w-1440">
        <CoursePage.CourseAboveFoldMainComp
          courseId={id}
        ></CoursePage.CourseAboveFoldMainComp>

        {/* <!--course Content Section  Main Start--> */}
        <div className="course-content-section-main pb-100 ">
          {/* <!--course Content Section Start--> */}
          <div className="course-content-section common-container">
            <div className="common-row">
              <div className="common-col-8 common-md-10 common-sm-12">
                <CoursePage.CourseDetailsComp
                  courseId={id}
                ></CoursePage.CourseDetailsComp>

                <CoursePage.CourseStructureMainComp
                  courseId={id}
                ></CoursePage.CourseStructureMainComp>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
