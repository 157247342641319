import * as React from "react";
import { ProductMedia } from "../../../../apiServices/Models/Products/ProductMediaModel";
import { ProductSummary } from "../../../../apiServices/Models/Products/ProductSummaryModel";
import {
  getSlug,
  getYouTubeEmbedLink,
  isImage,
} from "../../../../apiServices/Services/CommonServices";
import detailsWhiteImage from "../../../../assets/images/all-projects/detailswhite.png";
import ImageNotFound from "../../../../assets/images/project-details/Img-not-found.jpg";

interface CardCompoProps {
  product: ProductSummary;
  key: number;
}

export const CardCompo: React.FunctionComponent<CardCompoProps> = (props) => {
  const product = props.product;
  const key = props.key;

  let imageUrl: string = ImageNotFound;
  const getMediaUrl = () => {
    let imageArray: ProductMedia[] = new Array<ProductMedia>();

    if (product.productMediaDto) {
      // );
      imageArray = product.productMediaDto;

      if (imageArray && imageArray.length > 0) {
        imageArray.map((imageMedia, key) => {
          if (imageMedia.thumbnail && imageMedia.document) {
            imageUrl = imageMedia.document.url;
          }
        });
        if (imageUrl === ImageNotFound) {
          if (imageArray[0].document) imageUrl = imageArray[0].document.url;
        }
      }
    }
    const isImageUrl = isImage(imageUrl);
    if (isImageUrl) {
      return (
        <a
          href={"/projects/" + product.id + "/" + getSlug(product.name)}
          className="project-link"
        >
          <div className="project-thumb" key={key}>
            <img src={imageUrl} alt="" />

            <div className="overlay_project_d">
              <h2>{product.name}</h2>
            </div>
          </div>
        </a>
      );
    } else {
      imageUrl = getYouTubeEmbedLink(imageUrl);
      return (
        <a
          href={"/projects/" + product.id + "/" + getSlug(product.name)}
          className="project-link"
        >
          <div className="project-thumb">
            <iframe
              width="100%"
              height="300px"
              src={imageUrl}
              title="YouTube video player"
              allowFullScreen
            ></iframe>

            <div className="overlay_project_d">
              <h2>{product.name}</h2>
            </div>
          </div>
        </a>
      );
    }
  };

  return (
    <>
      <div className="common-col-4 common-md-6 common-xs-12" key={key}>
        <div className="project-card-inner">
          {getMediaUrl()}

          <div className="project-flex">
            <img src={detailsWhiteImage} alt="" className="detail_pro_img" />
            <div className="project-short-details">
              <span className="auther-project">
                Developed by: {product.developerName}
              </span>
              <p>
                {product.description.trim().substring(0, 100).concat("...")}
              </p>
            </div>
            <a
              href={"/projects/" + product.id + "/" + getSlug(product.name)}
              className="image-overlay"
            >
              <span>Quick Look</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardCompo;
