import * as React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Bgimage from "../../../assets/images/auth-pages-background/bg_png.png";
import Bgimage2 from "../../../assets/images/auth-pages-background/bg_png2.png";
interface TermsPageCompProps {}

const TermsPageComp: React.FunctionComponent<TermsPageCompProps> = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Terms and Conditions | TechXR</title>
          <meta name="title" content="Terms and Conditions | TechXR." />
          <meta
            name="description"
            content="When you are using Techxr’s services you should know about the Terms of Use which is provided on our website. By signing up on the Techxr you are agreeing to our terms."
          />
          <meta
            property="image"
            content="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/uD41orAQL8hE2zva_2023-05-30T064448436786.Img web.png"
          />
        </Helmet>
      </HelmetProvider>
      <div className="Privacy-main py-100 mx-w-1440">
        <div className="common-container">
          <img src={Bgimage} alt="" className="bg-overlay" />
          <img src={Bgimage2} alt="" className="bg-overlay second" />
          <div className="Privacy-row">
            <div className="common-heading privacy-heading">
              <h1 className="mb-10">
                <h1>Terms of Use</h1>
              </h1>
              <h4>This Terms of Use applies to the techxr.co</h4>
            </div>
            <div className="privacy-content-block">
              <p>
                <strong>
                  By signing up on the Techxr Innovations Private Limited
                  Website you are agreeing to be bound by the following terms
                  and conditions (“Terms of Use”).
                </strong>
              </p>{" "}
            </div>

            <div className="privacy-content-block">
              <p>
                As the original purchaser of content sold on Techxr Innovations
                Private Limited, you are entitled to access and use the content
                which is identified in the course and which is on the Techxr
                Innovations Private Limited website, at learn.techxr.co
                ("Website"). In order to access and use this content, you must
                register with Techxr Innovations Private Limited and create a
                password to use in accessing the content on the Website.
              </p>

              <p>
                Your password is unique and exclusive to you, and you may not
                transfer your password to any other person or allow any other
                person to use your password to access or use content on the
                Website. You agree to notify Techxr Innovations Private Limited
                immediately if any other person makes unauthorized use of your
                password. Techxr Innovations Private Limited reserves the right
                to suspend or revoke your password in the event of any misuse of
                your password or any use of your password in violation of these
                Terms and Conditions. In the event of any such suspension or
                revocation, you will not be entitled to any refund or payment.
              </p>

              <p>
                These Terms of Use govern your access to and use of the Website
                and the content on the Website. By accessing and using the
                Website, you agree to these Terms of Use. If you do not agree to
                any of these Terms of Use, you may not access or use the site.
                Techxr Innovations Private Limited reserves the right to modify
                these Terms of Use at any time and in its sole discretion. Your
                use of the site following any modification will constitute your
                assent to and acceptance of the modifications.
              </p>

              <p>
                Upon registration, Techxr Innovations Private Limited grants you
                a non-exclusive, non-transferable, non-assignable, personal
                license to access and use the Techxr Innovations Private Limited
                content identified in the content you purchased via an
                online/offline reader.
              </p>

              <p>
                Techxr Innovations Private Limited will not be liable for any
                delay or interruption in your access to the site or any content
                located on the site, or for any transmission errors, equipment
                or software incompatibilities, force majeure or other failure of
                performance. Techxr Innovations Private Limited will use
                reasonable efforts to correct any failure of performance, but
                Techxr Innovations Private Limited will not be required to make
                any changes to any equipment or software used by Techxr
                Innovations Private Limited or its contractors or agents to
                ensure compatibility with any equipment or software used by you.
                You may not use the site or the content on the site for any
                commercial purpose, including but not limited to the use of any
                of the content to market or sell goods or services to any
                person. You agree not to launch any automated system, including
                without limitation, "robots," "spiders," or "offline readers,"
                to access the site.
              </p>

              <p>
                Techxr Innovations Private Limited reserves the right to change,
                suspend access to, or remove any or all of the content on the
                Website at any time, for any reason, in its sole discretion.
                Techxr Innovations Private Limited also reserves the right to
                discontinue the Website at any time, either temporarily or
                permanently. In the event of the removal of any content from the
                Website or the termination of the Website, you will not be
                entitled to any refund or payment.
              </p>

              <p>
                YOU AGREE THAT YOUR USE OF THE SITE SHALL BE AT YOUR SOLE RISK,
                AND Techxr Innovations Private Limited WILL NOT BE HELD LIABLE
                IN ANY WAY FOR YOUR USE OF THE SITE OR FOR ANY INFORMATION
                CONTAINED ON THE SITE. ALL CONTENT CONTAINED IN OR REFERRED TO
                ON THE SITE IS PROVIDED "AS IS," WITHOUT ANY REPRESENTATIONS OR
                WARRANTIES, EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMITTED
                BY LAW, Techxr Innovations Private Limited DISCLAIMS ALL
                WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION,
                ALL IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
                PARTICULAR PURPOSE. Techxr Innovations Private Limited MAKES NO
                WARRANTIES THAT THE SITE WILL BE ERROR-FREE, OR THAT ANY ERRORS
                WILL BE CORRECTED, OR THAT THE SITE OR THE SERVER FROM WHICH THE
                SITE IS OPERATED WILL BE FREE OF VIRUSES OR OTHER POTENTIALLY
                HARMFUL CODES. UNDER NO CIRCUMSTANCES, INCLUDING NEGLIGENCE,
                SHALL Techxr Innovations Private Limited BE HELD LIABLE FOR ANY
                DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES
                AND EXPENSES OF ANY KIND (INCLUDING, WITHOUT LIMITATION,
                PERSONAL INJURY OR PROPERTY DAMAGE, LOST PROFITS, AND DAMAGES
                ARISING FROM COMPUTER VIRUSES, BUSINESS INTERRUPTION, LOST DATA,
                UNAUTHORIZED ACCESS TO OR USE OF SITE SERVERS OR ANY PERSONAL
                INFORMATION STORED THEREIN, OR ANY INTERRUPTION OR CESSATION OF
                OPERATION OF THE SITE) ARISING OUT OF OR IN ANY WAY CONNECTED
                WITH THE USE OF THE SITE OR ANY INFORMATION CONTAINED ON THE
                SITE, WHETHER SUCH DAMAGES ARE BASED ON CONTRACT, TORT, STRICT
                LIABILITY OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES.
              </p>

              <p>
                You agree to indemnify, hold harmless and defend Techxr
                Innovations Private Limited from and against any and all claims,
                damages, losses, liabilities, judgments, awards, settlements,
                costs and expenses (including attorney's fees and court costs)
                arising out of or resulting from your use of this Website or the
                violation by you of any of these Terms of Use.
              </p>

              <p>
                YOU AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO
                THIS SITE OR YOUR USE OF THIS SITE MUST COMMENCE WITHIN ONE (1)
                YEAR AFTER THE CAUSE OF ACTION ACCRUES, AND WILL THEREAFTER BE
                PERMANENTLY BARRED.
              </p>

              <p>
                These Terms of Use constitute the entire agreement between you
                and Techxr Innovations Private Limited concerning your use of
                the Website and the contents of the Website. If any provision is
                deemed invalid by a court of competent jurisdiction, the
                remaining provisions shall remain in full force and effect. No
                waiver of any the Terms of Use shall be deemed a further or
                continuing waiver of such term or condition or any other term or
                condition, and any failure by Techxr Innovations Private Limited
                to assert any right or provision under these Terms of Use shall
                not constitute a waiver of such right or provision.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsPageComp;
