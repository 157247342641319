import { useState } from "react";
import LoginComponent from "../Header/LoginComponent";
import "../../../assets/css/main.css";
import ForgotPassword from "../Header/ForgotPassword";
import { toggleLoader } from "../../../apiServices/Services/CommonServices";
import { PageBackgroundLeft } from "../CommonComponents/PageBackgroundLeft";
import { PageBackgroundRight } from "../CommonComponents/PageBackgroundRight";
import { useAppSelector } from "../../../app/hooks";
import { isLoggedIn, lmsLink } from "../../../features/auth/AuthSlice";
import { Helmet, HelmetProvider } from "react-helmet-async";

const LoginPage = () => {
  const isLoggedInUser = useAppSelector(isLoggedIn);
  const lmsUrl = useAppSelector(lmsLink);
  const [isLoading, setLoader] = useState(false);

  const toggleisLoader = (status: boolean) => {
    if (status === true) {
      setLoader(status);
      //console.log(isLoader);
    } else {
      setLoader(status);
      // console.log(isLoader);
    }
  };

  const [value, setValue] = useState(0); // Working fine
  const ForceUpdate = () => {
    setValue((value) => value + 1);
  };

  const [showforgotpass, setShowforgotpass] = useState(false);
  const toggleshowforgotpass = () => {
    if (showforgotpass === false) {
      setShowforgotpass(true);
    } else {
      setShowforgotpass(false);
    }
  };

  const showForgotPass = () => {
    setShowforgotpass(true);
  };

  const redirectLoggedInUser = () => {
    console.log(lmsUrl);
    if (lmsUrl !== "https://learn.techxr.co/s/store") {
      window.location.href = lmsUrl;
    }
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>TechXR - Login Page</title>
          <meta
            name="title"
            content="Making Quality AR-VR Learning Easy - Affordable - Accessible"
          />
          <meta
            name="description"
            content="We are on a mission to democratize the access to emerging technologies, like Augmented, Virtual and Mixed Reality, where the main barrier to entry is the high cost of hardware and practically zero access for people with limited resources."
          />
          <meta
            property="image"
            content="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/uD41orAQL8hE2zva_2023-05-30T064448436786.Img web.png"
          />
        </Helmet>
      </HelmetProvider>
      {toggleLoader(isLoading)}
      <div className="main-section mx-w-1440">
        <div className="create-account py-100">
          <PageBackgroundLeft />
          {/* <img className="mainimage" src={require("./image/top-des.png")} alt="" /> */}
          {!isLoggedInUser ? (
            <>
              {showforgotpass ? (
                <>
                  <h2>Forgot Password</h2>
                  <ForgotPassword
                    hide={true}
                    reRender={ForceUpdate}
                    isLoader={toggleisLoader}
                    setShowforgotpass={toggleshowforgotpass}
                  />
                </>
              ) : (
                <>
                  <h2>Sign In</h2>
                  <LoginComponent
                    hide={true}
                    reRender={ForceUpdate}
                    isLoader={toggleisLoader}
                    setShowforgotpass={toggleshowforgotpass}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <h4 style={{ textAlign: "center" }}>
                Looks like you're already logged-in!!. Redirecting you to our
                Learning Portal!!
              </h4>
              {redirectLoggedInUser()}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default LoginPage;
