import { useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { AuthService } from "../../../apiServices/Services/AuthServices";
// css
import "../../../assets/css/required.css";
// import { ValidateForm } from "../../core/Validation/Validate";
import { Formik, ErrorMessage, Form, Field } from "formik";
import * as Yup from "yup";
import { ForgotPasswordRequest } from "../../../apiServices/Models/Auth/ForgotPasswordRequest";
import {
  sendGAEvent,
  toggleLoader,
} from "../../../apiServices/Services/CommonServices";

const objAuthService = new AuthService();
// const objValidateForm = new ValidateForm();

// interface ForgotPasswordProps {
//   email: string;
// }
const ForgotPassword = (props: any) => {
  const [isLoading, setLoader] = useState(false);

  // const { isLoader } = props;

  // const [userName, setUserName] = useState("");

  // const validateForm = () => {
  //   // return email.length > 0;
  //   if (!objValidateForm.validateUserName(userName)) {
  //     // toast.error("Invalid Username.");
  //     return false;
  //   }
  //   return true;
  // };

  const forgotPass = (values: ForgotPasswordRequest) => {
    sendGAEvent(
      "Forgot Password Started",
      "Forgot_Password_Started",
      "Auth Actions"
    );
    const userData = {
      email: values.email,
    };
    return objAuthService.forgotpassword(userData);
  };

  const handleSubmit = (values: ForgotPasswordRequest) => {
    // console.log("handle submit");
    setLoader(true);
    forgotPass(values).subscribe({
      next: (response) => {
        setLoader(false);
        // setUserName("");
      },
      error: (err) => {
        sendGAEvent(
          "Forgot Password Failed",
          "Forgot_Password_Failed",
          "Auth Actions"
        );
        setLoader(false);
        if (err.response.data) {
          toast.error(err.response.data);
        } else if (err.response.status === 500) {
          toast.error("Service unavailable.");
        } else {
          toast.error("Something went wrong, please try again!");
        }
      },
      complete: () => {
        sendGAEvent(
          "Forgot Password Successful",
          "Forgot_Password_Successful",
          "Auth Actions"
        );
        setLoader(false);
        toast.success("Password sent to your registered email & mobile number");
        closeModal();
        props.reRender();
        // setUserName("");
        if (props.hide) {
          props.setShowforgotpass();
        }
      },
    });
  };

  const toggleState = (event: any) => {
    event.preventDefault();
    props.toggle();
    props.toggleTitle("Sign In");
  };

  const toggleSignupState = (event: any) => {
    event.preventDefault();
    props.toggle("signup");
    props.toggleTitle("Sign Up");
  };

  const closeModal = () => {
    (document.querySelector('button[id="closeModal"]') as any).click();
  };

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .min(2, "Too Short!")
      .max(100, "Too Long!")
      .required("Required"),
  });
  return (
    <>
      {toggleLoader(isLoading)}
      <div className="login-account-middle">
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={ForgotPasswordSchema}
          onSubmit={(values: ForgotPasswordRequest) => {
            handleSubmit(values);
            // same shape as initial values
            console.log(values);
          }}
        >
          {({ errors, touched }) => (
            <Form className="login-form">
              <div className="form-block">
                <label htmlFor="email" className="after-required">
                  Email Address / Mobile
                </label>
                <Field
                  name="email"
                  type="text"
                  placeholder="email@example.com or Mobile number (10 Digits - XXXXXXXXXX)"
                  className={errors.email && touched.email ? "error-field" : ""}
                />
                <ErrorMessage
                  component="div"
                  className="errormsglabel"
                  name="email"
                />
              </div>
              <div className="create-btn">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
              {props.hide ? (
                <>
                  <div className="dropdown-divider"></div>
                  <div className="forget-pass">
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={props.setShowforgotpass}
                    >
                      Already an User?{" "}
                      <span style={{ color: "#2CABDE" }}>Sign-in</span>
                    </button>
                    <Link type="button" className="dropdown-item" to="/signup">
                      New around here?{" "}
                      <span style={{ color: "#2CABDE" }}>Sign-up</span>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <div className="dropdown-divider"></div>

                  <div className="forget-pass">
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={toggleState}
                    >
                      Already an User?{" "}
                      <span style={{ color: "#2CABDE" }}>Sign-in</span>
                    </button>
                    <button
                      type="button"
                      className="dropdown-item"
                      onClick={toggleSignupState}
                    >
                      New around here?{" "}
                      <span style={{ color: "#2CABDE" }}>Sign-up</span>
                    </button>
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ForgotPassword;
