import Desc0 from "./0-Desc.json";
import Desc1 from "./1-Desc.json";
import Desc2 from "./2-Desc.json";
import Desc3 from "./3-Desc.json";
import Desc4 from "./4-Desc.json";
import Desc5 from "./5-Desc.json";
import Desc6 from "./6-Desc.json";
import Desc7 from "./7-Desc.json";
import Desc8 from "./8-Desc.json";
import Desc9 from "./9-Desc.json";


let allCoursesDescJson = [Desc0, Desc1, Desc2, Desc3, Desc4, Desc5, Desc6, Desc7, Desc8, Desc9];

export default allCoursesDescJson;
