import * as React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { EventPageAboveFold } from "../../../apiServices/Models/WebsiteContent/EventPageModels/EventPageAboveFoldResponse";
import { EventDetailsResponse } from "../../../apiServices/Models/WebsiteContent/EventPageModels/EventPageMainResponse";
import { ContentServices } from "../../../apiServices/Services/ContentServices";
import * as EventComps from "../EventPage";
import { EventsMainComp } from "../HomePage";
import { isEventInFuture } from "./Helpers/EventPageHelper";

const contentService = new ContentServices();

interface EventsPageMainCompProps {}

export const EventsPageMainComp: React.FunctionComponent<
  EventsPageMainCompProps
> = (props) => {
  const { id } = useParams();
  // console.log(id);
  const [eventStructureDetail, setEventStructureDetail] =
    React.useState<EventDetailsResponse>();

  React.useEffect(() => {
    const getEventDetail = (): EventDetailsResponse => {
      return contentService.getEventDetail(id);
    };
    setEventStructureDetail(getEventDetail());
  }, [id, setEventStructureDetail]);

  // console.log(eventStructureDetail);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{"TechXR - " + eventStructureDetail?.name}</title>
          <meta name="title" content={eventStructureDetail?.name} />
          <meta
            name="description"
            content={eventStructureDetail?.shortDescription}
          />
          <meta
            property="image"
            content={eventStructureDetail?.media[0].imgUrl}
          />
        </Helmet>
      </HelmetProvider>
      {eventStructureDetail && eventStructureDetail.id !== "" ? (
        <div className="Event_details_main mx-w-1440">
          <EventComps.EventsAboveFoldComp
            eventAboveFoldDetails={eventStructureDetail.aboveFold}
            isEventInFuture={isEventInFuture(
              eventStructureDetail?.date ? eventStructureDetail?.date : ""
            )}
          />
          <EventComps.AboutEventComp
            eventAboutDetails={eventStructureDetail.aboutEvent}
          />
          <div className="media_gallery py-80 px-20">
            <div className="common-row ">
              <div className="common-container">
                <h2>Media Gallery</h2>
              </div>
              <EventComps.EventsGalleryMainComp
                eventMediaDetails={eventStructureDetail.media}
              />
            </div>
          </div>
          <EventsMainComp id={id} />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};
