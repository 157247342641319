import * as React from "react";
import imageLeft from "../../../assets/images/create-account/account-back.png";

interface PageBackgroundLeftProps {}

export const PageBackgroundLeft: React.FunctionComponent<
  PageBackgroundLeftProps
> = (props) => {
  return (
    // <!--Create Account Start-->
    <div className="create-account-left">
      <img src={imageLeft} alt="BackgroundImage" />
    </div>
  );
};
