import * as React from "react";
import imageRight from "../../../assets/images/create-account/account-back.png";

interface PageBackgroundRightProps {}

export const PageBackgroundRight: React.FunctionComponent<
  PageBackgroundRightProps
> = (props) => {
  return (
    <div className="create-account-right">
      <img src={imageRight} alt="BackgroundImage" />
    </div>
  );
};
