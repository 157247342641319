export class OurAppsOtherResponse extends Array<OurAppsOtherResponse> {
  constructor() {
    super();
    this.id = 0;
    this.appName = "";
    this.appDescription = "";
    this.appUrl = "";
    this.imageUrl = "";
    this.appgif = "";
  }
  public id: number;
  public appName: string;
  public appDescription: string;
  public appUrl: string;
  public imageUrl: string;
  public appgif: string;
}
// export class AppsInclusion extends Array<AppsInclusion> {
//   constructor() {
//     super();
//     this.vrHeadset = true;
//     this.arHeadset = true;
//     this.Experience = true;
//     this.gaming = true;
//     this.glass = true;
//   }
//   public vrHeadset: boolean;
//   public arHeadset: boolean;
//   public Experience: boolean;
//   public gaming: boolean;
//   public glass: boolean;
// }
