import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

import { Grid, Pagination, Autoplay, Navigation } from "swiper";

import { ContentServices } from "../../../../apiServices/Services/ContentServices";
import { HomePageNewsResponse } from "../../../../apiServices/Models/WebsiteContent/HomePageModels/HomePageNewsResponse";

const contentService = new ContentServices();
interface INewsAndInfoProps {}

export const NewsAndInfo: React.FunctionComponent<INewsAndInfoProps> = (
  props
) => {
  const [newsList, setnewsList] = React.useState<HomePageNewsResponse[]>([]);
  const getProjectsData = (): HomePageNewsResponse[] => {
    return contentService.getAllNews();
  };
  React.useEffect(() => {
    setnewsList(getProjectsData());
  }, [setnewsList]);

  return (
    <>
      <div className="newsSection-main pt-50">
        <div className="common-container">
          <div className="newsAndInfo">
            <div className="common-heading-home ">
              <h2 className="text-center">
                <span>News &</span>
                Media
              </h2>
            </div>
            <div className="main_news_content mt-70">
              <div className="news-list common-row text-center">
                <Swiper
                  slidesPerView={3}
                  grid={{
                    rows: 2,
                  }}
                  autoplay={{
                    delay: 8000,
                    disableOnInteraction: true,
                  }}
                  navigation={true}
                  spaceBetween={30}
                  pagination={{
                    clickable: true,
                  }}
                  // loop={true}
                  modules={[Grid, Pagination, Autoplay, Navigation]}
                  className="mySwiper"
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {newsList?.map((news, key1) => {
                    let randomNumber: number = Math.floor(
                      Math.random() * 11111
                    );
                    // console.log("news and info key1 = " + randomNumber);
                    return (
                      <>
                        <SwiperSlide key={randomNumber}>
                          <div className="achievment Info_news">
                            <a
                              href={news.newsUrl}
                              target="blank"
                              aria-label={news.newstitle}
                            >
                              <img src={news.newsthumb} alt="EduPrenuer-Logo" />
                            </a>
                          </div>
                        </SwiperSlide>
                      </>
                    );
                  })}
                </Swiper>
              </div>
            </div>

            {/* <!--------Our Achievments Partners End --------> */}
          </div>
        </div>
      </div>
    </>
  );
};
