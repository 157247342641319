import * as React from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ProductService } from "../../../../../apiServices/Services/ProductServices";
import { ProductSummary } from "../../../../../apiServices/Models/Products/ProductSummaryModel";
import toast from "react-hot-toast";
import { toggleLoader } from "../../../../../apiServices/Services/CommonServices";
import { SaveUpdateProduct } from "../../../../../apiServices/Models/Products/SaveUpdateProduct";
import { useAppSelector } from "../../../../../app/hooks";
import { isLoggedIn, selectUser } from "../../../../../features/auth/AuthSlice";
import { DeveloperIdResponse } from "../../../../../apiServices/Models/DeveloperProfile/DeveloperIdResponse";
import { ProductMedia } from "../../../../../apiServices/Models/Products/ProductMediaModel";
import { ProductRelease } from "../../../../../apiServices/Models/Products/ProductReleaseModel";
import { PlayControlsResponse } from "../../../../../apiServices/Models/Products/PlayControlsResponseModel";
import { PlayControls } from "../../../../../apiServices/Models/Products/PlayControlsRequestModel";
// import { Lms } from "../../../../../apiServices/Models/Auth/LmsRequest";

const objProductService = new ProductService();

interface UploadProductDetailsCompProps {
  productId?: string | undefined;
  callBackWithProductSummaryDto?: (productSummary: ProductSummary) => void;
  callBackWithMediaDto?: (mediaList: Array<ProductMedia>) => void;
  callBackWithReleasesDto?: (releasesList: Array<ProductRelease>) => void;
  callBackWithControlsDto?: (controlsList: PlayControlsResponse) => void;
  callBackWithUserAuthorizationFlag?: (isAuthorized: boolean) => void;
}

const UploadProductDetailsComp: React.FunctionComponent<
  UploadProductDetailsCompProps
> = (props) => {
  const [productId] = React.useState(props.productId);
  const [isLoading, setLoader] = React.useState(true);
  const [productDetail, setProduct] = React.useState<ProductSummary>();

  const [isEditModeOn, setEditModeOn] = React.useState(false);
  const isUserLoggedIn = useAppSelector(isLoggedIn);

  const user = useAppSelector(selectUser);
  const [profileId] = React.useState(user ? user.profile?.id : undefined);
  const [developerId, setDeveloperId] = React.useState(0);
  const [isAuthorized, setUserAuthorization] = React.useState(false);
  const [isArVrFlag, setArVrFlag] = React.useState(true);

  const initialValues: SaveUpdateProduct = {
    id: "",
    name: "",
    developerName: "",
    description: "",
    arvrBoolean: "true",
    productTypeList: ["AR"],
    gitHubLink: "",
    developerId: 0,
  };

  const [newValues, updateInitialValues] = React.useState(initialValues);

  const getDeveloperId = (profileId: number) => {
    if (profileId !== undefined) {
      setLoader(true);
      const developerId = objProductService.getDeveloperId(profileId);

      developerId.subscribe({
        next: (response: DeveloperIdResponse) => {
          setDeveloperId(response.id);
          newValues.developerId = response.id;
        },
        error: (err) => {
          if (err.response.data) {
            toast.error(err.response.data);
          } else if (err.response.status === 500) {
            toast.error("Service unavailable.");
          } else {
            toast.error("Something went wrong, please try again!");
          }
        },
        complete: () => {
          setLoader(false);
        },
      });
    }
  };

  const getProductDetailsForEdit = (productId: string) => {
    if (productId !== "" && isUserLoggedIn) {
      setLoader(true);
      setEditModeOn(true);
      const productDetails = objProductService.productById(productId);

      productDetails.subscribe({
        next: (response: ProductSummary) => {
          if (
            profileId &&
            response.profile.id === profileId &&
            props.callBackWithUserAuthorizationFlag
          ) {
            getDeveloperId(profileId);
            setUserAuthorization(true);
            if (props.callBackWithProductSummaryDto)
              props.callBackWithProductSummaryDto(response);
            props.callBackWithUserAuthorizationFlag(true);
          } else if (props.callBackWithUserAuthorizationFlag) {
            setUserAuthorization(false);
            props.callBackWithUserAuthorizationFlag(false);
            toast.error("Unauthorized access detected.");
          }
          if (response.productMediaDto && props.callBackWithMediaDto) {
            props.callBackWithMediaDto(response.productMediaDto);
          }
          if (response.releases && props.callBackWithReleasesDto) {
            props.callBackWithReleasesDto(response.releases);
            response.releases.map((release) => {
              if (release.appControl) {
                if (props.callBackWithControlsDto) {
                  props.callBackWithControlsDto(release.appControl);
                }
              } else if (props.callBackWithControlsDto) {
                const emptyPlayResponseObj = new PlayControlsResponse();
                emptyPlayResponseObj.controls.push(new PlayControls());
                props.callBackWithControlsDto(emptyPlayResponseObj);
              }
            });
          }
          updateInitialValues({
            id: response.id,
            name: response.name ? response.name : "",
            developerName: response.developerName ? response.developerName : "",
            description: response.description ? response.description : "",
            arvrBoolean: checkArVrBoolean(response.productTypeList),
            productTypeList: projectTypeList(response.productTypeList),
            gitHubLink: response.gitHubLink,
            developerId: developerId,
          });
          if (response.productTypeList && response.productTypeList.length > 0) {
            setArVrFlag(true);
          } else {
            setArVrFlag(false);
          }
        },
        error: (err) => {
          if (err.response.data) {
            toast.error(err.response.data);
          } else if (err.response.status === 500) {
            toast.error("Service unavailable.");
          } else {
            toast.error("Something went wrong, please try again!");
          }
        },
        complete: () => {
          setLoader(false);
        },
      });
    }
  };

  const checkArVrBoolean = (productTypeList: Array<string>): string => {
    if (productTypeList && productTypeList.length > 0) {
      return "true";
    }
    return "false";
  };

  const projectTypeList = (productTypeList: Array<string>) => {
    if (productTypeList && productTypeList.length > 0) {
      return productTypeList;
    }
    return [];
  };

  React.useEffect(() => {
    if (productId !== undefined && isUserLoggedIn) {
      getProductDetailsForEdit(productId ? productId : "");
    } else if (profileId !== undefined && isUserLoggedIn) {
      getDeveloperId(profileId);
      setLoader(false);
    } else {
      window.location.href = "/login";
    }
  }, [productId, profileId]);

  React.useEffect(() => {
    if (productDetail && productDetail.id !== "") {
      window.location.href = "/project/edit/" + productDetail?.id;
    }
  }, [productDetail]);

  const saveProductDetails = (values: SaveUpdateProduct) => {
    const productData: SaveUpdateProduct = {
      name: values.name,
      developerName: values.developerName,
      developerId: values.developerId,
      description: values.description,
      productTypeList: isArVrFlag ? values.productTypeList : [],
      gitHubLink: values.gitHubLink,
    };

    if (productId !== undefined) {
      productData.id = values.id;
    }

    return objProductService.productSaveUpdate(productData);
  };

  const handleSaveProduct = (values: SaveUpdateProduct) => {
    setLoader(true);
    saveProductDetails(values).subscribe({
      next: (response: ProductSummary) => {
        setProduct(response);
        setLoader(false);
      },
      error: (err) => {
        setLoader(false);

        if (err.response.data) {
          toast.error(err.response.data);
        } else if (err.response.status === 500) {
          toast.error("Service unavailable.");
        } else {
          toast.error("Something went wrong, please try again!");
        }
      },
      complete: () => {
        toast.success("Project has been saved successfully!");
        setLoader(false);
      },
    });
  };

  const toggleFormEdit = () => {
    setEditModeOn(false);
  };

  const handleCancel = (resetForm: () => void) => {
    resetForm();
    if (productId) {
      setEditModeOn(true);
    } else {
      window.location.href = "/myprojects";
    }
  };

  const projectDetailsSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, "Too Short!")
      .max(100, "Too Long!")
      .matches(/^[A-Za-z\s][A-Za-z\s]*$/, "Invalid Project Name")
      .required("Required"),
    developerName: Yup.string()
      .min(1, "Too Short!")
      .max(100, "Too Long!")
      .required("Required"),
    description: Yup.string()
      .min(1, "Too Short!")
      .max(500, "Too Long!")
      .required("Required"),
    arvrBoolean: Yup.boolean().required(),
    productTypeList: Yup.array()
      .min(0)
      .of(Yup.string().required())
      .required()
      .when("arvrBoolean", {
        is: (arvrBoolean: boolean) => arvrBoolean === true,
        then: Yup.array()
          .min(1, "Select atleast 1 option")
          .of(Yup.string().required())
          .required("Select atleast 1 option"),
      }),
    gitHubLink: Yup.string().url().min(1, "Too Short!").max(500, "Too Long!"),
  });

  return (
    <>
      {toggleLoader(isLoading)}
      <div className="submission-section">
        <div className="submision-sub-headig">
          <h2>
            Project<span>Details</span>
          </h2>
          {isEditModeOn && isAuthorized && (
            <button className="edit-btn" onClick={toggleFormEdit}>
              Edit
            </button>
          )}
        </div>
        {!isLoading && (
          <Formik
            initialValues={newValues}
            validationSchema={projectDetailsSchema}
            onSubmit={(values: SaveUpdateProduct) => {
              handleSaveProduct(values);
            }}
          >
            {({
              errors,
              touched,
              values,
              setFieldTouched,
              handleChange,
              setValues,
              setFieldValue,
              resetForm,
            }) => (
              <Form>
                <div className="common-row">
                  <div className="submission-input common-col-6 common-md-6 common-xs-12">
                    <div className="input-inner">
                      <label>Project Name</label>
                      <Field
                        name="name"
                        type="text"
                        placeholder="Project Name"
                        required=""
                        className={
                          errors.name && touched.name ? "error-field" : ""
                        }
                        disabled={isEditModeOn}
                      />
                      <ErrorMessage
                        component="div"
                        className="errormsglabel"
                        name="name"
                      />
                    </div>
                  </div>
                  <div className="submission-input common-col-6 common-md-6 common-xs-12">
                    <div className="input-inner">
                      <label>Developer Name</label>
                      <Field
                        name="developerName"
                        type="text"
                        placeholder="Developer Name"
                        required=""
                        className={
                          errors.name && touched.name ? "error-field" : ""
                        }
                        disabled={isEditModeOn}
                      />
                      <ErrorMessage
                        component="div"
                        className="errormsglabel"
                        name="name"
                      />
                    </div>
                  </div>
                  <div className="input-inner common-col-12 common-md-12 common-xs-12">
                    <label>Description</label>
                    <Field
                      name="description"
                      component="textarea"
                      rows="2"
                      placeholder="Short Description of your project."
                      required=""
                      className={
                        errors.description && touched.description
                          ? "error-field"
                          : ""
                      }
                      disabled={isEditModeOn}
                    />
                    <ErrorMessage
                      component="div"
                      className="errormsglabel"
                      name="description"
                    />
                  </div>

                  <div className="input-inner common-col-6 common-xs-12">
                    <label>Is the project AR/VR</label>
                    <Field
                      name="arvrBoolean"
                      as="select"
                      className={
                        errors.arvrBoolean && touched.arvrBoolean
                          ? "error-field"
                          : ""
                      }
                      onChange={(e: any) => {
                        setFieldTouched("arvrBoolean");
                        handleChange(e);
                        values.arvrBoolean = e.target.value;
                        e.target.value === "true"
                          ? setArVrFlag(true)
                          : setArVrFlag(false);
                      }}
                      disabled={isEditModeOn}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </Field>
                    <ErrorMessage
                      component="div"
                      className="errormsglabel"
                      name="arvrBoolean"
                    />
                  </div>
                  {isArVrFlag && (
                    <>
                      <div
                        id="checkbox-group"
                        className="input-inner common-col-6 common-xs-12"
                      >
                        <label>Type</label>

                        <div
                          role="group"
                          aria-labelledby="checkbox-group"
                          className="custum-box"
                        >
                          <label className="container">
                            <span>AR</span>
                            <Field
                              type="checkbox"
                              name="productTypeList"
                              value="AR"
                              disabled={isEditModeOn}
                            />
                            <span className="checkmark"></span>
                          </label>

                          <label className="container">
                            <span>VR</span>
                            <Field
                              type="checkbox"
                              name="productTypeList"
                              value="VR"
                              disabled={isEditModeOn}
                            />
                            <span className="checkmark"></span>
                          </label>
                          <ErrorMessage
                            component="div"
                            className="errormsglabel"
                            name="productTypeList"
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="input-inner common-col-12 common-md-12 common-xs-12">
                    <label className="not-reqired">Github Link</label>
                    <Field
                      name="gitHubLink"
                      type="text"
                      placeholder="GitHub repo URL"
                      // required=""
                      className={
                        errors.gitHubLink && touched.gitHubLink
                          ? "error-field"
                          : ""
                      }
                      disabled={isEditModeOn}
                    />
                    <ErrorMessage
                      component="div"
                      className="errormsglabel"
                      name="githubLink"
                    ></ErrorMessage>
                  </div>
                </div>

                {isEditModeOn === false ? (
                  <>
                    <div className="submission-btn">
                      <button
                        className="submit"
                        type="submit"
                        disabled={isEditModeOn}
                      >
                        Save
                      </button>
                      <button
                        className="cancel"
                        disabled={isEditModeOn}
                        type="button"
                        onClick={handleCancel.bind(null, resetForm)}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default UploadProductDetailsComp;
