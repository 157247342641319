import * as React from "react";
import UploadProductDetailsComp from "./ProductDetailsComponent/UploadProductDetailsComp";
import BgImage from "../../../../assets/images/auth-pages-background/bg_png.png";
import BgImage2 from "../../../../assets/images/auth-pages-background/bg_png2.png";
import { Helmet, HelmetProvider } from "react-helmet-async";

interface UploadProductMainCompProps {}

const UploadProductMainComp: React.FunctionComponent<
  UploadProductMainCompProps
> = (props) => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>TechXR - Add New Project</title>
          <meta
            name="title"
            content="Making Quality AR-VR Learning Easy - Affordable - Accessible"
          />
          <meta
            name="description"
            content="We are on a mission to democratize the access to emerging technologies, like Augmented, Virtual and Mixed Reality, where the main barrier to entry is the high cost of hardware and practically zero access for people with limited resources."
          />
          <meta
            property="image"
            content="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/uD41orAQL8hE2zva_2023-05-30T064448436786.Img web.png"
          />
        </Helmet>
      </HelmetProvider>
      <div className="project-submission-main mx-w-1440">
        <div className="common-container">
          <img src={BgImage} alt="" className="bg-overlay" />
          <img src={BgImage2} alt="" className="bg-overlay second" />

          <div className="project-submission-inner">
            <div className="privacy-heading">
              <h1>Project Submission</h1>
            </div>
            <UploadProductDetailsComp />
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadProductMainComp;
