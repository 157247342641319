import * as React from "react";
import BookCallFormMainComp from "./BookCallFormMainComp";
const myVar2 = require("../../../../assets/videos/finalvideo.mp4");

interface AboveFoldMainCompProps {}

export const AboveFoldMainComp: React.FunctionComponent<
  AboveFoldMainCompProps
> = (props) => {
  // const navigate = useNavigate();
  // const closeModal = () => {
  //   (document.querySelector('button[id="closeModal"]') as any).click();
  // };

  const [muted, setMuted] = React.useState(true);
  const handleToggleMute = () => setMuted((current) => !current);
  return (
    <>
      <div className="banner ">
        <div className="controller__video p-relative">
          <img
            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/vBIrhlEOA5pigkyq_2022-12-16T054859835095.Thumbnail-Main-Slider.jpg"
            alt=""
            className="slidertopThumb"
          />
          <video autoPlay loop id="myVideo" muted={muted}>
            <source src={myVar2} />
          </video>
          <div className="common-container">
            <button onClick={handleToggleMute} className="controlButton">
              <img
                src={
                  muted
                    ? "https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/LrrCUNDJQlcnR6nB_2023-02-06T124848044546.Mute icon1.png"
                    : "https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/1wdESz3mLAvtnNC3_2023-02-06T124818065124.Unmute icon1.png"
                }
                alt=""
              />
            </button>
          </div>
        </div>
        <BookCallFormMainComp
          name={""}
          email={""}
          mobile={""}
          source={"Website Registration"}
        />
        <div className="border-box">
          <div className="banner-box-top">
            <div className="banner-box-top-text">
              <div className="h1_head">
                Augmented &{" "}
                <h1>Virtual Reality Certification Course in India</h1>
                <br /> in Association with iHub DivyaSampark @IIT Roorkee​
              </div>
            </div>
          </div>
          <div className="banner-box-border"></div>
        </div>
      </div>
    </>
  );
};
