import { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { logout } from "../../../features/auth/AuthSlice";
import { LocalStorage } from "../../core/Storage/LocalStorage";
import { HandleAuthToken } from "../../core/Utils/HandleAuthToken";

const objLocalStorage = new LocalStorage();
const objAuthHandler = new HandleAuthToken();

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  function refreshPage() {
    window.location.reload();
  }

  useEffect(() => {
    navigate("/");
    objAuthHandler.deleteUserSession("userData");
    objLocalStorage.removeItem("userData");
    objLocalStorage.removeItem("lmsData");
    objLocalStorage.removeItem("leadData");
    objLocalStorage.removeItem("registeredUserData");
    dispatch(logout());
    toast.success("User logged out successfully!!");
    setTimeout(() => {
      refreshPage();
    }, 2000);
  });
  return <></>;
};

export default Logout;
