import * as React from "react";
import toast from "react-hot-toast";
import { ProductSummary } from "../../../../apiServices/Models/Products/ProductSummaryModel";
import { ProductService } from "../../../../apiServices/Services/ProductServices";
import { toggleLoader } from "../../../../apiServices/Services/CommonServices";
import SingleCardCompo from "../AllProductsPage/SingleCardCompo";
import { useAppSelector } from "../../../../app/hooks";
import { isLoggedIn, selectUser } from "../../../../features/auth/AuthSlice";
import { DeveloperIdResponse } from "../../../../apiServices/Models/DeveloperProfile/DeveloperIdResponse";

const objProductService = new ProductService();

interface AllMyProductsCardCompProps {}

const AllMyProductsCardComp: React.FunctionComponent<
  AllMyProductsCardCompProps
> = (props) => {
  // const isUserLoggedIn = useAppSelector(isLoggedIn);

  const user = useAppSelector(selectUser);

  // first log
  // console.log("User = " + user.profile.firstName);

  // const developerId = 1;

  const [productList, setProducts] = React.useState<ProductSummary[]>([]);
  const [isLoading, setLoader] = React.useState(false);

  let allProducts = Array<ProductSummary>();

  const getDeveloperForProfile = (profileId: number) => {
    setLoader(true);
    objProductService.getDeveloperId(profileId).subscribe({
      next: (developer: DeveloperIdResponse) => {
        getProductsForDeveloper(developer.id);
      },
      error: (err) => {
        if (err.response.data) {
          toast.error(err.response.data);
        } else if (err.response.status === 500) {
          toast.error("Service unavailable.");
        } else {
          toast.error("Something went wrong, please try again!");
        }
      },
      complete: () => {
        setLoader(true);
      },
    });
  };

  const getProductsForDeveloper = (developerId: number) => {
    setLoader(true);
    const res = objProductService.getProductsForDeveloper(developerId);

    res.subscribe({
      next: (response: ProductSummary[]) => {
        response.map((item: ProductSummary) => {
          return allProducts.push(item);
        });
        setProducts(allProducts);
      },
      error: (err) => {
        if (err.response.data) {
          toast.error(err.response.data);
        } else if (err.response.status === 500) {
          toast.error("Service unavailable.");
        } else {
          toast.error("Something went wrong, please try again!");
        }
      },
      complete: () => {
        setLoader(false);
      },
    });
  };

  React.useEffect(() => {
    if (user.profile?.id !== undefined) {
      const id: number = user.profile?.id;
      getDeveloperForProfile(id);
    }
  }, []);

  return (
    <>
      {toggleLoader(isLoading)}
      {productList.map((product, key) => {
        return <SingleCardCompo key={key} product={product} />;
      })}
    </>
  );
};

export default AllMyProductsCardComp;
