import { Profile } from "./ProfileModel";

export class LoginResponse {
  constructor() {
    this.access_token = "";
    this.profile = new Profile();
  }

  public access_token: string;
  public profile: Profile;
  public profileId: number | undefined;

  public clearUser() {
    this.access_token = "";
  }

  public getToken() {
    return this.access_token;
  }

  public getProfileObject() {
    return this.profile;
  }
}
