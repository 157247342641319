import { Type } from "class-transformer";
import { Document } from "./DocumentModel";

export class ProductPackage {
  constructor() {
    this.id = "";
    this.productReleaseId = "";
    this.unityPlatform = "";
  }
  public id: string;
  public productReleaseId: string;
  public unityPlatform: string;
  @Type(() => Document)
  public document: Document = new Document();
}
