import * as React from "react";
import { OurAchievementsComp } from "./OurAchievementsComp";
import { OurPartnersComp } from "./OurPartnersComp";

interface OurPartnersAndAchievementsMainCompProps {}

export const OurPartnersAndAchievementsMainComp: React.FunctionComponent<
  OurPartnersAndAchievementsMainCompProps
> = (props) => {
  return (
    <div className="compnent-6 py-100">
     <div className="common-container">
     <OurPartnersComp></OurPartnersComp>
      <OurAchievementsComp></OurAchievementsComp>
     </div>
    </div>
  );
};
