import * as React from "react";
import VideoIcon from "../../../../assets/images/student-section/video.png";
import Icon24x7 from "../../../../assets/images/student-section/247-avail.png";
import DBTSupportIcon from "../../../../assets/images/student-section/dbt-support.png";
import { CourseDescriptionResponse } from "../../../../apiServices/Models/WebsiteContent/CoursePageModels/CourseDescriptionResponse";
import { ContentServices } from "../../../../apiServices/Services/ContentServices";
import { Helmet, HelmetProvider } from "react-helmet-async";

const contentService = new ContentServices();

interface CourseDetailsCompProps {
  courseId: string | undefined;
}

export const CourseDetailsComp: React.FunctionComponent<
  CourseDetailsCompProps
> = (props) => {
  const courseId = props.courseId;
  const [courseDesc, setCourseDesc] =
    React.useState<CourseDescriptionResponse>();

  React.useEffect(() => {
    const getCourseDescDetail = (): CourseDescriptionResponse => {
      return contentService.getCourseDescDetail(courseId);
    };
    setCourseDesc(getCourseDescDetail());
  }, [courseId, setCourseDesc]);

  // console.log(courseDesc);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <link rel="icon" href="./favicon.ico" />
        </Helmet>
      </HelmetProvider>
      {courseDesc?.mainDesc ? (
        <div dangerouslySetInnerHTML={{ __html: courseDesc.mainDesc }}></div>
      ) : (
        <div className="clear"></div>
      )}
      {/* 
      <h2 className="course-content-heading">Why This Course</h2>

      {courseDesc?.whyThisCourseDesc ? (
        <div
          dangerouslySetInnerHTML={{ __html: courseDesc.whyThisCourseDesc }}
        ></div>
      ) : (
        <div className="clear"></div>
      )} */}

      <h2 className="course-content-heading mt-30">Our Teaching Methodology</h2>

      <div className="teaching-methodology-icon">
        {courseDesc?.onlineClass ? (
          <div className="tech_method_block">
            <img src={VideoIcon} alt="VideoIcon" />
            <p>
              Live Online <br />
              Class
            </p>
          </div>
        ) : (
          <div className="clear"></div>
        )}
        {courseDesc?.videosAvailable ? (
          <div className="tech_method_block">
            <img src={Icon24x7} alt="Icon24x7" />
            <p>
              24x7 <br />
              Videos Available
            </p>
          </div>
        ) : (
          <div className="clear"></div>
        )}
        {courseDesc?.doubtSupport ? (
          <div className="tech_method_block">
            <img src={DBTSupportIcon} alt="DBTSupportIcon" />
            <p>
              Doubt Clearing <br />
              Support
            </p>
          </div>
        ) : (
          <div className="clear"></div>
        )}
      </div>
      <div className="clear"></div>
    </>
  );
};
