import * as React from "react";

export interface ITableCardCompoProps {}

export default function TableCardCompo(props: ITableCardCompoProps) {
  const [num, setnum] = React.useState(1);
  const unitPrice: number = 2999;
  const [price, setprice] = React.useState(unitPrice);
  const gstRate = 0.18;

  let newGst = unitPrice * gstRate;
  const [gst, setgst] = React.useState(newGst);

  const insnum = () => {
    let newCount = num + 1;
    setnum(newCount);
    setprice(newCount * unitPrice);
    setgst(newCount * unitPrice * gstRate);
    console.log(`this is gst = ${gst}`);
  };

  const decsnum = () => {
    let newCount = num - 1;
    if (num > 1) {
      setnum(newCount);
      setprice(newCount * unitPrice);
      setgst(newCount * unitPrice * gstRate);
    } else {
      setnum(1);
      setprice(unitPrice);
      alert("Zero limit Not Reached");
    }
  };
  const totalPrice = Math.floor(gst + price);
  return (
    <>
      <div className="common-col-4 common-md-12">
        <div className="submission-section">
          <div className="submision-sub-headig">
            <h2>
              Your <span>order</span>
            </h2>
          </div>

          <div className="shipping_table">
            <table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Subtotal</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="myproduct">
                      <img
                        src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/324i7dILNFoV3Ghn_2023-02-01T072058534424.controller2.1709bea11fb0a9825029.png"
                        alt=""
                      />
                      <div className="p-name">
                        Monster VR | Virtual Reality Headset with Built in
                        Controller
                      </div>
                      <div className="incr_decr_blog">
                        <button onClick={decsnum}>-</button>
                        <span> {num} </span>
                        <button onClick={insnum}>+</button>
                      </div>
                    </div>
                  </td>
                  <td>₹ {price}</td>
                </tr>
                <tr>
                  <td>Subtotal</td>
                  <td>₹ {price}</td>
                </tr>

                <tr>
                  <td>Shipping</td>
                  <td>Free shipping</td>
                </tr>

                <tr>
                  <td> GST</td>
                  <td>₹ {Math.round(gst)}</td>
                </tr>

                <tr>
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td>
                    <strong>₹ {totalPrice}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="coupon_code">
            <input type="text" placeholder="Coupon Code" />
            <button>Apply</button>
          </div>
        </div>
      </div>
    </>
  );
}
