import * as React from "react";
import { CourseStructureDetailsComp } from "./CourseStructureDetailsComp";
import { Helmet, HelmetProvider } from "react-helmet-async";

interface CourseStructureMainCompProps {
  courseId: string | undefined;
}

export const CourseStructureMainComp: React.FunctionComponent<
  CourseStructureMainCompProps
> = (props) => {
  const courseId = props.courseId;
  return (
    <>
      <h2 className="course-structure-heading">Course Structure</h2>
      <CourseStructureDetailsComp
        courseId={courseId}
      ></CourseStructureDetailsComp>
    </>
  );
};
