export class HomePageCoursesResponse extends Array<HomePageCoursesResponse> {
  constructor() {
    super();
    this.id = "0";
    this.name = "";
    this.description = "";
    this.duration = "";
    this.partnerName = "";
    this.courseLevel = "";
    this.gradeLevel = "";
    // this.courseUrl = "";
    // this.inclusions = {
    //   vrHeadset: false,
    //   coding: false,
    //   controller: false
    // };
    this.imageUrl = "";
    this.coursePrice = "";
    this.inclusions = new CourseInclusion();
  }
  public id: string;
  public name: string;
  public description: string;
  public duration: string;
  public partnerName: string;
  public courseLevel: string;
  public gradeLevel: string;
  // public courseUrl: string;
  public inclusions: CourseInclusion;
  public imageUrl: string;
  public coursePrice: string;
}

export class CourseInclusion extends  Array<CourseInclusion> {
  constructor() {
    super();
    this.vrHeadset = true;
    this.coding = true;
    this.controller = true;
    this.devcube = true;
  }
  public vrHeadset: boolean;
  public coding: boolean;
  public controller: boolean;
  public devcube: boolean;
}
