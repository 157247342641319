import * as React from "react";
import { EventPageAboveFold } from "../../../../apiServices/Models/WebsiteContent/EventPageModels/EventPageAboveFoldResponse";
import PastEventComp from "./PastEvent/PastEventComp";
import UpcomingEventComp from "./UpcomingEvent/UpcomingEventComp";
// import EventBanner from "../../../../assets/images/our-events-img/eventbanner.jfif";

interface EventsAboveFoldCompProps {
  eventAboveFoldDetails: EventPageAboveFold;
  isEventInFuture: boolean;
}

export const EventsAboveFoldComp: React.FunctionComponent<
  EventsAboveFoldCompProps
> = (props) => {
  const eventAboveFoldDetails = props.eventAboveFoldDetails;
  const isEventInFuture = props.isEventInFuture;
  // console.log(isEventInFuture);
  return (
    <>
      {isEventInFuture ? (
        <UpcomingEventComp eventAboveFoldDetails={eventAboveFoldDetails} />
      ) : (
        <PastEventComp eventAboveFoldDetails={eventAboveFoldDetails} />
      )}
    </>
  );
};
