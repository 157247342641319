export class Phone {
  constructor(){
    this.countryCode = ""
    this.phoneNumber = ""
    this.callStatus = undefined
    this.whatsappStatus = undefined
  }
  countryCode?: string;
  phoneNumber?: string;
  callStatus: string | undefined;
  whatsappStatus: string | undefined;
}
