export class XrBlocksModels extends Array<XrBlocksModels> {
    constructor() {
      super();
      this.id = "0";
      this.versionName = "";
      this.releaseDate = "";
      this.downloads = new Downloadinclusion();
    }
    public id: string;
    public versionName: string;
    public releaseDate: string;
    public downloads: Downloadinclusion[];
  }


  export class Downloadinclusion extends  Array<Downloadinclusion> {
    constructor() {
          super();
          this.platform = "";
          this.size = "";
          this.downloadUrl = "";
        }
        public platform: string;
        public size: string;
        public downloadUrl: string;
      }

  // export class versionListinclusion extends  Array<versionListinclusion> {
  //   constructor() {
  //     super();
  //     this.versionName = "";
  //     this.versionSize = "";
  //     this.versionDownload = "";
  //   }
  //   public versionName: string;
  //   public versionSize: string;
  //   public versionDownload: string;
  // }
  