import * as React from "react";
import { useRef } from "react";
import { OurAppsResponse } from "../../../apiServices/Models/WebsiteContent/OurAppsModels/OurAppsResponse";
import { ContentServices } from "../../../apiServices/Services/ContentServices";
import { AppsBanner } from "./AppsBanner";
import { AppsYoutubeVideoModal } from "../Header/Appsyoutube";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { OurAppsOtherResponse } from "../../../apiServices/Models/WebsiteContent/OurAppsModels/OurAppsOthersResponse";

interface IOurAppCompoProps {}
const contentService = new ContentServices();
const OurAppCompo: React.FunctionComponent<IOurAppCompoProps> = (
  props: any
) => {
  const videoModalRefapp: any = useRef();
  const setAppsData = (link: string, appsname: string) => {
    videoModalRefapp.current.setData(link, appsname);
  };

  const [appList, setappList] = React.useState<OurAppsResponse[]>([]);
  const getProjectsData = (): OurAppsResponse[] => {
    return contentService.getAllapps();
  };

  // new
  const [otherapp, setotherapp] = React.useState<OurAppsOtherResponse[]>([]);
  const getOtherAppData = (): OurAppsOtherResponse[] => {
    return contentService.getAllothers();
  };

  // new
  React.useEffect(() => {
    setappList(getProjectsData());
    setotherapp(getOtherAppData());

    window.scrollTo(0, 0);
  }, [setappList]);

  return (
    <>
      <div className="ourApps-main-block mx-w-1440">
        <div className="ourApps-inner">
          <div className="download_all_section">
            <div className="techxr_small_info">
              <div className="info-innertechXr">
                <p>
                  The best AR-VR apps now available on -
                  <span className="blue">SenseXR Play</span>{" "}
                  <div className="flex_btns_">
                    <a
                      href=" https://drive.google.com/drive/folders/16lSJfgBxkUWebh0BlY_08f2QwvUm-FC0?usp=sharing"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      DOWNLOAD NOW
                    </a>
                    <a
                      href=" https://play.google.com/store/apps/details?id=com.TechXR.SenseXRExperiences"
                      target={"_blank"}
                      className="playsotr_download"
                    >
                      <img
                        src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/ygyvj0kdmhofYjYz_2023-04-01T070625694033.download.png"
                        alt=""
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/in/app/sensexr-play/id1660377653"
                      target={"_blank"}
                      className="playsotr_download"
                      rel="noreferrer"
                    >
                      <img
                        src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/dSimXCRGqgvwf3zQ_2023-04-01T070654339911.download (1).png"
                        alt=""
                      />
                    </a>
                  </div>
                </p>
              </div>
            </div>
          </div>

          <div className="controller-banner">
            {/* <div className="controller__video p-relative">
              <ParticlesCompo />
              <video autoPlay muted loop id="myVideo">
                <source src={myVar2} />
              </video>
            </div> */}
            <AppsBanner />
          </div>

          <div className="ourAppsCard-list py-100">
            <div className="common-container">
              <div className="common-heading-home mb-50 ">
                <h2 className=" text-center">
                  <span>SenseXR</span> Apps
                </h2>
              </div>

              <div className="main-app-tabs mb-100">
                <Tabs>
                  <TabList>
                    <Tab>SenseXR Play</Tab>
                    <Tab>Other VR Apps</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="tabpanel_inner">
                      <div className="techxr_small_info">
                        <div className="info-innertechXr">
                          <p>
                            The best AR-VR apps now available on -
                            <span className="blue">SenseXR Play</span>{" "}
                            <a
                              href="https://play.google.com/store/apps/details?id=com.TechXR.SenseXRExperiences"
                              target={"_blank"}
                              rel="noreferrer"
                            >
                              DOWNLOAD NOW
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="common-row mt-100">
                        {appList?.map((apps, key) => {
                          return (
                            <div
                              className="common-col-4 common-md-6 common-xs-12 mb-20"
                              key={key}
                            >
                              <div className="apps-card">
                                <button
                                  className="dropdown-item usecase__btn"
                                  data-bs-toggle="modal"
                                  data-bs-target="#youtubeVideoModal3"
                                  onClick={() =>
                                    setAppsData(
                                      apps.videoUrl,
                                      apps.appName ? apps.appName : ""
                                    )
                                  }
                                >
                                  <div className="appcard-img">
                                    <img
                                      src={apps.imageUrl}
                                      alt=""
                                      className="thum_img"
                                    />
                                    <img
                                      src={apps.appgif}
                                      alt=""
                                      className="Gif_img"
                                    />
                                  </div>
                                </button>

                                <div className="aaps-description-block">
                                  <div className="__flex-box">
                                    <div className="___content__block">
                                      <h2>{apps.appName}</h2>
                                      <div className="all-icons-list">
                                        <ul>
                                          {apps.AppsInclusion.gaming ===
                                          true ? (
                                            <>
                                              <li>
                                                <div className="inner-icons-list">
                                                  <div className="img-block-icon">
                                                    <img
                                                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/a9Qkkv4Nxl28ivmv_2022-11-29T071224505757.Gaming_icon.png"
                                                      alt=""
                                                      className="normal-img"
                                                    />
                                                    <img
                                                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/d30OAJqr06d7POcI_2022-11-29T071303990552.Gaming_white_icon.png"
                                                      alt=""
                                                      className="onhover-img"
                                                    />
                                                  </div>
                                                  <span>Gaming</span>
                                                </div>
                                              </li>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {apps.AppsInclusion.vrHeadset ===
                                          true ? (
                                            <>
                                              <li>
                                                <div className="inner-icons-list">
                                                  <div className="img-block-icon">
                                                    <img
                                                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/kDqGQAFRitfTbIpV_2022-11-29T071404116218.VR_icon.png"
                                                      alt=""
                                                      className="normal-img"
                                                    />
                                                    <img
                                                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/q9sOMKC9YEsYdfcF_2022-11-29T071435305587.VR_white_icon.png"
                                                      alt=""
                                                      className="onhover-img"
                                                    />
                                                  </div>
                                                  <span>VR</span>
                                                </div>
                                              </li>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {apps.AppsInclusion.Experience ===
                                          true ? (
                                            <>
                                              <li>
                                                <div className="inner-icons-list">
                                                  <div className="img-block-icon">
                                                    <img
                                                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/nWo9CnNj1wjYKVAv_2022-11-29T071537132537.Experience_icon.png"
                                                      alt=""
                                                      className="normal-img"
                                                    />
                                                    <img
                                                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/p8REhLcEImaY9XNM_2022-11-29T071610067956.Experience_white_icon.png"
                                                      alt=""
                                                      className="onhover-img"
                                                    />
                                                  </div>
                                                  <span>Experience</span>
                                                </div>
                                              </li>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {apps.AppsInclusion.arHeadset ===
                                          true ? (
                                            <>
                                              <li>
                                                <div className="inner-icons-list">
                                                  <div className="img-block-icon">
                                                    <img
                                                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/9dMv0mrjPKAMZaJt_2022-11-29T071731551047.Ar-icon.png"
                                                      alt=""
                                                      className="normal-img"
                                                    />
                                                    <img
                                                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/DvJJ4xvobud9ROSs_2022-11-29T071811463223.AR-white-icon.png"
                                                      alt=""
                                                      className="onhover-img"
                                                    />
                                                  </div>
                                                  <span>AR</span>
                                                </div>
                                              </li>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {apps.AppsInclusion.glass === true ? (
                                            <>
                                              <li>
                                                <div className="inner-icons-list">
                                                  <div className="img-block-icon">
                                                    <img
                                                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/eZu8OAbeexbBtVca_2022-12-02T114258494171.3D-glasses.png"
                                                      alt=""
                                                      className="normal-img"
                                                    />
                                                    <img
                                                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/eZu8OAbeexbBtVca_2022-12-02T114258494171.3D-glasses.png"
                                                      alt=""
                                                      className="onhover-img"
                                                    />
                                                  </div>
                                                  <span>3D</span>
                                                </div>
                                              </li>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                    {/* <a
                                      href={apps.appUrl}
                                      className="install_btn"
                                    >
                                      Install
                                    </a> */}
                                  </div>
                                  <p>{apps.appDescription}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <AppsYoutubeVideoModal ref={videoModalRefapp} />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="tabpanel_inner othersappsPanel">
                      <div className="techxr_small_info">
                        <div className="info-innertechXr">
                          <p>
                            <span className="blue">Install and play</span> these
                            third party VR Games with senseXR Controller via
                            bluetooth.
                          </p>
                        </div>
                        <div className="list-innertechXr">
                          <ul className="infolistsmall">
                            <li>
                              Find The game controls in the app description
                            </li>
                            <li>
                              There is no 6DoF interaction in these applications
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="common-row mt-100">
                        {otherapp?.map((otherapp, key) => {
                          return (
                            <div
                              className="common-col-4 common-md-6 common-xs-12 mb-20"
                              key={key}
                            >
                              <div className="apps-card">
                                <div className="appcard-img">
                                  <img
                                    src={otherapp.imageUrl}
                                    alt=""
                                    className="thum_img"
                                  />
                                  <img
                                    src={otherapp.appgif}
                                    alt=""
                                    className="Gif_img"
                                  />
                                </div>

                                <div className="aaps-description-block">
                                  <div className="__flex-box mb-5">
                                    <div className="___content__block">
                                      <h2>{otherapp.appName}</h2>
                                      {/* <div className="all-icons-list">
                                        <ul>
                                          {otherapp.AppsInclusion.gaming ===
                                          true ? (
                                            <>
                                              <li>
                                                <div className="inner-icons-list">
                                                  <div className="img-block-icon">
                                                    <img
                                                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/a9Qkkv4Nxl28ivmv_2022-11-29T071224505757.Gaming_icon.png"
                                                      alt=""
                                                      className="normal-img"
                                                    />
                                                    <img
                                                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/d30OAJqr06d7POcI_2022-11-29T071303990552.Gaming_white_icon.png"
                                                      alt=""
                                                      className="onhover-img"
                                                    />
                                                  </div>
                                                  <span>Gaming</span>
                                                </div>
                                              </li>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {otherapp.AppsInclusion.vrHeadset ===
                                          true ? (
                                            <>
                                              <li>
                                                <div className="inner-icons-list">
                                                  <div className="img-block-icon">
                                                    <img
                                                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/kDqGQAFRitfTbIpV_2022-11-29T071404116218.VR_icon.png"
                                                      alt=""
                                                      className="normal-img"
                                                    />
                                                    <img
                                                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/q9sOMKC9YEsYdfcF_2022-11-29T071435305587.VR_white_icon.png"
                                                      alt=""
                                                      className="onhover-img"
                                                    />
                                                  </div>
                                                  <span>VR</span>
                                                </div>
                                              </li>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {otherapp.AppsInclusion.Experience ===
                                          true ? (
                                            <>
                                              <li>
                                                <div className="inner-icons-list">
                                                  <div className="img-block-icon">
                                                    <img
                                                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/nWo9CnNj1wjYKVAv_2022-11-29T071537132537.Experience_icon.png"
                                                      alt=""
                                                      className="normal-img"
                                                    />
                                                    <img
                                                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/p8REhLcEImaY9XNM_2022-11-29T071610067956.Experience_white_icon.png"
                                                      alt=""
                                                      className="onhover-img"
                                                    />
                                                  </div>
                                                  <span>Experience</span>
                                                </div>
                                              </li>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {otherapp.AppsInclusion.arHeadset ===
                                          true ? (
                                            <>
                                              <li>
                                                <div className="inner-icons-list">
                                                  <div className="img-block-icon">
                                                    <img
                                                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/9dMv0mrjPKAMZaJt_2022-11-29T071731551047.Ar-icon.png"
                                                      alt=""
                                                      className="normal-img"
                                                    />
                                                    <img
                                                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/DvJJ4xvobud9ROSs_2022-11-29T071811463223.AR-white-icon.png"
                                                      alt=""
                                                      className="onhover-img"
                                                    />
                                                  </div>
                                                  <span>AR</span>
                                                </div>
                                              </li>
                                            </>
                                          ) : (
                                            <></>
                                          )}

                                          {otherapp.AppsInclusion.glass ===
                                          true ? (
                                            <>
                                              <li>
                                                <div className="inner-icons-list">
                                                  <div className="img-block-icon">
                                                    <img
                                                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/eZu8OAbeexbBtVca_2022-12-02T114258494171.3D-glasses.png"
                                                      alt=""
                                                      className="normal-img"
                                                    />
                                                    <img
                                                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/eZu8OAbeexbBtVca_2022-12-02T114258494171.3D-glasses.png"
                                                      alt=""
                                                      className="onhover-img"
                                                    />
                                                  </div>
                                                  <span>3D</span>
                                                </div>
                                              </li>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </ul>
                                      </div> */}
                                    </div>
                                    <a
                                      href={otherapp.appUrl}
                                      className="install_btn"
                                      target={"_blank"}
                                      rel="noreferrer"
                                    >
                                      Install
                                    </a>
                                  </div>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: otherapp.appDescription,
                                    }}
                                  ></p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="infodesclimer">
                        <p>
                          <span className="redStar">*</span> TechXR has no
                          control over the content of these third party websites
                          and mobile applications
                        </p>
                        <p>
                          <span className="redStar">*</span> Full credit is
                          given to the creators of these applications.
                        </p>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurAppCompo;
