export class LeadResponse {
    constructor(){
        this.id = -1
        this.fullName = ""
        this.email = ""
        this.phone = ""
        this.source = ""
    }
    public id:number
    public fullName:string
    public email:string
    public phone:string
    public source:string
}