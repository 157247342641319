import toast from "react-hot-toast";
import ReactLoading from "react-loading";
import { LocalStorage } from "../../shared/core/Storage/LocalStorage";
import { HandleAuthToken } from "../../shared/core/Utils/HandleAuthToken";
import ReactGA from "react-ga4";
import { AuthService } from "./AuthServices";
import LoaderImage from "../../assets/Loader/Loader-main.gif"

const objAuthService = new AuthService();
const objLocalStorage = new LocalStorage();
const objAuthHandler = new HandleAuthToken();

export const dateFormat = (dateString: string) => {
  return new Date(dateString).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

const units: string[] = [
  "bytes",
  "KB",
  "MB",
  "GB",
  "TB",
  "PB",
  "EB",
  "ZB",
  "YB",
];

export const niceBytes = (n: number) => {
  let l = 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
};

export const getFileType = (filePath: string) => {
  return filePath.split(/[#?]/)[0].split(".").pop()?.trim();
};

export const isImage = (filePath: string) => {
  const fileType = getFileType(filePath);
  const validTypes = ["jpg", "png", "jpeg"];
  if (fileType) {
    return validTypes.includes(fileType);
  }
  return false;
};

export const isVideo = (filePath: string) => {
  const fileType = getFileType(filePath);
  const validTypes = [
    "mp4",
    "mov",
    "wmv",
    "avi",
    "avchd",
    "flv",
    "f4v",
    "swf",
    "mkv",
    "webm",
  ];
  if (fileType) {
    return validTypes.includes(fileType);
  }
  return false;
};

export const getYoutubeVideoId = (url: string): string | undefined => {
  // console.log(url);
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  if (match && match[7].length === 11) {
    // console.log(match[7]);
    return match[7];
  }
  return undefined;
};

export const getYouTubeEmbedLink = (link: string) => {
  var embedLink: string = link;
  if (!embedLink.includes("embed")) {
    const youtubeVideoId = getYoutubeVideoId(embedLink);
    if (youtubeVideoId) {
      embedLink = "https://www.youtube.com/embed/" + youtubeVideoId;
    }
  }
  return embedLink;
};

export const toggleLoader = (isLoading: boolean) => {
  if (isLoading) {
    return (
      <div className="react-loader">
        {/* <ReactLoading type="cubes" color="#2cabde" /> */}
        <img src={LoaderImage} alt="" />
      </div>
    );
  }
  return null;
};

const profileData = () => {
  const data = {
    profileId: objAuthHandler.getProfile(),
  };
  // console.log(data);
  // const headers = {};
  return objAuthService.lms(data);
};

export const getSlug = (name: string): string => {
  return name
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const handleLearn = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    let lmsUrl: string = "";
    lmsUrl = JSON.parse(objLocalStorage.getItem("lmsData") || "{}").lmsUrl;
    console.log(lmsUrl);

    if (!lmsUrl) {
      // window.location.href = lms_url;
      profileData().subscribe({
        next: (response) => {
          let stringResponse: string = JSON.stringify(response.lmsUrl);
          objLocalStorage.setItem("lmsData", JSON.stringify(response));
          lmsUrl = stringResponse;
          console.log(lmsUrl);
          resolve(lmsUrl);
        },
        error: (err) => {
          // setLoader(false);
          if (err.response.data) {
            toast.error(err.response.data);
          } else if (err.response.status === 500) {
            toast.error("Service unavailable.");
          } else {
            toast.error("Something went wrong, please try again!");
          }
        },
      });
    }
    return lmsUrl;
  });
};

// export const getProductImageUrl = (product: ProductSummary) => {
//   // console.log(product);
//   if (
//     product &&
//     product.productMediaDto &&
//     product.productMediaDto.length > 0
//   ) {
//     product.productMediaDto.map((media) => {
//       if (
//         media.document &&
//         media.mediaType !== "" &&
//         media.mediaType === "IMAGE"
//       ) {
//         console.log(encodeURI(media.document.url));
//         return <img src={encodeURI(media.document.url)} alt="projectCard" />;
//       }
//     });
//     //   if (
//     //     product.productMediaDto[0].document &&
//     //     product.productMediaDto[0].mediaType !== ""
//     //   ) {
//     //     if (product.productMediaDto[0].mediaType === "image") {
//     //       return product.productMediaDto[0].document.url;
//     //     }
//   }

//   return "";
// };

export const toTimestamp = (strDate: string | undefined): number => {
  const dt = Date.parse(strDate ? strDate : "");
  return dt;
};

export const sendGAEvent = (
  category: string,
  action: string,
  label: string
) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label, // optional
    // category: "HomePage Lead Form Activity",
    // action: "Field Touched",
    // label: "Form Activities", // optional
    // value: 99, // optional, must be a number
    // nonInteraction: true, // optional, true/false
    // transport: "xhr", // optional, beacon/xhr/image
  });
  return () => {};
};
