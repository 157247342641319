/* eslint-disable array-callback-return */
import * as React from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { ProductSummary } from "../../../../apiServices/Models/Products/ProductSummaryModel";
import { ProductService } from "../../../../apiServices/Services/ProductServices";
import {
  isImage,
  toggleLoader,
} from "../../../../apiServices/Services/CommonServices";
import ProductSlider from "./ProductSliderComp";
import BgImage from "../../../../assets/images/auth-pages-background/bg_png.png";
import BgImage2 from "../../../../assets/images/auth-pages-background/bg_png2.png";
import ARImage from "../../../../assets/images/project-details/cube.png";
import VRImage from "../../../../assets/images/project-details/virtual-reality.png";
import OtherImage from "../../../../assets/images/project-details/worldwide.png";
import AndroidLogo from "../../../../assets/images/project-details/android-logo.png";

import AndroidImage from "../../../../assets/images/project-details/Android.png";
import AppleLogo from "../../../../assets/images/project-details/apple-black-logo.png";
import WindowsLogo from "../../../../assets/images/project-details/windows.png";
import OculusLogo from "../../../../assets/images/project-details/Oculus.png";
import { ProductPackage } from "../../../../apiServices/Models/Products/ProductPackageModel";
import { Document } from "../../../../apiServices/Models/Products/DocumentModel";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useAppSelector } from "../../../../app/hooks";
import { selectUser } from "../../../../features/auth/AuthSlice";
import { DeveloperIdResponse } from "../../../../apiServices/Models/DeveloperProfile/DeveloperIdResponse";

const objProductService = new ProductService();

interface ProducDetailsCompProps {}

const ProducDetailsComp: React.FunctionComponent<ProducDetailsCompProps> = (
  props
) => {
  const user = useAppSelector(selectUser);
  const { project_id } = useParams();

  // const [productDetails, setProductDetails] = React.useState<ProductSummary>();
  const [productDetails, setProductDetails] = React.useState<ProductSummary>();
  const [isLoading, setLoader] = React.useState(false);
  const [editable, setEditable] = React.useState(false);

  // const [downloadUrl, setDownloadUrl] = React.useState("");
  // const [gitUrl, setGitUrl] = React.useState("");

  const getProductDetails = (product_id: string) => {
    setLoader(true);
    const productDetails = objProductService.productById(product_id);
    productDetails.subscribe({
      next: (response: ProductSummary) => {
        setProductDetails(response);
        if (user.profile?.id !== undefined)
          checkEditStatus(response.developerId, user.profile.id);
      },
      error: (err) => {
        if (err.response.data) {
          toast.error(err.response.data);
        } else if (err.response.status === 500) {
          toast.error("Service unavailable.");
        } else {
          toast.error("Something went wrong, please try again!");
        }
      },
      complete: () => {
        setLoader(false);
      },
    });
  };

  const checkEditStatus = (developerId: number, profileId: number) => {
    objProductService.getDeveloperId(profileId).subscribe({
      next: (developerIdResponse: DeveloperIdResponse) => {
        if (developerIdResponse.id === developerId) {
          setEditable(true);
        } else {
          setEditable(false);
        }
      },
      error: (err) => {
        setEditable(false);
      },
      complete: () => {},
    });
  };

  React.useEffect(() => {
    getProductDetails(project_id ? project_id : "");
  }, [project_id]);

  const getProjecTypeBlocks = () => {
    let projectTypeBlocksArray: JSX.Element[] = [];

    productDetails?.productTypeList.map((type, key) => {
      if (type === "AR") {
        projectTypeBlocksArray.push(
          <li key={key}>
            <div className="pro_type_d">
              <img src={ARImage} alt="AR" />
              <span>AR</span>
            </div>
          </li>
        );
      } else if (type === "VR") {
        projectTypeBlocksArray.push(
          <li key={key}>
            <div className="pro_type_d">
              <img src={VRImage} alt="" />
              <span>VR</span>
            </div>
          </li>
        );
      } else {
        projectTypeBlocksArray.push(
          <li key={key}>
            <div className="pro_type_d">
              <img src={OtherImage} alt="" />
              <span>WEB</span>
            </div>
          </li>
        );
      }
    });
    return projectTypeBlocksArray;
  };

  const getAppSize = (appDocument: Document): string => {
    if (appDocument) {
      return appDocument.size
        ? (appDocument.size / 1000000).toPrecision(2)
        : "0";
    }
    return "0";
  };

  const getAndroidAppDetails = () => {
    const productPackages: Array<ProductPackage> = productDetails?.releases
      ? productDetails.releases &&
        productDetails.releases.length > 0 &&
        productDetails.releases[0].productPackages
        ? productDetails.releases[0].productPackages
        : []
      : [];
    const androidAppIndex: number = productPackages.findIndex(
      (app) => app.unityPlatform === "ANDROID"
    );

    if (androidAppIndex !== -1) {
      const defAndroidApp: ProductPackage = productPackages[androidAppIndex];

      return (
        <div>
          <a href={defAndroidApp.document.url}>
            <button className="applebtn">
              <img src={AndroidImage} alt="" /> FREE DOWNLOAD
            </button>
          </a>
          <div key={androidAppIndex} className="small_details">
            <span>{defAndroidApp.unityPlatform}</span>
            <span>{getAppSize(defAndroidApp.document) + " MB"}</span>
          </div>
        </div>
      );
    } else {
      if (productPackages.length === 0) {
        return (
          <div className="Error_build">
            <p>No Build Available</p>
          </div>
        );
      } else {
        let randomPackage = productPackages.pop()
          ? productPackages.pop()
          : new ProductPackage();
        if (randomPackage) {
          return (
            <div>
              <Link to={randomPackage.document.url}>
                <button className="applebtn">
                  <img src={AndroidImage} alt="" /> FREE DOWNLOAD
                </button>
              </Link>

              <div className="small_details">{getAndroidAppDetails()}</div>
              <div key={randomPackage.id} className="small_details">
                <span>{randomPackage.unityPlatform}</span>
                <span>
                  {randomPackage.document.size
                    ? randomPackage.document.size / 1000000
                    : 0}{" "}
                  MB
                </span>
              </div>
            </div>
          );
        }
      }
    }
    return "";
  };

  const getCompatiblePlatforms = () => {
    let compatibleAppsBlocksArray: JSX.Element[] = [];
    let platformLogo: string = "";
    let platformTitles: string = "";

    productDetails?.releases?.map((release) => {
      release.productPackages?.map((pkg, key) => {
        if (pkg.unityPlatform === "MACOS") {
          platformLogo = AppleLogo;
          platformTitles = "For macOS";
        } else if (pkg.unityPlatform === "WINDOWS") {
          platformLogo = WindowsLogo;
          platformTitles = "For Windows";
        } else if (pkg.unityPlatform === "IOS") {
          platformLogo = AppleLogo;
          platformTitles = "For iOS";
        } else if (pkg.unityPlatform === "OCULUS") {
          platformLogo = OculusLogo;
          platformTitles = "For Oculus";
        } else if (pkg.unityPlatform === "ANDROID") {
          platformLogo = AndroidLogo;
          platformTitles = "For Android";
        }
        compatibleAppsBlocksArray.push(
          <li key={key}>
            <a href={pkg.document.url}>
              <div className="social_plateform">
                <img src={platformLogo} alt="" />
                <span>{platformTitles}</span>
              </div>
            </a>
          </li>
        );
      });
    });
    return compatibleAppsBlocksArray;
  };

  const getPlayControls = () => {
    let playControlsBlocksArray: JSX.Element[] = [];
    productDetails?.releases?.map((release) => {
      release.appControl?.controls.map((control, key) => {
        playControlsBlocksArray.push(
          <tr key={key}>
            <td>{control.input}</td>
            <td>{control.outcome}</td>
          </tr>
        );
      });
    });
    return playControlsBlocksArray;
  };

  const getProductImage = (): string => {
    let imageUrl: string = "";

    productDetails?.productMediaDto?.map((media) => {
      if (media.document) {
        imageUrl = media.document.url;
        if (isImage(imageUrl)) {
          return imageUrl;
        }
      }
    });
    return "";
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{"TechXR - " + productDetails?.name} </title>
          <meta name="title" content={productDetails?.name} />
          <meta name="description" content={productDetails?.description} />
          <meta property="image" content={getProductImage()} />
        </Helmet>
      </HelmetProvider>
      {toggleLoader(isLoading)}
      <div className="project-main mx-w-1440">
        <div className="common-container">
          <img src={BgImage} alt="" className="bg-overlay" />
          <img src={BgImage2} alt="" className="bg-overlay second" />
          {productDetails ? (
            <>
              {editable ? (
                <>
                  <div className="editbtnmyProject">
                    <Link to={"/project/edit/" + project_id}>Edit</Link>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="common-row top_bar_row">
                <div className="project-details-left_side">
                  <div className="product-left-inner">
                    <div className="slider-section">
                      <ProductSlider
                        mediaDto={
                          productDetails.productMediaDto
                            ? productDetails.productMediaDto
                            : []
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="project-details-right_side">
                  <div className="product-right-inner">
                    <h1>{productDetails.name}</h1>
                    <div className="right_content_details">
                      <div className="pro_details_sub first">
                        <h2>
                          <span>Project</span> Description
                        </h2>
                      </div>
                      <p>{productDetails.description}</p>

                      <div className="git-img">
                        {productDetails.gitHubLink !== "" ? (
                          <>
                            <a href={productDetails.gitHubLink} target="blank">
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fab"
                                data-icon="github"
                                className="svg-inline--fa fa-github fa-4x git-icon"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 496 512"
                              >
                                <path
                                  fill="currentColor"
                                  d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
                                ></path>
                              </svg>
                              <span>View on Github</span>
                            </a>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="right_content_details">
                      <div className="pro_details_sub first">
                        <h2>
                          <span>Project</span> Type
                        </h2>
                      </div>
                      <ul className="project_type">{getProjecTypeBlocks()}</ul>

                      <ul className="auther_details_project">
                        <li>
                          <span>Developed by</span>
                        </li>
                        <li>
                          <strong>{productDetails.developerName}</strong>
                        </li>
                      </ul>
                      <div className="bottom_btn_block">
                        {getAndroidAppDetails()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="common-row bottom_bar_row">
                <div className="project-details-left_side">
                  <div className="product-left-inner">
                    <div className="plateforms_block">
                      <div className="pro_details_sub">
                        <h2>
                          <span>Compatible </span> Platforms
                        </h2>
                      </div>
                      <ul className="plateforms_details">
                        {getCompatiblePlatforms()}
                      </ul>
                    </div>
                    <div className="how_to_play">
                      <div className="pro_details_sub">
                        <h2>
                          <span>Play </span> Controls
                        </h2>
                      </div>
                      <div className="table_play">
                        <table>
                          <thead>
                            <tr>
                              <th>Events</th>
                              <th>Value</th>
                            </tr>
                          </thead>
                          <tbody>{getPlayControls()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div>
              <h2>Loading...</h2>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProducDetailsComp;
