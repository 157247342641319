import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import { ContentServices } from "../../../../apiServices/Services/ContentServices";
import { HomePageAchievementsResponse } from "../../../../apiServices/Models/WebsiteContent/HomePageModels/HomePageAchievementsResponse";

const contentService = new ContentServices();
interface OurAchievementsCompProps {}

export const OurAchievementsComp: React.FunctionComponent<
  OurAchievementsCompProps
> = (props) => {
  const [Achivement, setAchivement] = React.useState<
    HomePageAchievementsResponse[]
  >([]);
  const getProjectsData = (): HomePageAchievementsResponse[] => {
    return contentService.getAllachivment();
  };
  React.useEffect(() => {
    setAchivement(getProjectsData());
  }, [setAchivement]);

  return (
    <div className="achievments">
      {/* <h2 className="achievments-title">
        Our <span>Achievements</span>
      </h2> */}
      <div className="common-heading-home mt-150">
        <h2 className="text-center">
          <span>Our</span>
          Achievements
        </h2>
      </div>
      <div className="mt-70">
        <div className="achievments-list  text-center">
          <Swiper
            slidesPerView={5}
            spaceBetween={30}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 8000,
              disableOnInteraction: true,
            }}
            loop={true}
            navigation={true}
            modules={[FreeMode, Pagination, Navigation, Autoplay]}
            className="mySwiper"
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {Achivement?.map((Achive, key) => {
              return (
                <SwiperSlide>
                  <div className="achievment" key={key}>
                    <img src={Achive.achiveThumblogo} alt="EduPrenuer-Logo" />

                    <div className="achievment-content">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: Achive.achiveDescription,
                        }}
                      ></div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {/* <!--------Our Achievment Start --------> */}
        </div>
      </div>

      {/* <!--------Our Achievments Partners End --------> */}
    </div>
  );
};
