import * as React from "react";
import { useMediaQuery } from "react-responsive";
import BlockEngineDownloadCompo from "../BlockEngine/BlockEngineDownloadCompo";
export interface IXrBlockEngineCompoProps {}
export default function XrBlockEngineCompo(props: IXrBlockEngineCompoProps) {
  const block = "http://blockengine.techxrdev.in/";
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 991px)" });
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="block_engine mx-w-1440 light_bg">
        {/* <div className="common-heading-home">
            <h2 className=" text-center">
              <span>XR</span> Block
            </h2>
          </div> */}
        <div className="xrblockiframe">
          {isTabletOrMobile ? (
            <div className="blockenginalert">
              <span className="alertEngine">
                "For Best Experience Download the XR Block Mobile Application"
              </span>
              <BlockEngineDownloadCompo />
            </div>
          ) : (
            <iframe
              className="forum-frame"
              title="Xrblock"
              src={block}
              allowFullScreen
            ></iframe>
          )}
        </div>
      </div>
    </>
  );
}
