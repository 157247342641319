import * as React from "react";
import { HomePageCoursesResponse } from "../../../apiServices/Models/WebsiteContent/HomePageModels/HomePageCoursesResponse";
import { ContentServices } from "../../../apiServices/Services/ContentServices";
import { toggleLoader } from "../../../apiServices/Services/CommonServices";
import { SingleCourseCardComp } from "../HomePage/SingleCourseCard/SingleCourseCardComp";
import { Helmet, HelmetProvider } from "react-helmet-async";

const contentService = new ContentServices();

interface AllCoursesMainCompProps {}

export const AllCoursesMainComp: React.FunctionComponent<
  AllCoursesMainCompProps
> = (props) => {
  const [coursesList, setCourses] = React.useState<HomePageCoursesResponse[]>(
    []
  );
  const [isLoading, setLoader] = React.useState(false);

  const getCoursesData = (): HomePageCoursesResponse[] => {
    setLoader(true);
    return contentService.getAllCourses();
  };
  React.useEffect(() => {
    setCourses(getCoursesData());
    setLoader(false);
  }, [setCourses]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Virtual & Augmented Certification Course in India | TechXR
          </title>
          <meta
            name="title"
            content="Virtual & Augmented Certification Course in India | TechXR"
          />
          <meta
            name="description"
            content="TechXR is an online platform that provides Online virtual and augmented courses with training and Certification. Our mission is to democratize access to emerging technologies."
          />
          <meta
            property="image"
            content="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/uD41orAQL8hE2zva_2023-05-30T064448436786.Img web.png"
          />
          <link rel="canonical" href=" https://techxr.co/courses" />
          <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
        </Helmet>
      </HelmetProvider>
      {toggleLoader(isLoading)}
      <div className="course_main_page mx-w-1440">
        <div className="Course_top_heading">
          <div className="common-container">
            <div className="course_flex_box">
              <div className="common-heading course_title">
                <h1>All Courses</h1>
                <h3>TechXR Courses</h3>
              </div>

              <div className="search_box_course">
                <button>
                  <i className="fa fa-search"></i>
                </button>
                <input type="text" placeholder="Search in Courses" />
              </div>
            </div>
          </div>
        </div>
        <div className="courses_main_page">
          <div className="common-container">
            <div className="common-row">
              {/* <CoursesCardsComp
                coursesList={coursesList}
                limit={coursesList.length}
              /> */}
              {coursesList.map((coursenew, index) => {
                return (
                  <>
                    <div
                      className="common-col-4 common-md-6 common-xs-12 mb-30"
                      key={index}
                    >
                      <SingleCourseCardComp course={coursenew} />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
