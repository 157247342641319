export const Constant = {
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  forumUrl: process.env.REACT_APP_FORUM_URL,
  applicationId: process.env.REACT_APP_TECHXR_APPLICATION_ID,
  labelType: {
    info: "info",
    error: "danger",
    warning: "warning",
    success: "success",
  },
};
// console.log("Constant baseUrl = " + Constant.baseUrl);
// console.log("Constant applicationId = " + Constant.applicationId);
// console.log("Constant forumUrl = " + Constant.forumUrl);
// console.log( "this is my file link = "+ Constant.forumUrl );

export class WebServiceConstant {
  // Auth endpoints
  static AUTH_HELLO: string = Constant.baseUrl + "auth/hello";
  static AUTH_LOGIN: string = Constant.baseUrl + "auth/login/";
  static AUTH_GET_PROFILE: string = Constant.baseUrl + "auth/profile/search/";
  static LEAD_CAPTURE: string = Constant.baseUrl + "lead/";

  static DEVELOPER_GET_BY_PROFILE: string = Constant.baseUrl + "developer";
  
  static DEVELOPER_GET_LICENSES: string =
    Constant.baseUrl + "developer/license/developer";
  static DEVELOPER_CREATE_LICENSES: string =
    Constant.baseUrl + "developer/license/";
  static DEVELOPER_DELETE_LICENSE: string =
    Constant.baseUrl + "developer/license/deactivate";

  // static AUTH_REGISTER_USER: string =
  //   Constant.baseUrl + "auth/register/student";
// NEW
  static AUTH_REGISTER_USER_NEW: string =
    Constant.baseUrl + "auth/v1/signup";
// NEW


  static AUTH_VERIFY_OTP_CREATE_DEVELOPER: string =
    Constant.baseUrl + "auth/register/student/verifyotp";
  static AUTH_FORGOT_PASSWORD: string =
    Constant.baseUrl + "auth/password/forgot";
  static AUTH_CHANGE_PASSWORD: string =
    Constant.baseUrl + "auth/profile/password/change";
  static LMS: string = Constant.baseUrl + "lms/";

  // Product endpoints
  static PRODUCT_SAVE_UPDATE: string = Constant.baseUrl + "product/";
  static PRODUCT_SAVE_IMAGE: string = Constant.baseUrl + "product/image";
  static PRODUCT_SAVE_VIDEO: string = Constant.baseUrl + "product/video";
  static PRODUCT_DELETE_MEDIA: string = Constant.baseUrl + "product/media";


  static PRODUCT_DELETE_PACKAGE: string = Constant.baseUrl + "product/build";
// 
static PRODUCT_CHANGE_THUMB: string = Constant.baseUrl + "product/markAsDefault";
// 
  
  static PRODUCT_DELETE: string = Constant.baseUrl + "product/";


  static PRODUCT_SAVE_BUILD: string = Constant.baseUrl + "product/build";
  static PRODUCT_SAVE_PLAY_CONTROLS: string =
    Constant.baseUrl + "product/controls";
  static PRODUCT_GET_ALL: string = Constant.baseUrl + "product/product";
  static PRODUCT_GET_BY_ID: string = Constant.baseUrl + "product/product/"; // <id> will be added in signature file
  static PRODUCT_GET_BY_DEVELOPER: string = Constant.baseUrl + "product/developer/"; // <id> will be added in signature file
  static PRODUCT_UPDATE: string = Constant.baseUrl + "product/product/"; // <id> will be added in signature file
  static PRODUCT_PUBLISH: string = Constant.baseUrl + "product/product/"; // <id>/publish will be added in signature file
  static PRODUCT_PACKAGE: string = Constant.baseUrl + "product/product/package";
  static PRODUCT_DOWNLOAD_LATEST_RELEASE: string =
    Constant.baseUrl + "product/download";
  static PRODUCT_DEVELOPER: string = Constant.baseUrl + "product/developer/"; //<id> will be added in signature file

  // Developer endpoints
  static DEVELOPER_PROFILE: string = Constant.baseUrl + "developer/profile"; //<id> will be added in signature file
  static DEVELOPER_ID: string = Constant.baseUrl + "developer/"; //<id> will be added in signature file
}
