export class CountryContent extends Array<CountryContent> {
    constructor() {
        super();
        this.id = 0;
        this.name = "";
        this.code = "";
        this.dialing_code = "";
        
      }
      public id: number;
      public name: string;
      public code: string;
      public dialing_code: string;
    }
    
  