import * as React from "react";
import { OurCoursesLeftComp } from "./OurCoursesLeftComp";
import { CoursesCardsComp } from "./CoursesCardsComp";
import { HomePageCoursesResponse } from "../../../../apiServices/Models/WebsiteContent/HomePageModels/HomePageCoursesResponse";
import { ContentServices } from "../../../../apiServices/Services/ContentServices";

const contentService = new ContentServices();

interface OurCoursesMainCompProps {}

export const OurCoursesMainComp: React.FunctionComponent<
  OurCoursesMainCompProps
> = (props) => {
  const [coursesList, setCourses] = React.useState<HomePageCoursesResponse[]>(
    []
  );
  const getCoursesData = (): HomePageCoursesResponse[] => {
    // console.log(contentService.getAllCourses());
    return contentService.getAllCourses();
  };
  // console.log(getCoursesData());
  React.useEffect(() => {
    setCourses(getCoursesData());
    // console.log(coursesList);
  }, [setCourses]);

  return (
    <div className="our-courses-block py-100">
      <div className="courses-block common-container">
        <div className="common-row">
          <OurCoursesLeftComp></OurCoursesLeftComp>
          <div className="courses-block-right w-75">
            <div className="common-row w-100">
              <CoursesCardsComp coursesList={coursesList} limit={5} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
