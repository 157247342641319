export class CourseDescriptionResponse {
  constructor() {
    this.id = "0";
    this.mainDesc = "";
    this.onlineClass = true;
    this.doubtSupport = true;
    this.videosAvailable = true;
  }
  public id: string;
  public mainDesc: string;
  public onlineClass: boolean;
  public doubtSupport: boolean;
  public videosAvailable: boolean;
}
