export interface Storage {
  getItem: (key: string) => string | null | undefined;
  setItem: (key: string, value?: string | null | undefined) => void;
}


const localStorage = (() => {
  try {
    // sometimes just accessing local storage can throw (like incognito Safari/Chrome)
    return window.localStorage;
  } catch {
    return null;
  }
})();

export class LocalStorage implements Storage {
  public getItem(key: string): string | null | undefined {
    return localStorage?.getItem(key);
  }

  public setItem(key: string, value: string | null | undefined) {
    if (value) {
      localStorage?.setItem(key, value);
    } else {
      localStorage?.removeItem(key);
    }
  }

  public removeItem(key: string) {
    localStorage?.removeItem(key);
  }
//Deprecated
  public getToken() {
    let userData = localStorage?.getItem('userData');
    let token = (JSON.parse(userData || '{}')).access_token
    return token
  }
//Deprecated
  public getProfile = () => {
    let userData = localStorage?.getItem("userData")
    let profile_id = (JSON.parse(userData || '{}').profile.id)
    return profile_id
  }

  // Cookie: Set and Get
  public setCookie = (cname: string, cvalue: string, exdays: number) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = d.toUTCString();
    const domain = `${process.env.REACT_APP_COOKIE_DOMAIN}`;
    document.cookie = cname + "=" + cvalue + ";expires=" + expires + ";domain=" + domain +";path=/";  
    return true;
  }

  public deleteCookie = (cname: string) => {
    const domain = `${process.env.REACT_APP_COOKIE_DOMAIN}`;
    //console.log(cname + "=;" + "domain=" + domain + ";path=/; expires = Thu, 01 Jan 1970 00:00:00 GMT");
    document.cookie = cname + "=;domain=" + domain + ";path=/; expires = Thu, 01 Jan 1970 00:00:00 GMT";
        
  }

  public getCookie = (cname: string): string => {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
