import * as React from "react";
import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/effect-coverflow";
import { ContentServices } from "../../../../apiServices/Services/ContentServices";
import { HomePageStudentProjectsResponse } from "../../../../apiServices/Models/WebsiteContent/HomePageModels/HomePageStudentProjectsResponse";
// import { YoutubeVideoModal } from "../../Header/YoutubeVideoModal";
import { NewYoutubeVideoModal } from "../../Header/NewyouTube";

const contentService = new ContentServices();

interface StudentProjectCardsCompProps {}

export const StudentProjectCardsComp: React.FunctionComponent<
  StudentProjectCardsCompProps
> = (props) => {
  const videoModalRef: any = useRef();

  const setProjectData = (link: string, projectName: string) => {
    videoModalRef.current.setData(link, projectName);
  };

  const [projectsList, setProjects] = React.useState<
    HomePageStudentProjectsResponse[]
  >([]);
  const getProjectsData = (): HomePageStudentProjectsResponse[] => {
    return contentService.getAllProjects();
  };

  React.useEffect(() => {
    setProjects(getProjectsData());
  }, [setProjects]);

  return (
    <>
      <Swiper
        slidesPerView={5}
        initialSlide={4}
        spaceBetween={30}
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        pagination={{
          clickable: true,
        }}
        // autoplay={{
        //   delay: 8000,
        //   disableOnInteraction: true,
        // }}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 5,
          },
        }}
        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        className="mySwiper"
      >
        {projectsList?.map((project, key) => {
          return (
            <SwiperSlide key={key}>
              <div className="swiper-slide">
                <button
                  className="dropdown-item"
                  data-bs-toggle="modal"
                  data-bs-target="#youtubeVideoModal1"
                  onClick={() =>
                    setProjectData(
                      project.videoUrl,
                      project.projectName ? project.projectName : ""
                    )
                  }
                >
                  <img src={project.imageUrl} alt="StudentProjectImage" />
                  <div className="student-project-title">
                    {project.projectName ? project.projectName : ""}
                  </div>
                  <div className="project-short-desc">
                    {project.studentName ? project.studentName : ""}
                    {/* {project.description ? project.description : ""} */}
                  </div>
                </button>
              </div>
            </SwiperSlide>
          );
        })}
        <div className="swiper-button-next"></div>
        <div className="swiper-button-prev"></div>
        <div className="swiper-pagination"></div>
      </Swiper>
      {/* <YoutubeVideoModal ref={videoModalRef} /> */}
      <NewYoutubeVideoModal ref={videoModalRef} />
    </>
  );
};
