import * as React from "react";
import StarIcon from "../../../../assets/images/student-section/star-c.svg";
import StarIconInactive from "../../../../assets/images/student-section/star.svg";
import AVTimerIcon from "../../../../assets/images/student-section/av-timer.png";
import LanguageIcon from "../../../../assets/images/student-section/language.png";
import GraphicEQIcon from "../../../../assets/images/student-section/graphic-eq.png";
import CertificateIcon from "../../../../assets/images/student-section/certificate-icon.png";
import DeveloperIcon from "../../../../assets/images/student-section/developer-mode.png";
import SupportIcon from "../../../../assets/images/student-section/support.png";
import { ContentServices } from "../../../../apiServices/Services/ContentServices";
import { CourseDetailResponse } from "../../../../apiServices/Models/WebsiteContent/CoursePageModels/CourseDetailResponse";
import { toggleLoader } from "../../../../apiServices/Services/CommonServices";

const contentService = new ContentServices();

// let courses: CourseDetailResponse = new CourseDetailResponse();
interface CoursesAboveFoldLeftCompProps {
  courseId: string | undefined;
}

export const CoursesAboveFoldLeftComp: React.FunctionComponent<
  CoursesAboveFoldLeftCompProps
> = (props) => {
  const courseId = props.courseId;
  const [courseDetail, setCourseDetail] =
    React.useState<CourseDetailResponse>();
  const [isLoading, setLoader] = React.useState(false);

  React.useEffect(() => {
    const getCourseDetail = (): CourseDetailResponse => {
      setLoader(true);
      return contentService.getCourseDetail(courseId);
    };
    setCourseDetail(getCourseDetail());
    setLoader(false);
  }, [courseId, setCourseDetail]);
  const starRatings = (rating: number) => {
    const ratingsArray = [];
    for (let index = 1; index <= 5; index++) {
      ratingsArray.push(
        <li className={index <= rating ? "rating" : "nrating"} key={index}>
          <img
            src={index <= rating ? StarIcon : StarIconInactive}
            alt="StarImage"
          />
        </li>
      );
    }
    return ratingsArray;
  };
  return (
    <>
      {toggleLoader(isLoading)}
      <div className="course-section-left">
        <div className="common-heading mb-10">
          <h1 className="course-heading ">{courseDetail?.name}</h1>
        </div>

        <div className="course-rating">
          <ul>{starRatings(courseDetail?.rating ? courseDetail.rating : 5)}</ul>
        </div>

        {courseDetail ? (
          courseDetail.totalEnrollments > 0 ? (
            <div className="course-enrolled-no">
              ({courseDetail?.totalEnrollments} Students Enrolled)
            </div>
          ) : (
            <div></div>
          )
        ) : (
          <div></div>
        )}

        <div className="clear"></div>
        <div className="level">
          {/* <!--course Duration start--> */}
          <div className="course-duration">
            <img src={AVTimerIcon} alt="AVTimerImage" />
            <p>{courseDetail?.duration}</p>
          </div>

          {/* <!--course Duration end-->  */}

          {/* <!--course language start--> */}
          <div className="course-duration">
            <img src={LanguageIcon} alt="LanguageImage" />
            <p>{courseDetail?.languages}</p>
          </div>

          {/* <!--course language end-->  */}

          {/* <!--course type start--> */}
          <div className="course-duration">
            <img src={GraphicEQIcon} alt="GraphicEQImage" />
            <p>{courseDetail?.courseLevel}</p>
          </div>
        </div>
        <div className="affilation d-flex">
          <img src={CertificateIcon} alt="CertificateIcon" />
          <p className="pl-10">{courseDetail?.affiliation}</p>
        </div>

        <div className="course-m-t-e">
          {/* <!--Course Start Date start--> */}

          {/* <div className="course_block_inner">
            <p className="ptitle">Batch Starting: </p>
            <p className="pdetails">{courseDetail?.startDate}</p>
          </div> */}

          <div className="course_block_inner">
            <p className="ptitle">Course Mode: </p>
            <p className="pdetails">{courseDetail?.courseMode}</p>
          </div>

          <div className="course_block_inner">
            <p className="ptitle">Timing : </p>
            <p className="pdetails">{courseDetail?.timing}</p>
          </div>

          <div className="course_block_inner">
            <p className="ptitle">Eligibilty : </p>
            <p className="pdetails">{courseDetail?.eligibility}</p>
          </div>
        </div>

        <div className="bot-btn d-flex">
          <div className="developers-kit btn-stucture">
            <img src={DeveloperIcon} alt="DeveloperIcon" />
            <p>Developers Kit</p>
          </div>

          {/* <div className="placement-support btn-stucture">
            <img src={SupportIcon} alt="SupportIcon" />
            <p>Placement Support</p>
          </div> */}
        </div>

        {/* <!--Course Course Bot BTN End--> */}

        <div className="course-left-bottom"></div>
      </div>
    </>

    // <!--course Left Section End-->
  );
};
