import * as React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ProductMedia } from "../../../../../apiServices/Models/Products/ProductMediaModel";
import {
  getYouTubeEmbedLink,
  toggleLoader,
} from "../../../../../apiServices/Services/CommonServices";
import { SaveMedia } from "../../../../../apiServices/Models/Products/SaveMediaRequestModel";
import { ProductService } from "../../../../../apiServices/Services/ProductServices";
import toast from "react-hot-toast";
import { Document } from "../../../../../apiServices/Models/Products/DocumentModel";

const objProductService = new ProductService();

interface UploadMediaCompProps {
  mediaDto?: ProductMedia[];
  productId?: string;
}

const UploadMediaComp: React.FunctionComponent<UploadMediaCompProps> = (
  props
) => {
  const [isLoading, setLoader] = React.useState(false);
  const [isEditModeOn, setEditModeOn] = React.useState(true);

  const initialValues = {
    productId: "",
    file: "",
    mediaType: "photo",
    videoUrl: "",
  };

  const mediaList: ProductMedia[] = props.mediaDto ? props.mediaDto : [];

  const productId: string = props.productId ? props.productId : "";
  const [isVideo, setTypeVideo] = React.useState(false);

  const toggleFormEdit = () => {
    setEditModeOn(false);
  };

  const handleSaveImageProduct = (values: SaveMedia) => {
    const header = "multipart/form-data";
    const params = {
      productId: productId,
    };
    const imageData: SaveMedia = {
      file: values.file,
    };

    if (productId !== "") {
      setLoader(true);
      const saveImage = objProductService.saveImage(imageData, header, params);

      saveImage.subscribe({
        next: (response: ProductMedia) => {},
        error: (err) => {
          if (err.response.data) {
            toast.error(err.response.data);
          } else if (err.response.status === 500) {
            toast.error("Service unavailable.");
          } else {
            toast.error("Something went wrong, please try again!");
          }
        },
        complete: () => {
          setLoader(false);
          window.location.reload();
        },
      });
    }
  };

  const handleSaveVideoProduct = (values: SaveMedia) => {
    if (values.videoUrl && productId) {
      const documentObj = new Document();
      documentObj.url = values.videoUrl;
      const videoData: SaveMedia = new SaveMedia();
      videoData.mediaType = "VIDEO";
      videoData.productId = productId;
      videoData.document = documentObj;

      if (productId !== "") {
        setLoader(true);
        const saveVideo = objProductService.saveVideo(videoData);

        saveVideo.subscribe({
          next: (response: ProductMedia) => {},
          error: (err) => {
            if (err.response.data) {
              toast.error(err.response.data);
            } else if (err.response.status === 500) {
              toast.error("Service unavailable.");
            } else {
              toast.error("Something went wrong, please try again!");
            }
          },
          complete: () => {
            setLoader(false);
            window.location.reload();
          },
        });
      }
    }
  };

  const handleDeleteMedia = (mediaId: string) => {
    if (mediaId !== "") {
      const params = {
        productMediaId: mediaId,
      };
      const deleteMedia = objProductService.deleteMedia(params);
      setLoader(true);

      deleteMedia.subscribe({
        next: (response: ProductMedia) => {},
        error: (err) => {
          setLoader(false);

          if (err.response.data) {
            toast.error(err.response.data);
          } else if (err.response.status === 500) {
            toast.error("Service unavailable.");
          } else {
            toast.error("Something went wrong, please try again!");
          }
        },
        complete: () => {
          setLoader(false);
          window.location.reload();
        },
      });
    }
  };

  // new-api===========================

  // new-api===========================

  const handleChageThumbnail = (mediaId: string) => {
    if (mediaId !== "") {
      const ChangeThumb = objProductService.markAsdefault(mediaId);
      setLoader(true);

      ChangeThumb.subscribe({
        next: (response: ProductMedia) => {},
        error: (err) => {
          setLoader(false);

          if (err.response.data) {
            toast.error(err.response.data);
          } else if (err.response.status === 500) {
            toast.error("Service unavailable.");
          } else {
            toast.error("Something went wrong, please try again!");
          }
        },
        complete: () => {
          setLoader(false);
          window.location.reload();
        },
      });
    }
  };

  // new-api===========================
  // new-api===========================

  const handleCancel = (resetForm: () => void) => {
    resetForm();
    if (productId) {
      setEditModeOn(true);
    }
    //}
  };

  const getImageBlocks = (mediaList: ProductMedia[]) => {
    const blocksArray: JSX.Element[] = [];
    mediaList.map((media, key) => {
      if (media.document) {
        if (media.mediaType === "IMAGE") {
          blocksArray.push(
            <>
              <div className="image-upload-inner" key={key}>
                <img src={media.document ? media.document.url : ""} alt="" />
                <button
                  className="dismis-btn"
                  onClick={() => handleDeleteMedia(media.id ? media.id : "")}
                >
                  X
                </button>
                {media.thumbnail === true ? (
                  <>
                    <span className="mark_is_thumbnail active_thumb">
                      current thumbnail
                    </span>
                  </>
                ) : (
                  <>
                    <button
                      className="mark_is_thumbnail"
                      onClick={() =>
                        handleChageThumbnail(media.id ? media.id : "")
                      }
                    >
                      Mark as thumbnail
                    </button>
                  </>
                )}
              </div>
            </>
          );
        } else if (media.mediaType === "VIDEO") {
          blocksArray.push(
            <>
              <div className="image-upload-inner" key={key}>
                <iframe
                  width="100%"
                  height="100%"
                  src={getYouTubeEmbedLink(media.document.url)}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <button
                  className="dismis-btn"
                  onClick={() => handleDeleteMedia(media.id ? media.id : "")}
                >
                  X
                </button>
                {media.thumbnail === true ? (
                  <>
                    <span className="mark_is_thumbnail active_thumb">
                      current thumbnail
                    </span>
                  </>
                ) : (
                  <>
                    <button
                      className="mark_is_thumbnail"
                      onClick={() =>
                        handleChageThumbnail(media.id ? media.id : "")
                      }
                    >
                      Mark as thumbnail
                    </button>
                  </>
                )}
              </div>
            </>
          );
        }
      }
    });
    return blocksArray;
  };

  const projectMediaSchema = Yup.object().shape({
    mediaType: Yup.string().required(),
    file: Yup.mixed().when("mediaType", {
      is: (mediaType: string) => mediaType === "image",
      then: Yup.mixed().required("Required"),
    }),
    videoUrl: Yup.string().when("mediaType", {
      is: (mediaType: string) => mediaType === "video",
      then: Yup.string()
        .url()
        .min(1, "Too Short!")
        .max(200, "Too Long!")
        .required("Required"),
    }),
  });

  return (
    <>
      {toggleLoader(isLoading)}
      <div className="submission-section">
        <div className="submision-sub-headig">
          <h2>
            Project<span>Media</span>
          </h2>
          {isEditModeOn && (
            <button className="edit-btn" onClick={toggleFormEdit}>
              Edit
            </button>
          )}
        </div>
        {!isLoading && (
          <Formik
            initialValues={initialValues}
            validationSchema={projectMediaSchema}
            onSubmit={(values: SaveMedia) => {
              handleSaveVideoProduct(values);
            }}
          >
            {({
              errors,
              touched,
              values,
              setFieldTouched,
              handleChange,
              setValues,
              setFieldValue,
              resetForm,
            }) => (
              <Form>
                <div className="submission-input">
                  <div className="submission-media-upload">
                    <div className="upload-photos-result">
                      <div className="photos-section">
                        {isEditModeOn === false ? (
                          getImageBlocks(mediaList)
                        ) : (
                          <>
                            <div className="disablebaladiv"></div>
                            <div className="pointopacity-flex">
                              {getImageBlocks(mediaList)}
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="upload-selection">
                      <div className="common-row">
                        <div className="input-inner common-col-6 common-xs-12">
                          <label>Select Media Type</label>
                          <Field
                            name="mediaType"
                            as="select"
                            className={
                              errors.mediaType && touched.mediaType
                                ? "error-field"
                                : ""
                            }
                            onChange={(e: any) => {
                              setFieldTouched("mediaType");
                              handleChange(e);
                              e.target.value === "video"
                                ? setTypeVideo(true)
                                : setTypeVideo(false);
                            }}
                            disabled={isEditModeOn}
                          >
                            <option value="photo">Photos</option>
                            <option value="video">Video</option>
                          </Field>
                          <ErrorMessage
                            component="div"
                            className="errormsglabel"
                            name="mediaType"
                          />
                        </div>

                        {isVideo && (
                          <>
                            <div className="upload-result-section common-col-6 common-xs-12">
                              <div className="add-video">
                                <Field
                                  name="videoUrl"
                                  type="text"
                                  placeholder="Youtube URL.."
                                  required=""
                                  className={
                                    errors.videoUrl && touched.videoUrl
                                      ? "error-field"
                                      : ""
                                  }
                                  disabled={isEditModeOn}
                                />
                                <ErrorMessage
                                  component="div"
                                  className="errormsglabel"
                                  name="videoUrl"
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {isEditModeOn === false ? (
                  <>
                    <div className="submission-btn">
                      {isVideo && (
                        <>
                          {" "}
                          <div className="upload-photos">
                            <button type="submit" disabled={isEditModeOn}>
                              add video url
                            </button>
                          </div>
                        </>
                      )}

                      {!isVideo && (
                        <>
                          {" "}
                          <div className="upload-button">
                            <div className="upload-photos">
                              <input
                                id="file"
                                name="file"
                                type="file"
                                onChange={(e: any) => {
                                  setFieldValue("file", e.target.files[0]);
                                  handleSaveImageProduct({
                                    file: e.target.files[0],
                                  });
                                }}
                                className="form-control"
                                disabled={isEditModeOn}
                              />
                              <span>add image</span>
                            </div>
                          </div>
                        </>
                      )}

                      <button
                        className="cancel"
                        type="button"
                        disabled={isEditModeOn}
                        onClick={handleCancel.bind(null, resetForm)}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default UploadMediaComp;
