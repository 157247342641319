import * as React from "react";
import AllProductsCardsComp from "./AllProductsCardsComp";
import Bgimage from "../../../../assets/images/auth-pages-background/bg_png.png";
import Bgimage2 from "../../../../assets/images/auth-pages-background/bg_png2.png";
import { Helmet, HelmetProvider } from "react-helmet-async";

interface ProductsMainCompProps {}

const AllProductsMainComp: React.FunctionComponent<ProductsMainCompProps> = (
  props
) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Projects information & Tenders | TechXR</title>
          <meta
            name="title"
            content="Projects information & Tenders | TechXR"
          />
          <meta
            name="description"
            content="A project is a sequence of tasks that must be completed to attain a certain outcome. It also talks about how to manage projects and the project life cycle."
          />
          <meta
            property="image"
            content="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/uD41orAQL8hE2zva_2023-05-30T064448436786.Img web.png"
          />
        </Helmet>
      </HelmetProvider>
      <div className="project-main mx-w-1440 projectAllcommon">
        <div className="common-container">
          <img src={Bgimage} alt="" className="bg-overlay" />
          <img src={Bgimage2} alt="" className="bg-overlay second" />

          <div className="common-heading course_title">
            <h1>Projects</h1>
            <h3>All</h3>
          </div>
          <div className="common-row">
            <AllProductsCardsComp />
          </div>
        </div>
      </div>
    </>
  );
};

export default AllProductsMainComp;
