import * as React from "react";
import "../../../assets/css/main.css";
import { ContentServices } from "../../../apiServices/Services/ContentServices";
import { FaqsResponse } from "../../../apiServices/Models/WebsiteContent/FaqsPageModel/FaqsResponse";
import Bgimage from "../../../assets/images/auth-pages-background/bg_png.png";
import Bgimage2 from "../../../assets/images/auth-pages-background/bg_png2.png";
import { Helmet, HelmetProvider } from "react-helmet-async";

const contentService = new ContentServices();
const getFaqData = (): FaqsResponse[] => {
  return contentService.getAllFaqs();
};
interface AllFaqProps {}

export const FaqComp: React.FunctionComponent<AllFaqProps> = (props: any) => {
  const [faqList, setFaqs] = React.useState<FaqsResponse[]>(getFaqData());

  const getFaqStucture = (id: string) => {
    if (id) {
      var intId: number = +id + 1;
      return "faq-" + intId;
    }
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>TechXR - FAQs</title>
          <meta name="title" content={faqList[0]?.question} />
          <meta name="description" content={faqList[0]?.answer} />
          <meta
            property="image"
            content="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/uD41orAQL8hE2zva_2023-05-30T064448436786.Img web.png"
          />
        </Helmet>
      </HelmetProvider>
      <div className="main-section py-100">
        <div className="create-account">
          <div className="Privacy-main">
            <div className="common-container">
              <img src={Bgimage} alt="" className="bg-overlay" />
              <img src={Bgimage2} alt="" className="bg-overlay second" />
              <div className="Privacy-row faq-row">
                {/* <PageBackgroundLeft /> */}
                <h2>FAQ</h2>

                {faqList?.map((faq) => (
                  <div className="course-str-tab" key={faq.id}>
                    <input type="checkbox" id={getFaqStucture(faq.id)} />
                    <h2>
                      <label htmlFor={getFaqStucture(faq.id)}>
                        {faq.question}
                      </label>
                    </h2>
                    <div className="p">
                      <div>
                        {faq.answer ? (
                          <div
                            dangerouslySetInnerHTML={{ __html: faq.answer }}
                          ></div>
                        ) : (
                          <div className="clear"></div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {/* <PageBackgroundRight /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
