import { Type } from "class-transformer";
import { Document } from "./DocumentModel";

export class SaveMedia {
  // constructor() {}
  public productId?: string;
  public file?: string;
  public videoUrl?: string;
  public mediaType?: string;
  @Type(() => Document)
  public document?: Document;
  public unityPlatform?: string;
}
