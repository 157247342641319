import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { LoginResponse } from "../../apiServices/Models/Auth/LoginResponse";
import { HandleAuthToken } from "../../shared/core/Utils/HandleAuthToken";
import { VerifyOtp } from "../../apiServices/Models/Auth/VerifyOtpRequest";
import { plainToInstance } from "class-transformer";
import { LocalStorage } from "../../shared/core/Storage/LocalStorage";

const objAuthHandler = new HandleAuthToken();
const userDetailFromHandler = JSON.parse(objAuthHandler?.getItem() || "{}");
const objLocalStorage = new LocalStorage();
const lmsUrlFromHandler = JSON.parse(
  objLocalStorage.getItem("lmsData") || "{}"
).lmsUrl;
// console.log(objAuthHandler?.getItem());

export interface AuthState {
  user: LoginResponse | null;
  isLoggedIn: boolean;
  status: "idle" | "loading" | "failed";
  signUpUser: VerifyOtp | null;
  lmsLink: string | "";
}

const initialState: AuthState = {
  user: userDetailFromHandler?.access_token ? userDetailFromHandler : null,
  isLoggedIn: !!userDetailFromHandler?.access_token,
  status: "idle",
  signUpUser: null,
  lmsLink: lmsUrlFromHandler
    ? lmsUrlFromHandler
    : "https://learn.techxr.co/s/store",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (
      state: { isLoggedIn: boolean; user: any },
      action: { payload: any }
    ) => {
      state.isLoggedIn = true;
      // console.log(action.payload);
      state.user = JSON.parse(action.payload);
    },
    logout: (state: { isLoggedIn: boolean; user: any; lmsLink: string }) => {
      state.isLoggedIn = false;
      state.user = null;
      state.lmsLink = "https://learn.techxr.co/s/store";
    },
    signUp: (
      state: { signUpUser: VerifyOtp | null },
      action: { payload: any }
    ) => {
      state.signUpUser = JSON.parse(action.payload);
    },
    lms: (state: { lmsLink: string }, action: { payload: any }) => {
      // console.log(action.payload);
      state.lmsLink = JSON.parse(action.payload);
    },
  },
});

export const { login, logout, signUp, lms } = authSlice.actions;
export const selectUser = (state: RootState) =>
  plainToInstance(LoginResponse, state.auth.user);
export const isLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const signUpUser = (state: RootState) =>
  plainToInstance(VerifyOtp, state.auth.signUpUser);
export const lmsLink = (state: RootState) => state.auth.lmsLink;

export default authSlice.reducer;
