import * as React from "react";
import { EventPageAboveFold } from "../../../../../apiServices/Models/WebsiteContent/EventPageModels/EventPageAboveFoldResponse";
import EventBanner1 from "../../../../../assets/images/our-events-img/eventbanner.jpg";
const Calendar = require("../../../../../assets/images/our-events-img/Calendar.png");
const Time = require("../../../../../assets/images/our-events-img/Time.png");
const Zoom = require("../../../../../assets/images/our-events-img/zoombtn.jpg");

interface UpcomingEventCompProps {
  eventAboveFoldDetails: EventPageAboveFold;
}

const UpcomingEventComp: React.FunctionComponent<UpcomingEventCompProps> = (
  props
) => {
  const eventAboveFoldDetails = props.eventAboveFoldDetails;

  return (
    <div className="top_event_banner">
      <img
        src={
          eventAboveFoldDetails.abovefoldImageUrl
            ? eventAboveFoldDetails.abovefoldImageUrl
            : EventBanner1
        }
        alt=""
        className="top_main_banner"
      />
      <div className="top_baner_con">
        <div className="common-container">
          <div className="common-row">
            <div className="event-col common-sm-10">
              <h1>{eventAboveFoldDetails.name}</h1>

              <div className="event_date_time">
                <ul>
                  <li>
                    <div className="event-small-box">
                      <img src={Calendar} alt="" />
                      <span>{eventAboveFoldDetails.displayDate}</span>
                    </div>
                  </li>

                  <li>
                    <div className="event-small-box">
                      <img src={Time} alt="" />
                      <span>{eventAboveFoldDetails.time}</span>
                    </div>
                  </li>

                  <li>
                    <div className="event-small-box">
                      <div className="zoom_button_box">
                        at{" "}
                        <button>
                          <a href={eventAboveFoldDetails.zoomUrl}>
                            <img src={Zoom} alt="" />
                          </a>
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="future_button_box">
                <button className="register_btn_black">
                  <a href={eventAboveFoldDetails.futureButtonUrl}>
                    {eventAboveFoldDetails.futureButtonLabel}
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </a>
                </button>
                <button className="share_btn btn_white">
                  <i className="fa fa-share-alt" aria-hidden="true"></i> SHARE{" "}
                </button>
                <button className="reminder_btn btn_white">
                  <i className="fa fa-bell-o" aria-hidden="true"></i> SET
                  REMINDER
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomingEventComp;
