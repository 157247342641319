import * as React from "react";

export interface IThankYouRegistrationProps {}

export default function ThankYouRegistration(
  props: IThankYouRegistrationProps
) {
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      // 👇️ redirects to an external URL
      window.location.replace("/courses");
    }, 6000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="thankyouregister">
      <div className="innerthankyour">
        <div className="popboxtahnkyou">
          <div className="svg-container">
            <svg
              className="ft-green-tick"
              xmlns="http://www.w3.org/2000/svg"
              height="100"
              width="100"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <circle
                className="circle"
                fill="#5bb543"
                cx="24"
                cy="24"
                r="22"
              />
              <path
                className="tick"
                fill="none"
                stroke="#FFF"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                d="M14 27l5.917 4.917L34 17"
              />
            </svg>
          </div>
          <p>
            Thank you for registering with us. Our counsellors will get in touch
            with you shortly. Till then take a look at our courses.
          </p>
        </div>
      </div>
    </div>
  );
}
