import * as React from "react";

import { HomePageCoursesResponse } from "../../../../apiServices/Models/WebsiteContent/HomePageModels/HomePageCoursesResponse";
import { SingleCourseCardComp } from "../SingleCourseCard/SingleCourseCardComp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/bundle";

interface CoursesCardsCompProps {
  coursesList: HomePageCoursesResponse[];
  limit: number;
}

export const CoursesCardsComp: React.FunctionComponent<
  CoursesCardsCompProps
> = (props) => {
  const coursesList = props.coursesList;
  const limit = props.limit;
  return (
    <>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        loop={true}
        navigation={true}
        modules={[FreeMode, Pagination, Navigation, Autoplay]}
        className="courseSwipperSlider"
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
      >
        {coursesList.slice(0, limit).map((course, index) => {
          // console.log(`Index =${index} and ${course.id}`);

          return (
            <>
              <SwiperSlide>
                <SingleCourseCardComp course={course} key={course.id} />
              </SwiperSlide>
            </>
          );
        })}
      </Swiper>
    </>
  );
};
