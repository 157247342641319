/* eslint-disable jsx-a11y/iframe-has-title */
import * as React from "react";
import { CourseCardDetailResponse } from "../../../../apiServices/Models/WebsiteContent/CoursePageModels/CourseCardDetailResponse";
import { ContentServices } from "../../../../apiServices/Services/ContentServices";
import StudentSectionBG1 from "../../../../assets/images/student-section/bg1.png";

const contentService = new ContentServices();

interface CourseAboveFoldCardCompProps {
  courseId: string | undefined;
}

export const CourseAboveFoldCardComp: React.FunctionComponent<
  CourseAboveFoldCardCompProps
> = (props) => {
  const courseId = props.courseId;
  const [courseCardDetail, setCourseCardDetail] =
    React.useState<CourseCardDetailResponse>();

  // console.log(courseDetail.findIndex(course, 0));

  React.useEffect(() => {
    const getCourseCardDetail = (): CourseCardDetailResponse => {
      return contentService.getCourseCardDetail(courseId);
    };
    setCourseCardDetail(getCourseCardDetail());
  }, [courseId, setCourseCardDetail]);
  const getDiscountedPrice = () => {
    if (
      courseCardDetail?.discount !== undefined &&
      courseCardDetail?.discount > 0
    ) {
      return (
        <>
          <div className="course-full-fees">
            <span className="material-symbols-outlined2">currency_rupee</span>{" "}
            <div className="full-amount">{courseCardDetail?.price}</div>
          </div>
          <div className="course-fees-dis-per">
            {courseCardDetail?.discount}% OFF
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };
  return (
    // <!--course Right Section Start-->
    <div className="course-section-right">
      <div className="top-bg">
        <img src={StudentSectionBG1} alt="StudentSectionBG1" />
      </div>

      <div className="course-right-inner">
        {/* <!--course image Start--> */}

        <div className="course-img">
          {courseCardDetail?.videoLink !== undefined &&
          courseCardDetail?.videoLink !== "" ? (
            <>
              <iframe
                className="video__iframe"
                width="942"
                height="530"
                src={courseCardDetail?.videoLink}
              ></iframe>
            </>
          ) : (
            <>
              <img src={courseCardDetail?.imageUrl} alt="CourseImage" />
            </>
          )}
          {/* <img src={courseCardDetail?.imageUrl} alt="CourseImage" /> */}
        </div>

        {/* <!--course Image End--> */}

        {/* <!--course Fee Start--> */}

        <div className="course-fees">
          <div className="course-dis-fees">
            <span className="material-symbols-outlined1">currency_rupee</span>
            <div className="dis-amount">{courseCardDetail?.actualPrice}</div>
          </div>

          {getDiscountedPrice()}
        </div>

        {/* <!--course Fee End--> */}

        <div className="coures-right-bottom-border"></div>

        {/* <!--course last apply date start--> */}
        {courseCardDetail ? (
          courseCardDetail?.entryOpenTill !== "" ? (
            <div className="course-last-apply-date">
              <div className="course-last-date-txt">Entries Open Till</div>
              <div className="course-last-date-n">
                {courseCardDetail?.entryOpenTill}
              </div>
            </div>
          ) : (
            <div></div>
          )
        ) : (
          <div></div>
        )}

        {/* <!--course last apply date End--> */}

        {/* <!--course Enroll Button Satrt--> */}

        <a
          href={courseCardDetail?.enrollLink}
          className="course-enroll"
          target="_blank"
          rel="noreferrer"
        >
          <div className="course-enroll-btn-txt">Enroll Now</div>
        </a>
        {/* <!--course Enroll Button End--> */}

        {/* <!--course Watch Button Satrt--> */}

        <a href={courseCardDetail?.demoLink} className="watch-demo">
          <div className="watch-demo-btn-txt">Download Brochure</div>
        </a>
        {/* <!--course Watch Button End--> */}
      </div>

      {/* <!--course Right Section inner End--> */}
    </div>

    // <!--course Right Section End-->
  );
};
