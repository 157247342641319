import * as React from "react";
import SwiperCore, { EffectFade, FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/effect-fade";
import { Swiper, SwiperSlide } from "swiper/react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Servicecom from "../../../assets/images/sensexr/bullet.png";
import BlockEngineDownloadCompo from "./BlockEngineDownloadCompo";
import { Link } from "react-router-dom";

// const contentService = new ContentServices();

// const getxrblocksData = (): XrBlocksModels[] => {
//   return contentService.getAllxrBlocks();
// };

interface PropsBlockEngine {}

export const BlockEngine: React.FunctionComponent<PropsBlockEngine> = (
  props: any
) => {
  const [thumbsSwiper, setThumbsSwiper] = React.useState<SwiperCore>();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>TechXR - XR-blocks</title>
          <meta
            name="title"
            content="Augmented & Technologies SenseXR | TechXR"
          />
          <meta
            name="description"
            content=" To know about XR Controller and how it works. Explore its different types of hardware. We can use it in different areas like gaming, education, architecture and 3D modelling."
          />
        </Helmet>
      </HelmetProvider>
      <div className="sencexr-controler-main mx-w-1440">
        <div className="sencexr-controler-inner">
          <div className="xrblockbanner">
            <img
              src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/iB6LV9JvcbFxFjK2_2022-12-13T073453152699.Header (1).png"
              alt=""
              className="desktop-xrblockimg"
            />
            <img
              src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/SZy0CsMaHhDL7loU_2022-12-13T103404193836.Mobile.jpg"
              alt=" "
              className="mobile-xrblockimg"
            />
            <div className="flexible_btn">
              <a href="#downloadSection">download</a>
              <Link to="/xrblock-project" className="rightBtnengine">
                Start Now
              </Link>
            </div>
          </div>

          <div className="controller-bottom">
            <div className="controller_bg"></div>
          </div>
        </div>

        <div className="what_is_xr py-100">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home mb-50">
                  <h2>
                    <span>What is</span>
                    <br />
                    XR Blocks?
                  </h2>
                </div>
              </div>

              <div className="common-sm-12 common-col-7 common-md-8 d-flex-between">
                <div className="controller-contrnt-paragraph">
                  <p>
                    XR blocks is a completely{" "}
                    <span className="color-dark">free game engine</span> that
                    allows young developers to{" "}
                    <span className="color-dark">
                      create augmented reality and virtual reality 3D
                      masterpieces
                    </span>{" "}
                    such as Games, Story Tales, Models etc. and share them with
                    the world.
                  </p>
                </div>
              </div>
            </div>

            <div className="common-row">
              <div className="common-col-12">
                <div className="xr_bottom_heading">
                  <h2>
                    <span className="color-dark">Build on</span> Desktop...{" "}
                    <span className="color-dark">PLAY on</span> MOBILE !
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="xr-card-block  py-100 small_dark_bg">
          <div className="common-heading-home mb-50 ">
            <h2 className="text-center">
              <span>What is </span>XR Blocks used for?
            </h2>
          </div>
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="xr-card mb-20">
                  <div className="xr-card-header">
                    <h2>3D games</h2>
                  </div>
                  <div className="xr-card-body">
                    <div className="xr-card-img">
                      <img
                        src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/dqawf3Ppj5vY5Aqi_2022-12-12T123211939564.3D games.png"
                        alt=""
                      />
                    </div>
                    <p>
                      Create realistic games using 3D visualisation and empower
                      your innovative skills.
                    </p>
                  </div>
                </div>
              </div>

              <div className="common-sm-12 common-col-4">
                <div className="xr-card mb-20">
                  <div className="xr-card-header">
                    <h2>Story Tales</h2>
                  </div>
                  <div className="xr-card-body">
                    <div className="xr-card-img">
                      <img
                        src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/A0dUmuFvovnQSzQB_2022-12-12T123312854564.Story tales.png"
                        alt=""
                      />
                    </div>
                    <p>
                      Design and Present 3D stories with interesting characters
                      in your own unique style.
                    </p>
                  </div>
                </div>
              </div>

              <div className="common-sm-12 common-col-4">
                <div className="xr-card mb-20">
                  <div className="xr-card-header">
                    <h2>Fun Activities</h2>
                  </div>
                  <div className="xr-card-body">
                    <div className="xr-card-img">
                      <img
                        src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/hz5pvLM3pW8lNfrM_2022-12-12T123339290834.fun activities.png"
                        alt=""
                      />
                    </div>
                    <p>
                      Brainstorm and Explore your creativity to create engaging
                      fun activities
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="controller-section controller-white-bg py-100">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home mb-50">
                  <h2>
                    <span>Who can use</span>
                    <br />
                    XR Blocks?
                  </h2>
                </div>
              </div>
              <div className="common-sm-12 common-col-7 common-md-8">
                <div className="controller-flex-box">
                  <div className="controller-contrnt-paragraph">
                    <p>
                      <span className="color-dark">
                        Six Degrees of Freedom (6DoF){" "}
                      </span>
                      XR blocks can be{" "}
                      <span className="color-dark">
                        used by both programmers and non-programmers alike.
                      </span>{" "}
                      Anyone wishing to create simple AR/VR experiences should
                      be able to do so using XR Blocks. User can build{" "}
                      <span className="color-dark">
                        the environment from the provided model library.
                      </span>{" "}
                      User can also{" "}
                      <span className="color-dark">
                        add logic to the newly created environment by “Block
                        Coding”.
                      </span>{" "}
                      Logical Blocks can be attached to objects of one’s choice.
                      The ability to add logical blocks{" "}
                      <span className="color-dark">
                        enhances the analytical skill of the user
                      </span>
                      and introduces the user to core concepts of programming
                      and logical reasoning.
                    </p>
                    <p>
                      The application should be ready to use after adding logic
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="controller-section py-100">
          <div className="common-container">
            <div className="common-heading-home mb-50 ">
              <h2 className="text-center">
                <span>Experiences can be </span>viewed in 3 modes
              </h2>
            </div>

            <div className="common-row align-items challnge_with_blocks mt-50">
              <div className="common-col-4 common-sm-12">
                <div className="challnge_with block_engine_img">
                  <div className="img-challange">
                    <img
                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/3unluHdsUs6ajs0Q_2022-12-12T123512701289.AR.png"
                      alt=""
                    />
                  </div>
                  <div className="challange-content">
                    <h3>
                      <img src={Servicecom} alt="" /> AR MODE
                    </h3>
                    <p>
                      Augmented reality (AR) mode uses your device to blend
                      digital content into the real world
                    </p>
                  </div>
                </div>
              </div>

              <div className="common-col-4 common-sm-12">
                <div className="challnge_with block_engine_img">
                  <div className="img-challange">
                    <img
                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/1X2xvNUNqIhL83Fx_2022-12-12T123621548932.VR.png"
                      alt=""
                    />
                  </div>
                  <div className="challange-content">
                    <h3>
                      <img src={Servicecom} alt="" /> VR MODE
                    </h3>
                    <p>
                      Virtual reality (VR) mode allows user to have full 3D
                      experience when viewed through a VR Headset.
                    </p>
                  </div>
                </div>
              </div>

              <div className="common-col-4 common-sm-12">
                <div className="challnge_with block_engine_img">
                  <div className="img-challange">
                    <img
                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/Jz5CKkSM6dzXhvmi_2022-12-12T123650394107.Anagylph.png"
                      alt=""
                    />
                  </div>
                  <div className="challange-content">
                    <h3>
                      <img src={Servicecom} alt="" />
                      ANAGLYPH MODE
                    </h3>
                    <p>
                      Anaglyph mode allows users to have a 3D effect when viewed
                      through two-color anaglyph glasses
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="controller-sixdef-section  controller-white-bg  py-100 pb-0">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home">
                  <h2>
                    <span>How to use</span>
                    <br />
                    XR Blocks?
                  </h2>
                </div>
              </div>

              <div className="common-sm-12 common-col-7 common-md-8 d-flex-between">
                <div className="controller-contrnt-paragraph">
                  <p>
                    Simple AR experiences and games are absolutely easy to make.
                    Learn how to build and play your first project in few simple
                    steps below!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="controller-sixdef-section  controller-white-bg pb-0 py-100">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-12 text-center">
                <div className="common-heading-home mb-50">
                  <h2 className="text-center">
                    <span>How to build</span> your game
                  </h2>
                </div>
              </div>
            </div>

            <div className="common-row">
              <div className="common-sm-12 common-col-12">
                <div className="main_controller_image">
                  <img
                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/H53sbTdryBphmCgy_2022-12-13T052348220621.How to build.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="controller-sixdef-section  controller-white-bg  py-100">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-12 text-center">
                <div className="common-heading-home mb-50">
                  <h2 className="text-center">
                    <span>How to Play</span> your game
                  </h2>
                </div>
              </div>
            </div>

            <div className="common-row">
              <div className="common-sm-12 common-col-12">
                <div className="main_controller_image">
                  <img
                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/pKXGJqgmjGPE9HSE_2022-12-12T124532811062.How to play.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="what_is_xr py-100">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home mb-50">
                  <h2>
                    <span>Basic Components</span>
                    <br />
                    of XR Blocks
                  </h2>
                </div>
              </div>

              <div className="common-sm-12 common-col-7 common-md-8 d-flex-between">
                <div className="controller-contrnt-paragraph">
                  <p>
                    XR Blocks includes more than{" "}
                    <span className="color-dark">70+ logical blocks,</span> a{" "}
                    <span className="color-dark">library of 1200+ assets</span>{" "}
                    divided into convenient categories, ability to save and
                    share projects with the public and ability to view the
                    project{" "}
                    <span className="color-dark">
                      in AR/ VR/ Anaglyph mode.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="what_is_xr py-100 controller-white-bg ">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home">
                  <h2>
                    <span>Takeaways from</span>
                    <br />
                    XR Blocks
                  </h2>
                </div>
              </div>

              <div className="common-sm-12 common-col-7 common-md-8 d-flex-between">
                <div className="controller-contrnt-paragraph">
                  <p>
                    In a safe and enjoyable learning environment, XR Blocks is a{" "}
                    <span className="color-dark">fun,</span> engaging app that{" "}
                    <span className="color-dark">
                      teaches users basic block coding, critical thinking,
                      application of logic{" "}
                    </span>{" "}
                    and <span className="color-dark">creativity.</span>
                  </p>
                  <p>
                    Do you like to just play instead of building projects? No
                    issues! Play the exciting games which are published by other
                    users like you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="slider_xrblock  py-100">
          <div className="common-container">
            <div className="common-row align-items ">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home">
                  <h2>
                    <span>XR Blocks</span>
                    <br />
                    Gallery
                  </h2>
                </div>
              </div>

              <div className="common-sm-12 common-col-7 common-md-8 d-flex-between">
                <div className="blockengine_slider">
                  <div className="slider-section">
                    <Swiper
                      loop={true}
                      spaceBetween={10}
                      navigation={true}
                      thumbs={{
                        swiper:
                          thumbsSwiper && !thumbsSwiper.destroyed
                            ? thumbsSwiper
                            : null,
                      }}
                      effect={"fade"}
                      fadeEffect={{ crossFade: true }}
                      modules={[FreeMode, Navigation, Thumbs, EffectFade]}
                      className="mySwiper2"
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                        },
                        640: {
                          slidesPerView: 1,
                        },
                        768: {
                          slidesPerView: 1,
                        },
                        1024: {
                          slidesPerView: 1,
                        },
                      }}
                    >
                      <SwiperSlide>
                        <div className="xr_imgblock">
                          <img
                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/g9sYyI1plDGhr2aC_2022-12-13T073832163474.Gallery_Image_01.png"
                            alt=""
                          />
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="xr_imgblock">
                          <img
                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/SnH9ptKhJCKA1U02_2022-12-13T074824267723.Gallery_Image_02.png"
                            alt=""
                          />
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="xr_imgblock">
                          <img
                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/5oIKzl6ZWJAE7w5i_2022-12-13T074856525020.Gallery_Image_03.png"
                            alt=""
                          />
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="xr_imgblock">
                          <img
                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/42knLIED1tU5yTM9_2022-12-13T074930311816.Gallery_Image_04.png"
                            alt=""
                          />
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="video_section_blockxr">
                          <iframe
                            width="942"
                            height="530"
                            src="https://www.youtube.com/embed/3bzcVaXNJ0o"
                            title="XR Blocks"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <Swiper
                      style={{ width: "100%" }}
                      onSwiper={setThumbsSwiper}
                      spaceBetween={0}
                      slidesPerView={5}
                      freeMode={true}
                      watchSlidesProgress={true}
                      modules={[Thumbs]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <div className="xr_imgblock">
                          <img
                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/g9sYyI1plDGhr2aC_2022-12-13T073832163474.Gallery_Image_01.png"
                            alt=""
                          />
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="xr_imgblock">
                          <img
                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/SnH9ptKhJCKA1U02_2022-12-13T074824267723.Gallery_Image_02.png"
                            alt=""
                          />
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="xr_imgblock">
                          <img
                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/5oIKzl6ZWJAE7w5i_2022-12-13T074856525020.Gallery_Image_03.png"
                            alt=""
                          />
                        </div>
                      </SwiperSlide>

                      <SwiperSlide>
                        <div className="xr_imgblock">
                          <img
                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/42knLIED1tU5yTM9_2022-12-13T074930311816.Gallery_Image_04.png"
                            alt=""
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="xr_imgblock">
                          <img
                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/TqeqTsLxdGS0wpxm_2022-12-07T123142947163.video.jpg"
                            alt=""
                          />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="controller-section py-100 d-none">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home mb-50">
                  <h2>
                    <span>XR Blocks</span> <br />
                    Tutorials
                  </h2>
                </div>
              </div>
              <div className="common-sm-12 common-col-7 common-md-8 d-flex-between">
                <div className="controller-contrnt-paragraph">
                  <p>
                    To get a feel of interactive VR with SenseXR the user may
                    try TechXR popular applications in gaming, and simulation
                    such as :
                  </p>
                  <div className="application_store_gallery">
                    <ul>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BlockEngineDownloadCompo />

        <div className="controller-section py-100">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home mb-50">
                  <h2>
                    <span>XR Blocks</span> <br />
                    FAQs
                  </h2>
                </div>

                <div className="faq-xrblock-img">
                  <img
                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/oqbeBD8SCn5YsNaq_2022-12-13T065146638042.FAQ.png"
                    alt=""
                  />
                </div>
              </div>

              <div className="common-sm-12 common-col-7 common-md-8">
                <div className="xrfaq-section">
                  <div className="collapse_main">
                    <input type="checkbox" />
                    <div className="collapse__title">
                      <p>What is the age limit for using XR Blocks?</p>
                    </div>

                    <div className="collapse_body">
                      <div className="collapse_body_inner">
                        <p>
                          XR Blocks is specially designed for young brains of
                          ages 6-19. But, there is no age limit for using XR
                          Blocks. Anyone who wishes to explore 3D creativity is
                          open to use.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="collapse_main">
                    <input type="checkbox" />
                    <div className="collapse__title">
                      <p>How to learn using XR Blocks?</p>
                    </div>

                    <div className="collapse_body">
                      <div className="collapse_body_inner">
                        <p>
                          You can learn using XR Blocks using the tutorial
                          videos provided here. You can also refer to the User
                          Guide to XR Blocks from{" "}
                          <a href="https://www.youtube.com/playlist?list=PL0WX1zULAhD_uh_u6LXzUsHF6FFa2BBhy">
                            Link here.{" "}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="collapse_main">
                    <input type="checkbox" />
                    <div className="collapse__title">
                      <p>
                        Is it possible to create Augmented Reality Experiences
                        using XR Blocks?
                      </p>
                    </div>

                    <div className="collapse_body">
                      <div className="collapse_body_inner">
                        <p>
                          Yes, it is possible to create exclusive AR
                          experiences. All you need is a SenseXR to experience
                          your creation in AR.{" "}
                          <a href="https://www.youtube.com/watch?v=EvnLrXKIuCY">
                            Click here to check how to create!
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="collapse_main">
                    <input type="checkbox" />
                    <div className="collapse__title">
                      <p>
                        Is it possible to create Virtual Reality Experiences
                        using XR Blocks?
                      </p>
                    </div>

                    <div className="collapse_body">
                      <div className="collapse_body_inner">
                        <p>
                          Yes, it is possible! The feature will be released soon
                          for user experience.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="collapse_main">
                    <input type="checkbox" />
                    <div className="collapse__title">
                      <p>How to Create 3D games using XR Blocks?</p>
                    </div>

                    <div className="collapse_body">
                      <div className="collapse_body_inner">
                        <p>
                          There are two primary steps - (1) Build a scene, (2)
                          Add intelligence to the objects in the scene. To learn
                          the details check out the tutorial videos and User
                          Guide to explore your 3D creativity.{" "}
                          <a href="https://www.youtube.com/playlist?list=PL0WX1zULAhD_uh_u6LXzUsHF6FFa2BBhy">
                            Click Here
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="collapse_main">
                    <input type="checkbox" />
                    <div className="collapse__title">
                      <p>How to Create an AR experience using XR Blocks?</p>
                    </div>

                    <div className="collapse_body">
                      <div className="collapse_body_inner">
                        <p>
                          Select AR mode in the build scene top menu. AR mode
                          requires building the scene in a ‘Cube’ or a ‘Sphere’.
                          For more information, check this{" "}
                          <a href="https://www.youtube.com/watch?v=EvnLrXKIuCY">
                            video
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="collapse_main">
                    <input type="checkbox" />
                    <div className="collapse__title">
                      <p>Is there a web version available for XR Blocks?</p>
                    </div>

                    <div className="collapse_body">
                      <div className="collapse_body_inner">
                        <p>
                          Specific desktop versions provide better experience in
                          game building. However, the web version of XR Blocks
                          is also in the pipeline, where users can create
                          experiences online.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="collapse_main">
                    <input type="checkbox" />
                    <div className="collapse__title">
                      <p>Is SenseXR a must for using XR Blocks?</p>
                    </div>

                    <div className="collapse_body">
                      <div className="collapse_body_inner">
                        <p>
                          SenseXR is used for experiencing AR out of 3D
                          creations. XR Blocks can be used to create 3D content
                          without SenseXR too.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="controller-section py-100 d-none ">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home mb-50">
                  <h2>
                    <span>SenseXR </span>also provides AR experience
                  </h2>
                </div>
              </div>
              <div className="common-sm-12 common-col-7 common-md-8 d-flex-between">
                <div className="controller-contrnt-paragraph">
                  <p>
                    SenseXR C ontroller, along with a compatible headset, allows
                    user to explore both AR and VR experiences. As a matter of
                    fact, the following AR application are already available on
                    Play Store and App Store to try your hands. _______.
                  </p>
                  <div className="application_store_gallery">
                    <ul>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="controller-section controller-white-bg py-100 d-none ">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home mb-50">
                  <h2>
                    <span>SenseXR </span>Gallery
                  </h2>
                </div>
              </div>
              <div className="common-sm-12 common-col-7 common-md-8 d-flex-between">
                <div className="controller-contrnt-paragraph">
                  <div className="application_store_gallery none_border_radies">
                    <ul>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
