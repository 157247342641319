import {Phone} from "./PhoneModel";

export class Profile {
  constructor() {
    this.firstName = "";
    this.lastName = "";
    this.email = "";
    this.address = "";
    this.password = "";
    this.image = "";
    this.roles = []; 
}
  public id?: number = -1;
  public firstName: string = "";
  public lastName: string;
  public email: string;
  public phone: Phone = new Phone();
  public address?: string;
  public password?: string;
  public image?: string = "";
  public active?: boolean = false;
  public roles: string[];
  // createdAt: Date = new Date();
}
