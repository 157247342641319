import * as React from "react";
import ControllerImage from "../../../assets/images/sensexr/trimcontroller.png";
import Bgimage from "../../../assets/images/auth-pages-background/bg_png.png";
import Bgimage2 from "../../../assets/images/auth-pages-background/bg_png2.png";
import { Helmet, HelmetProvider } from "react-helmet-async";

interface CampusAmbassadorProps {}

const CampusAmbassador: React.FunctionComponent<CampusAmbassadorProps> = (
  props
) => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Brand Ambassador Program | TechXR</title>
          <meta name="title" content="Brand Ambassador Program | TechXR" />
          <meta
            name="description"
            content="Be a part of our brand ambassador and know more about the perks and benefits, roles and responsibilities. Visit our website read our program details and much more."
          />
          <meta
            property="image"
            content="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/uD41orAQL8hE2zva_2023-05-30T064448436786.Img web.png"
          />
        </Helmet>
      </HelmetProvider>

      <div className="main-section mx-w-1440">
        <div className="Privacy-main py-100">
          <div className="common-container">
            {/* <Bgimage/> */}
            <img src={Bgimage} alt="" className="bg-overlay" />
            <img src={Bgimage2} alt="" className="bg-overlay second" />
            <div className="Privacy-row">
              <div className="common-heading privacy-heading d-flex-between brandbox-main">
                <h1 className="mb-10">Brand Ambassador Program</h1>
                {/* <h4>This Brand Ambassador applies to the techxr.co</h4> */}

                <div className="privacy-btn mobile-view">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfWsvbxWis8nT1hLQTy8PwbyTrx2-x1bBk7EFAnBw_w5ABg8g/viewform?vc=0&c=0&w=1&flr=0"
                    target="blank"
                  >
                    Apply Now
                  </a>
                </div>
                <div className="img-brand">
                  <img src={ControllerImage} alt="" />
                </div>
              </div>

              <div className="privacy-content-block ">
                <div className="content-boox ">
                  <h2>
                    <strong>
                      <i>Perks & Benefits:</i>
                    </strong>
                  </h2>
                  <ul className="refund_block">
                    <li>
                      <strong>Start-up exposure:</strong> Be the face of Tech XR
                      Innovations, one of the first AR/VR Edu-tech companies in
                      India
                    </li>
                    <li>
                      <strong>Learning and Development:</strong> Learn about
                      MetaVerse, AR/VR, new technology in AR/VR, Free courses,
                      weekly competitions, peer learning all at one place.{" "}
                    </li>
                    <li>
                      <strong>Get XR Experience Kit:</strong> Top performers
                      every month get an AR/VR Headset and a TechXR patented 6
                      DoF XR Controller.
                    </li>
                    <li>
                      <strong>Win Rewards & Recognition:</strong> Get Monetary
                      Benefits, win amazon vouchers & cool swags/merchandise,
                      Get social media shoutouts.
                    </li>
                    <li>
                      <strong>Develop Leadership Skills:</strong> Host
                      online/offline events, offer guidance to batch-mates, earn
                      recognition, stand out from the pack
                    </li>
                    <li>
                      <strong>Guidance and Mentorship:</strong> Interact with
                      top faculty and industry stalwarts at TechXR
                    </li>
                    <li>
                      <strong>
                        Get Certificates / Personalized LinkedIn
                        Recommendations:
                      </strong>{" "}
                      On successful completion of the program
                    </li>
                    <li>
                      <strong>Social Media Internship Opportunity:</strong> Be a
                      part of our social media team, get special certificates &
                      develop your communication skills
                    </li>
                    <li>
                      <strong>Earn Money:</strong> Choose your own Promo Code,
                      refer your friends, batchmates, and earn money for each
                      enrollment done with your Promo Code.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="privacy-content-block">
                <h2>
                  <strong>
                    <i>Roles & Responsibilities:</i>
                  </strong>
                </h2>

                <ul className="refund_block">
                  <li>
                    A Brand Ambassador is responsible for spreading awareness
                    about AR/VR Technology and TechXR’s Offerings within the
                    campus.{" "}
                  </li>
                  <li>
                    You have to promote TechXR’s events, courses, etc., and help
                    your peers by various means such as conducting workshops &
                    seminars, social media sharing, Whatsapp groups etc.
                  </li>
                  <li>
                    Introduce our Course/Curriculum and product to students.
                  </li>
                  <li>
                    Participate in cool tasks each week and win amazing rewards.
                  </li>
                  <li>
                    Be the face of TechXR Innovations in and be a single point
                    of contact for all student queries around AR/VR and TechXR
                    Innovations in your campus.
                  </li>
                  <li>
                    Coordinate with college management and professors to get MoU
                    for free course offerings.
                  </li>
                </ul>
              </div>

              <div className="privacy-content-block">
                <h2>
                  <strong>
                    <i>Application and Program Details:</i>
                  </strong>
                </h2>
                <ul className="refund_block">
                  {/* <li>
                    You can register directly on our Website by going to this
                    link: techxr.co/brandambasaddor
                  </li> */}
                  <li>
                    Click on the Apply Now button, fill up some basic details
                    and answer some basic questions relevant to the program.
                    Also, you have to upload your Resume here and then click on
                    the ‘Submit’ button.
                  </li>
                  <li>
                    Make sure all the details entered by you are correct
                    otherwise your form may be rejected.
                  </li>
                  <li>
                    Our team will review your details, and if your application
                    stands out, we will email/call you for an interview.
                  </li>
                  <li>
                    The Brand Ambassador program is for 4 Weeks. After 4 Weeks,
                    only the Top 10% will be offered an extension.
                  </li>
                </ul>
              </div>

              <div className="privacy-btn">
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfWsvbxWis8nT1hLQTy8PwbyTrx2-x1bBk7EFAnBw_w5ABg8g/viewform?vc=0&c=0&w=1&flr=0"
                  target="blank"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampusAmbassador;
