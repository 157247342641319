import { Observable } from "rxjs";
//import { downloadLatestRelease } from "../models/javaproducts/downloadLatestRelease";
import { ProductSummary } from "../Models/Products/ProductSummaryModel";
import { SaveUpdateProduct } from "../Models/Products/SaveUpdateProduct";
import { SaveMedia } from "../Models/Products/SaveMediaRequestModel";
import { UploadPackageResponse } from "../Models/Products/UploadPackageModel";
import { NetworkService } from "./NetworkServices";
import { WebServiceConstant } from "./WebServiceConstants";
import { DeveloperProfileResponse } from "../Models/DeveloperProfile/DeveloperProfileResponse";
import { ProductRelease } from "../Models/Products/ProductReleaseModel";
import { DeveloperIdResponse } from "../Models/DeveloperProfile/DeveloperIdResponse";
import { ProductMedia } from "../Models/Products/ProductMediaModel";
import { PlayControls } from "../Models/Products/PlayControlsRequestModel";
import { PlayControlsResponse } from "../Models/Products/PlayControlsResponseModel";
import { Lms } from "../Models/Auth/LmsRequest";
import { ProductPackage } from "../Models/Products/ProductPackageModel";

export class ProductService {
  public readonly networkService: NetworkService;

  constructor() {
    this.networkService = new NetworkService();
  }

  public getAllProducts(): Observable<ProductSummary> {
    return this.networkService.getRequest(
      WebServiceConstant.PRODUCT_GET_ALL,
      {},
      {},
      ProductSummary
    );
  }

// developerid myprojects
  public getProductsForDeveloper(developerId: number): Observable<ProductSummary> {
    return this.networkService.getRequest(
      WebServiceConstant.PRODUCT_GET_BY_DEVELOPER + developerId,
      {},
      {},
      ProductSummary
    );
  }
// developerid myprojects


  public productById(product_id: string): Observable<ProductSummary> {
    return this.networkService.getRequest(
      WebServiceConstant.PRODUCT_GET_BY_ID + product_id,
      {},
      {},
      ProductSummary
    );
  }
  public productSaveUpdate(
    data: SaveUpdateProduct
  ): Observable<ProductSummary> {
    return this.networkService.postNewRequest(
      WebServiceConstant.PRODUCT_SAVE_UPDATE,
      data,
      {},
      {},
      ProductSummary
    );
  }

  public uploadPackage(data: {}): Observable<UploadPackageResponse> {
    return this.networkService.postNewRequest(
      WebServiceConstant.PRODUCT_PACKAGE,
      data,
      {},
      {},
      UploadPackageResponse
    );
  }

  public saveImage(
    data: SaveMedia,
    header: string,
    params: any
  ): Observable<ProductMedia> {
    // console.log(data);
    // console.log(header);
    // console.log(params);
    return this.networkService.postNewRequest(
      WebServiceConstant.PRODUCT_SAVE_IMAGE,
      data,
      header,
      params,
      ProductMedia
    );
  }
  public saveVideo(data: SaveMedia): Observable<ProductMedia> {
    // console.log(data);
    return this.networkService.postNewRequest(
      WebServiceConstant.PRODUCT_SAVE_VIDEO,
      data,
      {},
      {},
      ProductMedia
    );
  }


  public deleteMedia(params: any): Observable<ProductMedia> {
    // console.log(params);
    return this.networkService.deleteRequest(
      WebServiceConstant.PRODUCT_DELETE_MEDIA,
      {},
      params,
      ProductMedia
    );
  }


// deletepackage
public deletePackage(productPackageId: string): Observable<ProductPackage> {
  // console.log("package params = " + params);
  const p = {
    "productPackageId": productPackageId
  }
  return this.networkService.deleteRequest(
    WebServiceConstant.PRODUCT_DELETE_PACKAGE,
    {},
    p,
    ProductPackage
  );
}
// deletepackage

// deleteProduct
public deleteProduct(productId: string): Observable<ProductSummary> {
  // console.log("package params = " + params);
  const p = {
    "productId": productId
  }
  return this.networkService.deleteRequest(
    WebServiceConstant.PRODUCT_DELETE,
    {},
    p,
    ProductSummary
  );
}
// deleteProduct







  public saveBuild(
    data: SaveMedia,
    header: string,
    params: any
  ): Observable<ProductRelease> {
    // console.log(data);
    // console.log(header);
    // console.log(params);
    return this.networkService.postNewRequest(
      WebServiceConstant.PRODUCT_SAVE_BUILD,
      data,
      header,
      params,
      ProductRelease
    );
  }
  public savePlayControls(
    data: PlayControlsResponse,
    params: any
  ): Observable<PlayControlsResponse> {
    console.log(data);
    return this.networkService.postNewRequest(
      WebServiceConstant.PRODUCT_SAVE_PLAY_CONTROLS,
      data,
      {},
      params,
      PlayControlsResponse
    );
  }
  public downloadLatestRelease(params: {}): Observable<ProductRelease> {
    return this.networkService.getRequest(
      WebServiceConstant.PRODUCT_DOWNLOAD_LATEST_RELEASE,
      {},
      params,
      ProductRelease
    );
  }
  public developerAllProducts(developerId: number): Observable<ProductSummary> {
    return this.networkService.getRequest(
      WebServiceConstant.PRODUCT_DEVELOPER + developerId,
      {},
      {},
      ProductSummary
    );
  }
  public getDeveloperProfile(params: {}): Observable<DeveloperProfileResponse> {
    return this.networkService.getRequest(
      WebServiceConstant.DEVELOPER_PROFILE,
      {},
      params,
      DeveloperProfileResponse
    );
  }

  public getDeveloperId(profileId: number): Observable<DeveloperIdResponse> {
    return this.networkService.postNewRequest(
      WebServiceConstant.DEVELOPER_PROFILE + "?profileId=" + profileId,
      {},
      {},
      {},
      DeveloperIdResponse
    );
  }


  public markAsdefault(id: string): Observable<ProductMedia> {
    // console.log("thumb id ="+ JSON.stringify(id));
    return this.networkService.postNewRequest(
      WebServiceConstant.PRODUCT_CHANGE_THUMB + "?productMediaId=" + id,
      {},
      {},
      {},
      ProductMedia
    );
}
  //Not required since getDeveloperById API also created developer if no dev found for user profile.
  // public createDeveloper(data: Lms): Observable<DeveloperIdResponse> {
  //   return this.networkService.postNewRequest(
  //     WebServiceConstant.DEVELOPER_ID,
  //     data,
  //     {},
  //     {},
  //     DeveloperIdResponse
  //   );
  // }
}
