/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Dofimage from "../../../assets/images/sensexr/controllericon3.png";
import Dofimagetwo from "../../../assets/images/sensexr/controllericon2.png";
import Dofimagethree from "../../../assets/images/sensexr/controllericon.png";
import Dofplus from "../../../assets/images/sensexr/plus.png";
import Dofequal from "../../../assets/images/sensexr/equal.png";
import Applications from "../../../assets/images/sensexr/NEWApplications.png";
import Oculus from "../../../assets/images/sensexr/OculusRift.png";
import Oculustwo from "../../../assets/images/sensexr/OculusQuest2.png";
import Oculusthree from "../../../assets/images/sensexr/HTCViveCosmo(Tethered).png";
import Oculusfour from "../../../assets/images/sensexr/OculusRift2Tethered.png";
import Oculusfive from "../../../assets/images/sensexr/HTCVivefocus3.png";
import Oculussix from "../../../assets/images/sensexr/SonyPSVR.png";
import Oculusseven from "../../../assets/images/sensexr/VarjoVR.png";
import OculusBg from "../../../assets/images/sensexr/cardbg.png";
import Service from "../../../assets/images/sensexr/Expensive.png";
import Servicetwo from "../../../assets/images/sensexr/LackofApp.png";
import Servicethree from "../../../assets/images/sensexr/lackofdev.png";
import Servicecom from "../../../assets/images/sensexr/bullet.png";
import VRSolving from "../../../assets/images/sensexr/VRSolving.png";
import vrcontroller from "../../../assets/images/sensexr/vrcontroller.png";
import vrcontrollertwo from "../../../assets/images/sensexr/controller2.png";
import HowDoes from "../../../assets/images/sensexr/1a.png";
import HowDoesSmall from "../../../assets/images/sensexr/1.png";
import HowDoestwo from "../../../assets/images/sensexr/2a.png";
import HowDoestwoSmall from "../../../assets/images/sensexr/2.png";
import HowDoesthree from "../../../assets/images/sensexr/3a.png";
import HowDoesthreeSmall from "../../../assets/images/sensexr/3.png";
import HowDoesfour from "../../../assets/images/sensexr/4a.png";
import HowDoesfourSmall from "../../../assets/images/sensexr/4.png";
import HowDoesInstruction from "../../../assets/images/sensexr/instruction.png";
import Front from "../../../assets/images/sensexr/Front.png";
import Back from "../../../assets/images/sensexr/Back.png";
import FronBg from "../../../assets/images/sensexr/Bakcground circle2.png";
import FronBgtwo from "../../../assets/images/sensexr/Background circle1.png";
import Learning from "../../../assets/images/sensexr/SCI.png";
import LearningTwo from "../../../assets/images/sensexr/ShootG.png";
import LearningThree from "../../../assets/images/sensexr/Industry.png";
import Learningfour from "../../../assets/images/sensexr/LearningsVenn.png";
import Card from "../../../assets/images/sensexr/ExploreCourses.png";
import CardTwo from "../../../assets/images/sensexr/ExploreProducts.png";

import ParticlesCompo from "./Particles";
import { Helmet, HelmetProvider } from "react-helmet-async";

interface SenseXrProps {}
const myVar2 = require("../../../assets/videos/finalvideo.mp4");
const SenseXr: React.FunctionComponent<SenseXrProps> = (props: any) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [muted, setMuted] = React.useState(true);
  const handleToggleMute = () => setMuted((current) => !current);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>TechXR - SenseXR</title>
          <meta
            name="title"
            content="Augmented & Technologies SenseXR | TechXR"
          />
          <meta
            name="description"
            content=" To know about XR Controller and how it works. Explore its different types of hardware. We can use it in different areas like gaming, education, architecture and 3D modelling."
          />
          <meta
            property="image"
            content="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/kOnqaSv3LVIlG6UP_2023-05-30T064619594352.controller2.1709bea11fb0a9825029.png"
          />
        </Helmet>
      </HelmetProvider>
      <div className="sencexr-controler-main mx-w-1440">
        <div className="sencexr-controler-inner">
          <div className="controller-banner">
            {/* <div className="techxrtext">
                <span>XR controller for Smartphone</span>
                <h3 className="fade-in"> SenseXR</h3>
                <h4 className="text-stroke">Controller</h4>
              </div>
              <div className="bgvideo">
                <video autoPlay muted loop id="myVideo">
                  <source src={myVar2} />
                </video>
              </div> */}

            <div className="banner-block-main">
              <div className="controller__video p-relative">
                <ParticlesCompo />
                <video autoPlay muted={muted} loop id="myVideo">
                  <source src={myVar2} />
                </video>
                <div className="common-container">
                  <button onClick={handleToggleMute} className="controlButton">
                    <img
                      src={
                        muted
                          ? "https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/LrrCUNDJQlcnR6nB_2023-02-06T124848044546.Mute icon1.png"
                          : "https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/1wdESz3mLAvtnNC3_2023-02-06T124818065124.Unmute icon1.png"
                      }
                      alt=""
                    />
                  </button>
                </div>
              </div>
              <div className="common-container">
                <div className="common-row banner-relative d-flex align-items">
                  <div className="common-col-12 p-relative common-sm-12">
                    {/* <ParticlesCompo />
                    <div className="controller-main-image">
                      <img src={ControllerImage} alt="" />
                    </div> */}
                  </div>
                  {/* <div className="common-col-6 common-sm-12">
                    <div className="controller-banner-content">
                      <div className="techxrtext">
                        <h3 className="fade-in"> SenseXR</h3>
                        <h4 className="text-stroke">Controller</h4>
                        <div className="button-block-book">
                          <a href="https://forms.gle/vKQ8xS7fKTVr26weA">
                            Pre-Order
                          </a>
                        </div>
                        <p>
                          SenseXR Controller is a hardware accessory that allows
                          users to interact in{" "}
                          <strong>Augmented & Virtual Reality Space.</strong>{" "}
                          SenseXR Controller is a patented 6 DoF XR Controller,
                          designed by{" "}
                          <strong>TechXR Innovations Private Limited.</strong>
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="controller-bottom">
              <div className="controller_bg"></div>
            </div>
          </div>
        </div>

        <div className="what_is_xr py-100">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home mb-50">
                  <h2>
                    <span>What is</span>
                    <br />
                    XR Controller?
                  </h2>
                </div>
              </div>

              <div className="common-sm-12 common-col-7 d-flex-between">
                <div className="controller-contrnt-paragraph">
                  <p>
                    <span className="color-dark">XR Controller</span> is a hand
                    held accessory, which coupled with a VR Head mounted device,
                    <span className="color-dark">
                      allows user to interact with the Virtual reality
                      environment{" "}
                    </span>
                    experienced by the user. Prior to XR controllers, the VR
                    experiences used to be non-interactive and view only. With
                    the advent of such controllers, the user experience is
                    greatly enriched.
                  </p>
                  <p>
                    XR controllers, come with varied degree of Interactive
                    freedom. Earlier controllers, such as Google PID Controller,
                    used to provide only 2 Degree of Freedom. The most advances
                    XR-controllers available in this decade provide 6 Degree of
                    Freedom (6 DoF). Examples include Oculus, HTC Vive, TechXR
                    Sense.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Dof_movement_section controller-white-bg py-100">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home mb-50">
                  <h2>
                    <span>What is</span>
                    <br />6 DoF <br />
                    Movement?
                  </h2>
                </div>
              </div>
              <div className="common-sm-12 common-col-7">
                <div className="controller-flex-box">
                  <div className="controller-contrnt-paragraph">
                    <p>
                      <span className="color-dark">
                        Six Degrees of Freedom (6DoF){" "}
                      </span>
                      refers to the freedom of movement of a rigid body in
                      three-dimensional space. A 6 degree of freedom controller
                      has the ability of tracking rotational and translational
                      movements of the controller. In short, with 6DoF
                      controller, one would be able to play even a sword-fight
                      game in VR through free hand movement. In other words, a
                      6DoF controller can serve as a 3D mouse, fully operable
                      through free hand movement in the VR space.
                    </p>
                    <p>
                      More technically, if a
                      <span className="color-dark">
                        {" "}
                        controller's translational movement (3 Degrees of
                        Freedom) as well as rotational movement (along the three
                        axes) can be tracked{" "}
                      </span>
                      by the head mounted device, then that controller can be
                      said to be a 6DoF controller.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="common-row align-items">
              <div className="common-col-4 common-sm-12 ">
                <div className="controller-img_col">
                  <img src={Dofimage} alt="" />
                  <span className="img-content">3DoF</span>
                </div>
              </div>
              <div className="common-col-4 common-sm-12 d-flex-center-center">
                <img src={Dofplus} alt="" className="extra-img" />
                <div className="controller-img_col">
                  <img src={Dofimagetwo} alt="" />
                  <span className="img-content">3DoF</span>
                </div>
                <img src={Dofequal} alt="" className="extra-img" />
              </div>
              <div className="common-col-4 common-sm-12">
                <div className="controller-img_col">
                  <img src={Dofimagethree} alt="" />
                  <span className="img-content position-absolute">6DoF</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="controller-sixdef-section py-100">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-12">
                <div className="main_controller_image">
                  <img src={Applications} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Controller_Hardwares controller-white-bg py-100 pb-0">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home mb-50">
                  <h2>
                    <span>Types of</span>
                    <br />
                    XR Controller Hardwares
                    <br />
                  </h2>
                </div>
              </div>

              <div className="common-sm-12 common-col-7">
                <div className="controller-flex-box">
                  <div className="controller-contrnt-paragraph">
                    <p>
                      <span className="color-dark">XR Hardwares </span>is a
                      worldwide way to produce
                      <span className="color-dark">
                        {" "}
                        a realistic 3D view of a virtual world{" "}
                      </span>
                      is to place a display monitor in front of eyes.Such a
                      system is called a Binocular Head-mounted exhibit (HMD).{" "}
                      <br />
                      Head-mounted displays (HMDs) are small displays or
                      projection technology integrated into eyeglasses or
                      mounted on a helmet or hat. Heads-up displays are a type
                      of HMD that does not block the user's vision, but
                      superimposes the image on the user's view of the real
                      world. Some examples of VR Hardwares are :
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="common-row align-items Hardwares-img-block mt-50 d-flex">
              <div className="common-col-3 common-sm-4 common-xs-10 mb-80">
                <div className="Hardwares-img">
                  <img src={Oculus} alt="" />
                  <strong>Oculus Rift (Tethered)</strong>
                </div>
              </div>

              <div className="common-col-3 common-sm-4 common-xs-10 mb-80">
                <div className="Hardwares-img">
                  <img src={Oculustwo} alt="" />
                  <strong>Oculus Quest 2 (StandAlone)</strong>
                </div>
              </div>

              <div className="common-col-3 common-sm-4 common-xs-10 mb-80">
                <div className="Hardwares-img">
                  <img src={Oculusthree} alt="" />
                  <strong>HTC Vive Cosmos (Tethered)</strong>
                </div>
              </div>

              <div className="common-col-3 common-sm-4 common-xs-10 mb-80">
                <div className="Hardwares-img">
                  <img src={Oculusfour} alt="" />
                  <strong>Oculus Rift 2 (Tethered)</strong>
                </div>
              </div>

              <div className="common-col-3 common-sm-4 common-xs-10 mb-80">
                <div className="Hardwares-img">
                  <img src={Oculusfive} alt="" />
                  <strong>HTC Vive focus 3 (Standalone)</strong>
                </div>
              </div>

              <div className="common-col-3 common-sm-4 common-xs-10 mb-80">
                <div className="Hardwares-img">
                  <img src={Oculussix} alt="" />
                  <strong>Sony PS VR</strong>
                </div>
              </div>

              <div className="common-col-3 common-sm-4 common-xs-10 mb-80">
                <div className="Hardwares-img">
                  <img src={Oculusseven} alt="" />
                  <strong>Varjo VR</strong>
                </div>
              </div>
            </div>
            <div className="Controller_Hardwares_bg">
              <img src={OculusBg} alt="" />
            </div>
          </div>
        </div>

        <div className="controller-section py-100 pb-0">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home mb-50">
                  <h2>
                    <span>Challenges With</span>
                    <br />
                    Available <br />
                    XR Controllers
                  </h2>
                </div>
              </div>
              <div className="common-sm-12 common-col-7">
                <div className="controller-flex-box">
                  <div className="controller-contrnt-paragraph">
                    <p>
                      Augmented Reality and Virtual Reality technologies will
                      serve as a major stepping stone for Metaverse as they are
                      the core, however there are some drawbacks -
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="common-row align-items challnge_with_blocks mt-50">
              <div className="common-col-4 common-sm-12">
                <div className="challnge_with">
                  <div className="img-challange">
                    <img src={Service} alt="" />
                  </div>
                  <div className="challange-content">
                    <h3>
                      <img src={Servicecom} alt="" /> EXPENSIVE
                    </h3>
                    <p>
                      For VR experiences, one requires high-tech, expensive
                      headsets which can't be accessed easily by general public.
                    </p>
                  </div>
                </div>
              </div>

              <div className="common-col-4 common-sm-12">
                <div className="challnge_with">
                  <div className="img-challange">
                    <img src={Servicetwo} alt="" />
                  </div>
                  <div className="challange-content">
                    <h3>
                      <img src={Servicecom} alt="" /> LACK OF APPLICATIONS
                    </h3>
                    <p>
                      Being a niche technology, available application base is
                      low. Industry grade applications are barely available
                    </p>
                  </div>
                </div>
              </div>

              <div className="common-col-4 common-sm-12">
                <div className="challnge_with">
                  <div className="img-challange">
                    <img src={Servicethree} alt="" />
                  </div>
                  <div className="challange-content">
                    <h3>
                      <img src={Servicecom} alt="" />
                      LACK OF DEVELOPER CONTENT
                    </h3>
                    <p>
                      There is lack of learning resources, vibrant community and
                      ecosystem for becoming an AR/VR Developer.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="common-row challnge_bottom_blocks mt-100 align-items">
              <div className="common-col-8 common-xs-12">
                <div className="common-heading-home small-heading">
                  <h2>
                    So how do we solve the above challenges at
                    <span> TechXR?</span>
                  </h2>
                </div>
              </div>

              <div className="common-col-4 common-xs-12">
                <div className="challnge_with_absolute">
                  <div className="img-challange">
                    <img src={VRSolving} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="controller-section controller-white-bg py-100">
          <div className="common-container">
            <div className="common-row align-items">
              <div className="common-sm-12 common-col-4">
                <div className="controler-image">
                  <img src={vrcontroller} alt="" />
                </div>
              </div>
              <div className="common-sm-12 common-col-7 d-flex-between">
                <div className="controller-contrnt-paragraph">
                  <p>
                    <strong>
                      TechXR Innovations Private Limited has launched it’s own
                      XR Controller which allows 6DoF Movement in the Virtual
                      Reality Environment. Connect this controller with your
                      Smartphone and experience your VR world with interaction
                      like never before. And all this
                      <span className="color-dark">
                        {" "}
                        at an amazing price at a fraction of other controllers
                        in the market.{" "}
                      </span>
                    </strong>
                  </p>

                  <div className="bottom-heading">
                    <div className="common-heading-home mt-50 mb-0 small-heading">
                      <h2>
                        Introducing to you
                        <span> SenseXR Controller!</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="controller-sensexr-section py-100">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home mb-50">
                  <h2>
                    <span>SenseXR </span>
                    <br />
                    Controller
                  </h2>
                </div>
              </div>
              <div className="common-sm-12 common-col-7 d-flex-between d-flex-between controllerwithimage">
                <div className="controller-contrnt-paragraph">
                  <p>
                    <span className="color-dark">SenseXR Controller</span> is a
                    hardware accessory that allows users to take action in
                    virtual reality. SenseXR is a 6 DoF XR Controller, designed
                    by TechXR Innovations Private Limited. The distinguishing
                    features of SenseXR are :
                  </p>
                  <ol>
                    <li>It is compatible with popular smartphones.</li>
                    <li>It provide 6DoF interaction in the VR space</li>
                  </ol>
                  <p>
                    <span className="color-dark">
                      Since SenseXR works with existing smartphones, it provides
                      interactive VR Experience at a very affordable price.
                    </span>
                  </p>
                </div>
                <div className="right-image-control">
                  <img src={vrcontrollertwo} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="how_does_it_work pb-100">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home mb-50">
                  <h2>
                    <span>How does</span>
                    <br />
                    it Work?
                  </h2>
                </div>
              </div>
              <div className="common-sm-12 common-col-7 d-flex-between">
                <div className="controller-contrnt-paragraph">
                  <p>
                    In order to use SenseXR, apart from the controller, the user
                    may require
                  </p>

                  <ul className="alfa-control">
                    <li>Smartphone,</li>
                    <li>A VR Headset</li>
                    <li>2 - AAA Battey cells</li>
                    <li>VR Applications installable on the phone.</li>
                  </ul>

                  <p>The process is easy :</p>
                </div>
              </div>
            </div>
            <div className="common-row mt-50">
              <div className="common-col-12">
                <div className="controller_instruction">
                  <ul className="card_list">
                    <li>
                      <div className="inner_card_list_elem">
                        <div className="top_block">
                          <span className="number_count">
                            <img src={HowDoesSmall} alt="" />
                          </span>
                          <div className="right_content_block">
                            <h3>INSTALL</h3>
                            <p>
                              Install our app from the Google Play Store or
                              Apple Store on your Smartphone
                            </p>
                          </div>
                        </div>
                        <div className="img_block_instruction">
                          <img src={HowDoes} alt="" />
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="inner_card_list_elem">
                        <div className="top_block">
                          <span className="number_count">
                            <img src={HowDoestwoSmall} alt="" />
                          </span>
                          <div className="right_content_block">
                            <h3>CONNECT</h3>
                            <p>
                              Connect the controller to your phone using
                              Bluetooth
                            </p>
                          </div>
                        </div>
                        <div className="img_block_instruction">
                          <img src={HowDoestwo} alt="" />
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="inner_card_list_elem">
                        <div className="top_block">
                          <span className="number_count">
                            <img src={HowDoesthreeSmall} alt="" />
                          </span>
                          <div className="right_content_block">
                            <h3>GET READY</h3>
                            <p>Place the smartphone into the headset</p>
                          </div>
                        </div>
                        <div className="img_block_instruction">
                          <img src={HowDoesthree} alt="" />
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="inner_card_list_elem">
                        <div className="top_block">
                          <span className="number_count">
                            <img src={HowDoesfourSmall} alt="" />
                          </span>
                          <div className="right_content_block">
                            <h3>PLAY</h3>
                            <p>
                              Put on the headset and dive right into the
                              Immersive World
                            </p>
                          </div>
                        </div>
                        <div className="img_block_instruction">
                          <img src={HowDoesfour} alt="" />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="instruction_bg">
            <img src={HowDoesInstruction} alt="" />
          </div>
        </div>

        <div className="controller-section controller_details-img py-100">
          <div className="details-img-bg">
            <img src={FronBg} alt="" />
          </div>
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home mb-50">
                  <h2>
                    <span>SenseXR </span> Guide
                  </h2>
                </div>
              </div>
              <div className="common-sm-12 common-col-11 m-auto">
                <div className="controller-new-image d-flex-center">
                  <img src={Front} alt="" />
                </div>
              </div>
              <div className="common-sm-12 common-col-9 m-auto">
                <div className="controller-new-image">
                  <img src={Back} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="details-img-bg second">
            <img src={FronBgtwo} alt="" />
          </div>
        </div>

        <div className="controller-section controller-learning py-100 controller-white-bg">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home mb-50">
                  <h2>
                    <span>SenseXR </span>
                    <br />
                    Learnings
                  </h2>
                </div>
              </div>
              <div className="common-sm-12 common-col-7 d-flex-between d-flex-between controllerwithimage">
                <div className="controller-contrnt-paragraph">
                  <p>
                    SenseXR Controller can be used as a go-to device for diving
                    into VR or can also be used to start your journey as an
                    AR/VR Creator. If you want to make a career as an
                    AR/VR/Metaverse Developer then check out our interesting
                    courses. With these courses you can either create your own
                    Science Lab or a Shooting Game or Nano Metaverse of your own
                    imagination. The possibilities are countless.
                  </p>

                  <div className="sensebtnblock">
                    <a href="http://techxr.co/courses">Explore Our Courses</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="common-row align-items mt-50">
              <div className="common-col-5 common-sm-12">
                <div className="learnig-left-block">
                  <ul>
                    <li>
                      <div className="listing-block">
                        <img src={Learning} alt="" />
                        <h3>SCIENCE LAB</h3>
                      </div>
                    </li>

                    <li>
                      <div className="listing-block">
                        <img src={LearningTwo} alt="" />
                        <h3>SHOOTING GAMES</h3>
                      </div>
                    </li>

                    <li>
                      <div className="listing-block">
                        <img src={LearningThree} alt="" />
                        <h3>INDUSTRY TRAINING</h3>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="common-col-7 common-sm-12">
                <div className="learnig-right-block">
                  <img src={Learningfour} alt="" />
                </div>
              </div>
            </div>

            <div className="common-row  card-block-learning mt-50  d-none ">
              <div className="common-col-4 common-xs-12">
                <div className="learning-img-card">
                  <a href="#">
                    <img src={Card} alt="" />
                  </a>
                </div>
              </div>

              <div className="common-col-4 common-xs-12">
                <div className="learning-img-card">
                  <a href="#">
                    <img src={CardTwo} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="controller-section controller-white-bg py-100 d-none ">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home mb-50">
                  <h2>
                    <span>Applications </span> <br />
                    to begin with
                  </h2>
                </div>
              </div>
              <div className="common-sm-12 common-col-7 d-flex-between">
                <div className="controller-contrnt-paragraph">
                  <p>
                    To get a feel of interactive VR with SenseXR the user may
                    try TechXR popular applications in gaming, and simulation
                    such as :
                  </p>
                  <div className="application_store_gallery">
                    <ul>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="controller-section py-100 d-none ">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home mb-50">
                  <h2>
                    <span>SenseXR </span>also provides AR experience
                  </h2>
                </div>
              </div>
              <div className="common-sm-12 common-col-7 d-flex-between">
                <div className="controller-contrnt-paragraph">
                  <p>
                    SenseXR C ontroller, along with a compatible headset, allows
                    user to explore both AR and VR experiences. As a matter of
                    fact, the following AR application are already available on
                    Play Store and App Store to try your hands. _______.
                  </p>
                  <div className="application_store_gallery">
                    <ul>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="controller-section controller-white-bg py-100 d-none ">
          <div className="common-container">
            <div className="common-row">
              <div className="common-sm-12 common-col-4">
                <div className="common-heading-home mb-50">
                  <h2>
                    <span>SenseXR </span>Gallery
                  </h2>
                </div>
              </div>
              <div className="common-sm-12 common-col-7 d-flex-between">
                <div className="controller-contrnt-paragraph">
                  <div className="application_store_gallery none_border_radies">
                    <ul>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                      <li>
                        <div className="app-image"></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SenseXr;
