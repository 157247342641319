import * as React from "react";
import * as HomePage from ".";
import { Helmet, HelmetProvider } from "react-helmet-async";

interface HomePageMainComponentProps {}

export const HomePageMainComponent: React.FunctionComponent<
  HomePageMainComponentProps
> = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="main-section mx-w-1440">
        <HelmetProvider>
          <Helmet>
            <title>
              AR-VR Learning Easy, Affordable and Accessible | TechXR
            </title>
            <meta
              name="title"
              content="AR-VR Learning Easy, Affordable and Accessible | TechXR"
            />
            <meta
              name="description"
              content="We are on a mission to democratize access to emerging technologies, like Augmented, Virtual and Mixed Reality. We provide AR – VR Certification courses in India.
              "
            />
            <meta
              name="keywords"
              content="how to learn AR VR, virtual reality certification Course India, augmented reality certification Course India, ar vr certiﬁcation course India, ar vr course, ar vr course in India, virtual reality course in India, virtual reality training in India"
            />
            <meta
              property="image"
              content="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/uD41orAQL8hE2zva_2023-05-30T064448436786.Img web.png"
            />
            <link rel="canonical" href="https://techxr.co/ " />
          </Helmet>
        </HelmetProvider>
        <HomePage.AboveFoldMainComp></HomePage.AboveFoldMainComp>

        <HomePage.UsecaseComp></HomePage.UsecaseComp>

        <HomePage.OurAppsHomeComp></HomePage.OurAppsHomeComp>

        <HomePage.OurCoursesMainComp></HomePage.OurCoursesMainComp>

        <HomePage.WhyUsMainComp></HomePage.WhyUsMainComp>

        <HomePage.StudentProjectsMainComp></HomePage.StudentProjectsMainComp>

        <HomePage.TestimoniesMainComp></HomePage.TestimoniesMainComp>

        <HomePage.OurPartnersAndAchievementsMainComp></HomePage.OurPartnersAndAchievementsMainComp>

        <HomePage.NewsAndInfo></HomePage.NewsAndInfo>

        <HomePage.EventsMainComp id={""}></HomePage.EventsMainComp>

        {/* <Demo/> */}
      </div>
    </>
  );
};
