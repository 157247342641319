import { useState, useImperativeHandle, forwardRef } from "react";
import { GrClose } from "react-icons/gr";
import { getYoutubeVideoId } from "../../../apiServices/Services/CommonServices";
import YouTube from "react-youtube";

export const AppsYoutubeVideoModal = forwardRef((props: any, ref) => {
  const [youtubeVideoId, setYoutubeVideoId] = useState<string | undefined>();
  const [projectName, setProjectName] = useState<string | undefined>();

  useImperativeHandle(ref, () => ({
    setData(link: string, projectName: string) {
      setYoutubeVideoId(getYoutubeVideoId(link));
      setProjectName(projectName);
    },
  }));

  const opts = {
    height: "450",
    width: "600",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const closeModal = () => {
    setYoutubeVideoId("");
  };

  return (
    <>
      <div
        className="modal fade"
        id="youtubeVideoModal3"
        tabIndex={-1}
        aria-labelledby="youtubeVideoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="youtubeVideoModalLabel">
                {projectName ? projectName : "Student Project"}
              </h5>
              <GrClose
                type="button"
                id="closeYoutubeVideoModal"
                data-bs-dismiss="modal"
                onClick={closeModal}
              />
            </div>
            <div className="modal-body">
              {youtubeVideoId && (
                <YouTube videoId={youtubeVideoId} opts={opts} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
