import * as React from "react";
import * as Yup from "yup";
import { Formik, Field, Form, FormikHelpers, ErrorMessage } from "formik";
import TableCardCompo from "./TableCardCompo";

export interface IShippingAddressProps {
  firstName: string;
  lastName: string;
  email: string;
}

export default function ShippingAddress(props: IShippingAddressProps) {
  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
  });
  return (
    <>
      <div className="project-submission-main mx-w-1440">
        <div className="common-container">
          <div className="project-submission-inner submission-inner-shipping">
            <div className="privacy-heading">
              <h1>Customer information</h1>
            </div>

            {/* <div className="common-row">
              <div className="submission-input common-col-12 common-md-12 common-xs-12">
                <div className="input-inner">
                  <label htmlFor="email">Email</label>
                  <Field
                    id="email"
                    name="email"
                    placeholder="john@acme.com"
                    type="email"
                  />
                  <ErrorMessage
                    component="div"
                    className="errormsglabel"
                    name="name"
                  />
                </div>
              </div>
            </div> */}

            <div className="common-row">
              <div className="common-col-8 common-md-12">
                <div className="submission-section">
                  <div className="submision-sub-headig">
                    <h2>
                      Billing <span>& Shipping</span>
                    </h2>
                  </div>
                  <Formik
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={(
                      values: IShippingAddressProps,
                      { setSubmitting }: FormikHelpers<IShippingAddressProps>
                    ) => {
                      setTimeout(() => {
                        alert(JSON.stringify(values));
                        setSubmitting(false);
                      }, 500);
                    }}
                  >
                    <Form>
                      <div className="common-row">
                        <div className="submission-input common-col-6 common-md-6 common-xs-12">
                          <div className="input-inner">
                            <label htmlFor="firstName">First Name</label>
                            <Field
                              id="firstName"
                              name="firstName"
                              required=""
                              placeholder="First Name"
                            />
                          </div>
                        </div>

                        <div className="submission-input common-col-6 common-md-6 common-xs-12">
                          <div className="input-inner">
                            <label htmlFor="lastName">Last Name</label>
                            <Field
                              id="lastName"
                              name="lastName"
                              required=""
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="common-row">
                        <div className="submission-input common-col-6 common-md-6 common-xs-12">
                          <div className="input-inner">
                            <label htmlFor="Housenumber">House Number</label>
                            <Field
                              id="Housenumber"
                              name="Housenumber"
                              required=""
                              placeholder="House Number"
                            />
                          </div>
                        </div>

                        <div className="submission-input common-col-6 common-md-6 common-xs-12">
                          <div className="input-inner">
                            <label htmlFor="Apartment">Apartment</label>
                            <Field
                              id="Apartment"
                              name="Apartment"
                              required=""
                              placeholder="Apartment"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="common-row">
                        <div className="submission-input common-col-6 common-md-6 common-xs-12">
                          <div className="input-inner">
                            <label htmlFor="City">City</label>
                            <Field
                              id="City"
                              name="City"
                              required=""
                              placeholder="City"
                            />
                          </div>
                        </div>

                        <div className="submission-input common-col-6 common-md-6 common-xs-12">
                          <div className="input-inner">
                            <label htmlFor="state">State</label>
                            <Field
                              id="state"
                              name="state"
                              required=""
                              placeholder="State"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="common-row">
                        <div className="submission-input common-col-6 common-md-6 common-xs-12">
                          <div className="input-inner">
                            <label htmlFor="postalcode">Postal code</label>
                            <Field
                              id="postalcode"
                              name="postalcode"
                              required=""
                              placeholder="Postal code"
                            />
                          </div>
                        </div>

                        <div className="submission-input common-col-6 common-md-6 common-xs-12">
                          <div className="input-inner">
                            <label htmlFor="PhoneNumber">Phone Number</label>
                            <Field
                              id="PhoneNumber"
                              name="PhoneNumber"
                              required=""
                              placeholder="Phone Number"
                              type="number"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="common-row">
                        <div className="submission-input common-col-12 common-md-12 common-xs-12">
                          <div className="input-inner">
                            <label htmlFor="PhoneNumber">
                              Additional information
                            </label>
                            <Field
                              name="description"
                              component="textarea"
                              rows="2"
                              placeholder="Notes about your order, e.g. special notes for delivery."
                              required=""
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex-end-block">
                        <button type="submit">Continue to Payment</button>
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>

              <TableCardCompo />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
