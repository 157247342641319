import { plainToInstance } from "class-transformer";
import { HomePageCoursesResponse } from "../Models/WebsiteContent/HomePageModels/HomePageCoursesResponse";
import { HomePageTestimoniesResponse } from "../Models/WebsiteContent/HomePageModels/HomePageTestimoniesResponse";
import { HomePageStudentProjectsResponse } from "../Models/WebsiteContent/HomePageModels/HomePageStudentProjectsResponse";
import { HomePageUsecasesResponse } from "../Models/WebsiteContent/HomePageModels/HomePageUsecasesResponse";
import UsecasesResponseJson from "../Models/WebsiteContent/StaticJSONs/HomePageJsonResponses/HomePageUsecasesJson.json";
import { HomePageNewsResponse } from "../Models/WebsiteContent/HomePageModels/HomePageNewsResponse";
import NewsResponseJson from "../Models/WebsiteContent/StaticJSONs/HomePageJsonResponses/HomePageNewsJson.json";
import { OurAppsResponse } from "../Models/WebsiteContent/OurAppsModels/OurAppsResponse";
import OurAppsJson from "../Models/WebsiteContent/StaticJSONs/OurAppsResponse/OurAppsJson.json";

import { OurAppsOtherResponse } from "../Models/WebsiteContent/OurAppsModels/OurAppsOthersResponse";
import OurAppsOtherJson from "../Models/WebsiteContent/StaticJSONs/OurAppsResponse/OurAppsOtherJson.json";

import { OurAppsBannerResponse } from "../Models/WebsiteContent/OurAppsModels/OurAppsBannerResponsive";
import OurAppsBannerJson from "../Models/WebsiteContent/StaticJSONs/OurAppsResponse/OurAppsBannerJson.json";
import { HomePageAchievementsResponse } from "../Models/WebsiteContent/HomePageModels/HomePageAchievementsResponse";
import HomePageAchievementsJson from "../Models/WebsiteContent/StaticJSONs/HomePageJsonResponses/HomePageAchivementsJson.json";
import { HomePartnersResponse } from "../Models/WebsiteContent/HomePageModels/HomePartnersResponse";
import HomePartnersResponseJson from "../Models/WebsiteContent/StaticJSONs/HomePageJsonResponses/HomePartnersResponseJson.json";

import { CourseDetailResponse } from "../Models/WebsiteContent/CoursePageModels/CourseDetailResponse";
import { CourseCardDetailResponse } from "../Models/WebsiteContent/CoursePageModels/CourseCardDetailResponse";
import { CourseDescriptionResponse } from "../Models/WebsiteContent/CoursePageModels/CourseDescriptionResponse";
import { CourseStructureResponse } from "../Models/WebsiteContent/CoursePageModels/CourseStructure/CourseStructureResponse";
import CourseJson from "../Models/WebsiteContent/StaticJSONs/HomePageJsonResponses/HomePageCoursesJsonResponse.json";
import TestimonialsJson from "../Models/WebsiteContent/StaticJSONs/HomePageJsonResponses/HomePageTestimoniesJsonResponse.json";
import ProjectsJson from "../Models/WebsiteContent/StaticJSONs/HomePageJsonResponses/HomePageStudentProjectsJson.json";
import CoursesJsons from "../Models/WebsiteContent/StaticJSONs/CoursePageJsonResponses/CourseDetail";
import CoursesCardJsons from "../Models/WebsiteContent/StaticJSONs/CoursePageJsonResponses/CourseCard";
import CoursesDescJsons from "../Models/WebsiteContent/StaticJSONs/CoursePageJsonResponses/CourseDescription";
import CoursesStructureJsons from "../Models/WebsiteContent/StaticJSONs/CoursePageJsonResponses/CourseStructure";
import { FaqsResponse } from "../Models/WebsiteContent/FaqsPageModel/FaqsResponse";
import faqJson from "../Models/WebsiteContent/StaticJSONs/FaqJsonResponse/Faqs.json";

import { SdkResponse } from "../Models/WebsiteContent/SdkModels/Sdkmodels";
import Sdkjson from "../Models/WebsiteContent/StaticJSONs/Sdkresponse/sdkResponsejson.json";

import { XrBlocksModels } from "../Models/WebsiteContent/XrBlockModels/XrBlocksModels";
import XRblockJson from "../Models/WebsiteContent/StaticJSONs/XrBlockResponse/xrblockResponsejson.json";

import { EventDetailsResponse } from "../Models/WebsiteContent/EventPageModels/EventPageMainResponse";
import EventDetailJsons from "../Models/WebsiteContent/StaticJSONs/EventsJsonResponses";
import { toTimestamp } from "./CommonServices";

import { CountryContent } from "../Models/WebsiteContent/CountryContent/CountryContent";
import CountryContentJson from "../Models/WebsiteContent/StaticJSONs/CountryContentJson/CountryContentJson.json";

export class ContentServices {
  public getAllCourses(): HomePageCoursesResponse[] {
    return plainToInstance(HomePageCoursesResponse, CourseJson);
  }

  public getAllFaqs(): FaqsResponse[] {
    return plainToInstance(FaqsResponse, faqJson);
  }

  public getAllcountry(): CountryContent[] {
    return plainToInstance(CountryContent, CountryContentJson);
  }

  public getAllsdk(): SdkResponse[] {
    return plainToInstance(SdkResponse, Sdkjson);
  }

  public getAllxrBlocks(): XrBlocksModels[] {
    return plainToInstance(XrBlocksModels, XRblockJson);
  }

  public getAllTestimonies(): HomePageTestimoniesResponse[] {
    return plainToInstance(HomePageTestimoniesResponse, TestimonialsJson);
  }

  public getAllEvents(): EventDetailsResponse[] {
    let allEventsObject: EventDetailsResponse[] = new EventDetailsResponse();
    EventDetailJsons.forEach((eventJson) => {
      allEventsObject.push(plainToInstance(EventDetailsResponse, eventJson));
    });
    return allEventsObject.sort((a, b) =>
      toTimestamp(a.date) > toTimestamp(b.date) ? 1 : -1
    );
  }

  public getAllProjects(): HomePageStudentProjectsResponse[] {
    return plainToInstance(HomePageStudentProjectsResponse, ProjectsJson);
  }

  // ===================n ew=============================

  public getAllUsecases(): HomePageUsecasesResponse[] {
    return plainToInstance(HomePageUsecasesResponse, UsecasesResponseJson);
  }

  public getAllBannerimage(): OurAppsBannerResponse[] {
    return plainToInstance(OurAppsBannerResponse, OurAppsBannerJson);
  }

  public getAllNews(): HomePageNewsResponse[] {
    return plainToInstance(HomePageNewsResponse, NewsResponseJson);
  }

  public getAllapps(): OurAppsResponse[] {
    return plainToInstance(OurAppsResponse, OurAppsJson);
  }
  // new
  public getAllothers(): OurAppsOtherResponse[] {
    return plainToInstance(OurAppsOtherResponse, OurAppsOtherJson);
  }
  // new

  public getAllpartner(): HomePartnersResponse[] {
    return plainToInstance(HomePartnersResponse, HomePartnersResponseJson);
  }

  public getAllachivment(): HomePageAchievementsResponse[] {
    return plainToInstance(
      HomePageAchievementsResponse,
      HomePageAchievementsJson
    );
  }
  // ===================n ew=============================

  public getCourseDetail(courseId: string | undefined): CourseDetailResponse {
    let courseJson: CourseDetailResponse = new CourseDetailResponse();
    CoursesJsons.forEach((courseDetailJson) => {
      // console.log(courseDetailJson.id);
      if (courseId === courseDetailJson.id) {
        //
        courseJson = plainToInstance(CourseDetailResponse, courseDetailJson);
      }
    });
    // console.log(courseJson);
    return courseJson;
  }

  public getEventDetail(eventId: string | undefined): EventDetailsResponse {
    let eventJson;
    // console.log(eventJson);
    EventDetailJsons.forEach((eventDetailJson) => {
      if (eventId === eventDetailJson.id) {
        // console.log(eventDetailJson);
        eventJson = eventDetailJson;
      }
    });
    // console.log(plainToInstance(EventDetailsResponse, eventJson));
    return plainToInstance(EventDetailsResponse, eventJson);
  }

  public getCourseCardDetail(
    courseId: string | undefined
  ): CourseCardDetailResponse {
    let courseCardJson: CourseCardDetailResponse =
      new CourseCardDetailResponse();
    CoursesCardJsons.forEach((courseCardDetailJson) => {
      if (courseId === courseCardDetailJson.id) {
        courseCardJson = plainToInstance(
          CourseCardDetailResponse,
          courseCardDetailJson
        );
      }
    });
    return courseCardJson;
    // return plainToInstance(CourseCardDetailResponse, courseCardJson);
  }

  public getCourseDescDetail(
    courseId: string | undefined
  ): CourseDescriptionResponse {
    let courseDescJson: CourseDescriptionResponse =
      new CourseDescriptionResponse();
    CoursesDescJsons.forEach((courseDescDetailJson) => {
      if (courseId === courseDescDetailJson.id) {
        courseDescJson = courseDescDetailJson;
      }
    });
    return plainToInstance(CourseDescriptionResponse, courseDescJson);
  }

  public getCourseStructureDetail(
    courseId: string | undefined
  ): CourseStructureResponse {
    let courseStructJson: CourseStructureResponse =
      new CourseStructureResponse();
    CoursesStructureJsons.forEach((courseStructureJson) => {
      // console.log(courseStructureJson);
      if (courseId === courseStructureJson.id) {
        courseStructJson = courseStructureJson;
      }
    });
    return plainToInstance(CourseStructureResponse, courseStructJson);
  }
}
