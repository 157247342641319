import * as React from "react";

import Bgimage from "../../../../assets/images/auth-pages-background/bg_png.png";
import Bgimage2 from "../../../../assets/images/auth-pages-background/bg_png2.png";
import AllMyProductsCardComp from "./AllMyProductsCardComp";
interface MyProductsMainCompProps {}

const MyProductsMainComp: React.FunctionComponent<MyProductsMainCompProps> = (
  props
) => {
  return (
   <>
    <div className="project-main mx-w-1440 projectAllcommon">
      {/* <PageBackgroundLeft /> */}
      <div className="common-container">
        <img src={Bgimage} alt="" className="bg-overlay" />
        <img src={Bgimage2} alt="" className="bg-overlay second" />
        
        <div className="common-heading course_title d-flex-between">
         <div className="leftSide">
         <h1>My Projects</h1>
          <h3>All</h3>
         </div>
         <div className="rightSide">
          <a href="project/add" className="outline-btn">Add Project</a>
         </div>
        </div>
        <div className="common-row">
          {/* <!-- Project-Card --> */}
          <AllMyProductsCardComp/>
          {/* <SingleCardCompo/> */}
        </div>
      </div>
      {/* <PageBackgroundRight /> */}
    </div>
   </>
  );
};

export default MyProductsMainComp;