import * as React from "react";

import { TestimoniesCardsComp } from "./TestimoniesCardsComp";

interface TestimoniesMainCompProps {}

export const TestimoniesMainComp: React.FunctionComponent<
  TestimoniesMainCompProps
> = (props) => {
  return (
    <div className="testimonial pb-100">
      <div className="common-container">
        <div className="common-heading-home">
          <h2 className="text-center">Testimonies</h2>
        </div>
        <TestimoniesCardsComp></TestimoniesCardsComp>
      </div>
    </div>
  );
};
