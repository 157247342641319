import { useState } from "react";
import toast from "react-hot-toast";
import { AuthService } from "../../../apiServices/Services/AuthServices";

// css
import "../../../assets/css/required.css";
// import { ValidateForm } from "../../core/Validation/Validate";
import { HandleAuthToken } from "../../core/Utils/HandleAuthToken";
import { Formik, ErrorMessage, Form, Field } from "formik";
import * as Yup from "yup";
import { ChangePasswordRequest } from "../../../apiServices/Models/Auth/ChangePasswordRequest";
import {
  sendGAEvent,
  toggleLoader,
} from "../../../apiServices/Services/CommonServices";

const objAuthService = new AuthService();
// const objValidateForm = new ValidateForm();
const objAuthHandler = new HandleAuthToken();

// interface ChangePasswordProps {
//   password: string;
//   confirmPassword: string;
// }

const ChangePassword = (props: any) => {
  const [isLoading, setLoader] = useState(false);
  // const [password, setPassword] = useState("");
  // const [changePassword, setChangePassword] = useState("");

  // const validateForm = () => {
  //   // return password === changePassword && password.length > 3 && changePassword.length > 3;
  //   if (!objValidateForm.validatePassword(password)) {
  //     toast.error("Invalid new password.");
  //     return false;
  //   }
  //   if (password !== changePassword) {
  //     toast.error("New password & Confirm password do not match.");
  //     return false;
  //   }
  //   return true;
  // };

  const changeUserPassword = (values: ChangePasswordRequest) => {
    sendGAEvent(
      "Change Password Started",
      "Change_Password_Started",
      "Auth Actions"
    );
    const data = {
      password: values.password,
      confirmPassword: values.confirmPassword,
    };

    return objAuthService.changePassword(data);
  };
  // showhide function
  const [passwordShown, setPasswordShown] = useState(false);
  const [showhide, setshowhide] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
    setshowhide(!showhide);
  };

  const [passwordShowncon, setPasswordShowncon] = useState(false);
  const [showhidecon, setshowhidecon] = useState(false);
  const togglePasswordcon = () => {
    setPasswordShowncon(!passwordShowncon);
    setshowhidecon(!showhidecon);
  };
  // showhide function

  const closeModal = () => {
    (document.querySelector('button[id="closeModal"]') as any).click();
  };

  const handleSubmit = (values: ChangePasswordRequest) => {
    // event.preventDefault();
    // if (validateForm()) {
    setLoader(true);
    changeUserPassword(values).subscribe({
      next: (response) => {
        toast.success("Password changed successfully!!");
      },
      error: (err) => {
        sendGAEvent(
          "Change Password Failed",
          "Change_Password_Failed",
          "Auth Actions"
        );
        setLoader(false);
        if (err.response.data) {
          toast.error(err.response.data);
        } else if (err.response.status === 500) {
          toast.error("Service unavailable.");
        } else {
          toast.error("Something went wrong, please try again!");
        }
      },
      complete: () => {
        sendGAEvent(
          "Change Password Successful",
          "Change_Password_Successful",
          "Auth Actions"
        );
        setLoader(false);
        // setPassword("");
        // setChangePassword("");
        closeModal();
      },
    });
    // }
  };

  const confirmPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .matches(/^[\S]+$/, "Whitespace not allowed.")
      .min(4, "Too Short!")
      .max(100, "Too Long!")
      .required("Required"),
    confirmPassword: Yup.string()
      .matches(/^[\S]+$/, "Whitespace not allowed.")
      .min(4, "Too Short!")
      .max(100, "Too Long!")
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
  });

  return (
    <>
      {toggleLoader(isLoading)}
      <div className="login-account-middle">
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validationSchema={confirmPasswordSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ errors, touched }) => (
            <Form className="login-form">
              <div className="form-block">
                <label htmlFor="password" className="after-required">
                  New Password
                </label>
                <div className="showhidepasss">
                  <Field
                    name="password"
                    type={passwordShown ? "text" : "password"}
                    placeholder="Password"
                    className={
                      errors.password && touched.password ? "error-field" : ""
                    }
                  />

                  <span onClick={togglePassword}>
                    <img
                      src={
                        showhide
                          ? "  https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/GJB5iUeLn4D4S8Qe_2023-01-06T115153495563.show password.png"
                          : "https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/Fzxc2wSez2sf0HEP_2023-01-06T115348969065.Hide password.png"
                      }
                      alt=""
                    />
                  </span>
                  <ErrorMessage
                    component="div"
                    className="errormsglabel"
                    name="password"
                  />
                </div>
              </div>
              <div className="form-block">
                <label htmlFor="confirmPassword" className="after-required">
                  Confirm Password
                </label>
                <div className="showhidepasss">
                  <Field
                    name="confirmPassword"
                    type={passwordShowncon ? "text" : "password"}
                    placeholder="Confirm Password"
                    className={
                      errors.confirmPassword && touched.confirmPassword
                        ? "error-field"
                        : ""
                    }
                  />
                  <span onClick={togglePasswordcon}>
                    <img
                      src={
                        showhidecon
                          ? "  https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/GJB5iUeLn4D4S8Qe_2023-01-06T115153495563.show password.png"
                          : "https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/Fzxc2wSez2sf0HEP_2023-01-06T115348969065.Hide password.png"
                      }
                      alt=""
                    />
                  </span>
                  <ErrorMessage
                    component="div"
                    className="errormsglabel"
                    name="confirmPassword"
                  />
                </div>
              </div>
              <div className="create-btn">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default ChangePassword;
