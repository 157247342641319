import * as React from "react";
import ErrorImage from "../../../assets/images/error-img.png";
interface BackendErrorCompProps {}

const BackendErrorComp: React.FunctionComponent<BackendErrorCompProps> = (
  props
) => {
  return (
    <>
      <div className="error_block mx-w-1440">
        <div className="common-container">
          <div className="error_inner">
            <div className="error_block_innner">
              <img src={ErrorImage} alt="" />
              <div className="right_new">
                <h2>
                  oops !{" "}
                  <span> Looks like our server is throwing tantrums.</span>
                </h2>
                <p>
                  Please try after 15 minutes. If the problem persists then
                  write to <span>contact@tecxr.co</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BackendErrorComp;
