export class EventPageAbout {
  constructor() {
    this.description = "";
    this.keyTakeaways = "";
    this.eventSchedule = "";
  }
  public description: string;
  public keyTakeaways: string;
  public eventSchedule: string;
}
