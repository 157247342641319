import * as React from "react";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { ContentServices } from "../../../../apiServices/Services/ContentServices";
import { HomePageUsecasesResponse } from "../../../../apiServices/Models/WebsiteContent/HomePageModels/HomePageUsecasesResponse";
import { YoutubeVideoModal } from "../../Header/YoutubeVideoModal";

const contentService = new ContentServices();

interface UsecaseCompProps {
  // modelid: string;
}

export const UsecaseComp: React.FunctionComponent<UsecaseCompProps> = (
  props
) => {
  const videoModalRefsecond: any = useRef();
  const setProjectData = (link: string, projectName: string) => {
    videoModalRefsecond.current.setData(link, projectName);
  };

  const [projectsList, setProjects] = React.useState<
    HomePageUsecasesResponse[]
  >([]);
  const getProjectsData = (): HomePageUsecasesResponse[] => {
    return contentService.getAllUsecases();
  };
  React.useEffect(() => {
    setProjects(getProjectsData());
  }, [setProjects]);

  return (
    <>
      {" "}
      <div className="compnent-4 py-100 bg-light usecase-main">
        <div className="common-heading-home mb-50 ">
          <h2 className="text-center">
            <span>Controller</span>
            Usecases
          </h2>
        </div>

        <div className="usecase-blocK common-container">
          <div className="common-row usecase_inner">
            {projectsList?.map((project, key) => {
              return (
                <div
                  className="common-col-3 common-md-4 common-xs-12 mb-20"
                  key={key}
                >
                  <div className="video_sectio_block">
                    <button
                      className="dropdown-item usecase__btn"
                      data-bs-toggle="modal"
                      data-bs-target="#youtubeVideoModal"
                      onClick={() =>
                        setProjectData(
                          project.videoUrl,
                          project.categoryName ? project.categoryName : ""
                        )
                      }
                    >
                      <img src={project.imageUrl} alt="StudentProjectImage" />
                      <h2>
                        {project.categoryName ? project.categoryName : ""}
                      </h2>
                      <p>{project.description ? project.description : ""}</p>
                      {/* {console.log(project.videoUrl + "vd url student project >>")} */}
                    </button>
                  </div>
                </div>
              );
            })}
            <YoutubeVideoModal ref={videoModalRefsecond} />
          </div>

          <div className="bottom-usecase-button">
            <Link to="/courses">
              Explore our Courses <i className="arrow"> </i>
            </Link>
            <Link to="/ourapps">
              Explore our Apps <i className="arrow"> </i>
            </Link>
            <a
              target="_blank"
              href="https://sensexr.techxr.co/products/sensexr-ar-vr-controller-for-smartphone"
              rel="noreferrer"
            >
              Buy Now <i className="arrow"> </i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
