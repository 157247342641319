import { Type } from "class-transformer";
import { PlayControlsResponse } from "./PlayControlsResponseModel";
import { ProductPackage } from "./ProductPackageModel";

export class ProductRelease {
  constructor() {
    this.id = "";
    this.productId = "";
  }
  public id: string;
  public productId: string;
  public releaseVersion?: string;
  public fileSize?: number;
  @Type(() => ProductPackage)
  public productPackages?: Array<ProductPackage> | undefined;
  @Type(() => PlayControlsResponse)
  public appControl?: PlayControlsResponse;
  public changeLog?: string;
}
