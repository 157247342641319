import * as React from "react";

export interface IWebarvrProps {}

export function Webarvr(props: IWebarvrProps) {

      React.useEffect(() => {
        window.location.href = 'https://www.google.com/';
      }, []);

  return (
    <>
     
    </>
  );
}
