export class HomePageStudentProjectsResponse extends Array<HomePageStudentProjectsResponse> {
  constructor() {
    super();
    this.id = 0;
    this.projectName = "";
    this.description = "";
    this.projectUrl = "";
    this.videoUrl = "";
    this.imageUrl = "";
    this.studentName = "";
  }
  public id: number;
  public projectName: string;
  public description: string;
  public projectUrl: string;
  public videoUrl: string;
  public imageUrl: string;
  public studentName: string;
}
