import * as React from "react";
import UploadProductDetailsComp from "./ProductDetailsComponent/UploadProductDetailsComp";
import BgImage from "../../../../assets/images/auth-pages-background/bg_png.png";
import BgImage2 from "../../../../assets/images/auth-pages-background/bg_png2.png";
import { Link, useParams } from "react-router-dom";
import { ProductMedia } from "../../../../apiServices/Models/Products/ProductMediaModel";
import UploadMediaComp from "./UploadMediaComponent/UploadMediaComp";
import UploadApplicationComp from "./UploadApplicationComponent/UploadApplicationComp";
import { ProductRelease } from "../../../../apiServices/Models/Products/ProductReleaseModel";
import UploadPlayControlsComp from "./UploadPlayControlsComponent/UploadPlayControlsComp";
import { PlayControlsResponse } from "../../../../apiServices/Models/Products/PlayControlsResponseModel";
import { ProductSummary } from "../../../../apiServices/Models/Products/ProductSummaryModel";
import {
  getSlug,
  toggleLoader,
} from "../../../../apiServices/Services/CommonServices";
import { ProductService } from "../../../../apiServices/Services/ProductServices";
import toast from "react-hot-toast";

const objProductService = new ProductService();

interface EditProductMainCompProps {}

const EditProductMainComp: React.FunctionComponent<EditProductMainCompProps> = (
  props
) => {
  const { project_id } = useParams();
  const [isLoading, setLoader] = React.useState(false);

  const [productSummaryDto, setProductSummaryDto] = React.useState(
    new ProductSummary()
  );

  const [mediaDtoArray, setMediaDtoArray] = React.useState(
    new Array(new ProductMedia())
  );

  const [releaseDtoArray, setReleaseDtoArray] = React.useState(
    new Array(new ProductRelease())
  );

  const [controlsDtoArray, setControlsDtoArray] = React.useState(
    new PlayControlsResponse()
  );

  const [productReleaseId, setProductReleaseId] = React.useState("");

  const [isUserAuthorized, setUserAuthorized] = React.useState(false);

  const setProductSummary = (productSummary: ProductSummary): void => {
    setProductSummaryDto(productSummary);
  };

  const setMediaArray = (mediaDto: ProductMedia[]): void => {
    setMediaDtoArray(mediaDto ? mediaDto : new Array(new ProductMedia()));
  };

  const setReleasesArray = (releasesDto: ProductRelease[]): void => {
    setReleaseDtoArray(
      releasesDto ? releasesDto : new Array(new ProductRelease())
    );
    setReleaseId(releasesDto);
  };

  const setControlsArray = (controlsDto: PlayControlsResponse): void => {
    setControlsDtoArray(controlsDto);
  };

  const setReleaseId = (releasesDto: ProductRelease[]): void => {
    if (releasesDto.length > 0) {
      releasesDto.map((release) => {
        setProductReleaseId(release.id);
      });
    }
  };

  const setUserAuthorization = (isAuthorized: boolean) => {
    setUserAuthorized(isAuthorized);
  };

  const deleteProduct = (productSummaryDtoid: string) => {
    if (productSummaryDtoid !== undefined) {
      setLoader(true);

      objProductService.deleteProduct(productSummaryDtoid).subscribe({
        next: (response: ProductSummary) => {},
        error: (err) => {
          setLoader(false);

          if (err.response.data) {
            toast.error(err.response.data);
          } else if (err.response.status === 500) {
            toast.error("Service unavailable.");
          } else {
            toast.error("Something went wrong, please try again!");
          }
        },
        complete: () => {
          setLoader(false);

          window.location.href = "/myprojects";
        },
      });
    }
  };

  return (
    <>
      {toggleLoader(isLoading)}
      <div className="project-submission-main mx-w-1440">
        <div className="common-container">
          <img src={BgImage} alt="" className="bg-overlay" />
          <img src={BgImage2} alt="" className="bg-overlay second" />

          <div className="project-submission-inner">
            <div className="privacy-heading">
              <h1>Project Submission</h1>
            </div>
            <UploadProductDetailsComp
              productId={project_id ? project_id : ""}
              callBackWithProductSummaryDto={setProductSummary}
              callBackWithMediaDto={setMediaArray}
              callBackWithReleasesDto={setReleasesArray}
              callBackWithControlsDto={setControlsArray}
              callBackWithUserAuthorizationFlag={setUserAuthorization}
              // callBackWithDeveloperId={setDeveloper}
            />
            {isUserAuthorized ? (
              <>
                <UploadMediaComp
                  mediaDto={mediaDtoArray}
                  productId={project_id ? project_id : ""}
                />
                <UploadApplicationComp
                  productId={project_id ? project_id : ""}
                  releasesDto={releaseDtoArray}
                  releaseId={productReleaseId ? productReleaseId : ""}
                />
                <UploadPlayControlsComp
                  releasesDto={releaseDtoArray}
                  releaseId={productReleaseId ? productReleaseId : ""}
                  controlsDto={controlsDtoArray}
                />
                <div className="submission-btn flex-between">
                  <button
                    className="mainsubmition deletebtnred"
                    onClick={() => deleteProduct(productSummaryDto.id)}
                  >
                    delete
                  </button>

                  <Link
                    to={
                      "/projects/" +
                      productSummaryDto.id +
                      "/" +
                      getSlug(productSummaryDto.name)
                    }
                    className="mainsubmition"
                  >
                    Preview
                  </Link>
                </div>
                {/* <p>{productSummaryDto.name} lorem text</p> */}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProductMainComp;
