import * as React from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { PlayControlsResponse } from "../../../../../apiServices/Models/Products/PlayControlsResponseModel";
import { ProductRelease } from "../../../../../apiServices/Models/Products/ProductReleaseModel";
import { ProductService } from "../../../../../apiServices/Services/ProductServices";
import { toggleLoader } from "../../../../../apiServices/Services/CommonServices";

const objProductService = new ProductService();

interface UploadPlayControlsCompProps {
  releasesDto?: ProductRelease[];
  releaseId?: string;
  controlsDto?: PlayControlsResponse;
}

const UploadPlayControlsComp: React.FunctionComponent<
  UploadPlayControlsCompProps
> = (props) => {
  const releaseId: string = props.releaseId ? props.releaseId : "";
  const [isLoading, setLoader] = React.useState(false);
  const [releaseList, setReleaseList] = React.useState(
    new Array<ProductRelease>()
  );

  const [isEditModeOn, setEditModeOn] = React.useState(true);

  const initialValues = new PlayControlsResponse();

  initialValues.controls?.push({ input: "", outcome: "" });

  const [newValues, updateInitialValues] = React.useState(
    props.controlsDto?.controls ? props.controlsDto : initialValues
  );

  React.useEffect(() => {
    if (props.releasesDto) {
      setReleaseList(props.releasesDto);
    }
    if (props.controlsDto) {
      updateInitialValues(props.controlsDto);
    }
  }, [props.controlsDto, props.releasesDto]);

  const toggleFormEdit = () => {
    setEditModeOn(false);
  };

  const handleSavePlayControlsProduct = (values: PlayControlsResponse) => {
    if (values.controls.length === 0) {
      values = initialValues;
    }

    if (releaseId !== "") {
      const params = {
        productReleaseId: releaseId,
      };

      setLoader(true);

      const saveImage = objProductService.savePlayControls(values, params);

      saveImage.subscribe({
        next: (response: PlayControlsResponse) => {
          window.location.reload();
        },
        error: (err) => {
          if (err.response.data) {
            toast.error(err.response.data);
          } else if (err.response.status === 500) {
            toast.error("Service unavailable.");
          } else {
            toast.error("Something went wrong, please try again!");
          }
        },
        complete: () => {
          setLoader(false);
        },
      });
    }
  };

  const handleCancel = (resetForm: () => void) => {
    resetForm();
    if (releaseId) {
      setEditModeOn(true);
    }
  };

  const playControlsSchema = Yup.object({
    controls: Yup.array().of(
      Yup.object().shape({
        input: Yup.string()
          .min(1, "Too Short!")
          .max(100, "Too Long!")
          .matches(/^[A-Za-z\s][A-Za-z\s]*$/, "Invalid Project Name")
          .required("Required"),
        outcome: Yup.string()
          .min(1, "Too Short!")
          .max(500, "Too Long!")
          .required("Required"),
      })
    ),
  });

  return (
    <>
      {toggleLoader(isLoading)}
      <div className="submission-section">
        <div className="submision-sub-headig">
          <h2>
            Play manual<span>/Input controls</span>
          </h2>
          {isEditModeOn && (
            <button className="edit-btn" onClick={toggleFormEdit}>
              Edit
            </button>
          )}
        </div>
        <div className="input-manual">
          <div className="common-row">
            {!isLoading &&
              newValues.controls &&
              newValues.controls?.length > 0 && (
                <Formik
                  initialValues={newValues}
                  validationSchema={playControlsSchema}
                  onSubmit={(values: PlayControlsResponse) => {
                    handleSavePlayControlsProduct(values);
                  }}
                >
                  {({
                    errors,
                    touched,
                    values: v1,
                    setFieldTouched,
                    handleChange,
                    resetForm,
                  }) => (
                    <Form>
                      <FieldArray
                        name="controls"
                        render={(arrayHelpers) => {
                          return (
                            <>
                              {v1.controls?.map((control, index) => {
                                return (
                                  <>
                                    <div
                                      className="submission-input new-upload-input-submission"
                                      key={index}
                                    >
                                      <div className="input-inner">
                                        <label>Input Action</label>
                                        <Field
                                          // name="input"
                                          // value={control.input}
                                          name={`controls.${index}.input`}
                                          type="text"
                                          placeholder="Input Action"
                                          required=""
                                          // className={
                                          //   errors.input && touched.input ? "error-field" : ""
                                          // }
                                          disabled={isEditModeOn}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          className="errormsglabel"
                                          name="input"
                                        />
                                      </div>

                                      <div className="input-inner">
                                        <label>Outcome</label>
                                        <Field
                                          // name="outcome"
                                          name={`controls.${index}.outcome`}
                                          // value={control.outcome}
                                          type="text"
                                          placeholder="Outcome"
                                          required=""
                                          // className={
                                          //   errors.outcome && touched.outcome ? "error-field" : ""
                                          // }
                                          disabled={isEditModeOn}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          className="errormsglabel"
                                          name="outcome"
                                        ></ErrorMessage>
                                      </div>
                                      <div className="table-btn">
                                        <button
                                          type="button"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                          className="dismis-table"
                                          disabled={isEditModeOn}
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}

                              <div className="add-btn">
                                <button
                                  type="button"
                                  disabled={isEditModeOn}
                                  onClick={() =>
                                    arrayHelpers.push({
                                      input: "",
                                      outcome: "",
                                    })
                                  }
                                >
                                  add more
                                </button>
                              </div>

                              {isEditModeOn === false ? (
                                <>
                                  {" "}
                                  <div className="submission-btn">
                                    <button
                                      className="submit"
                                      type="submit"
                                      disabled={isEditModeOn}
                                    >
                                      Save
                                    </button>
                                    <button
                                      className="cancel"
                                      disabled={isEditModeOn}
                                      type="button"
                                      onClick={handleCancel.bind(
                                        null,
                                        resetForm
                                      )}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          );
                        }}
                      />
                    </Form>
                  )}
                </Formik>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadPlayControlsComp;
