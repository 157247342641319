import Event0 from "./0-Event.json";
import Event1 from "./1-Event.json";
import Event2 from "./2-Event.json";
import Event3 from "./3-Event.json";
import Event4 from "./4-Event.json";
import Event5 from "./5-Event.json";
import Event6 from "./6-Event.json";
import Event7 from "./7-Event.json";
import Event8 from "./8-Event.json";
import Event9 from "./9-Event.json";
import Event10 from "./10-Event.json";

let allEventsJson = [Event0, Event1, Event2, Event3, Event4, Event5, Event6, Event7,Event8, Event9, Event10];

export default allEventsJson;
