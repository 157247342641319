import { useEffect, useState } from "react";
import "../../../assets/css/main.css";
// js script
import SignupComponent from "../Header/SignupComponent";
import VerifyOTP from "../Header/VerifyOtp";
import { toggleLoader } from "../../../apiServices/Services/CommonServices";
import { PageBackgroundLeft } from "../CommonComponents/PageBackgroundLeft";
import { PageBackgroundRight } from "../CommonComponents/PageBackgroundRight";
import { LocalStorage } from "../../core/Storage/LocalStorage";
import { useAppSelector } from "../../../app/hooks";
import { isLoggedIn, lmsLink } from "../../../features/auth/AuthSlice";
import { Helmet, HelmetProvider } from "react-helmet-async";

function Signup() {
  const isLoggedInUser = useAppSelector(isLoggedIn);
  const lmsUrl = useAppSelector(lmsLink);
  const objLocalStorage = new LocalStorage();
  useEffect(() => {
    objLocalStorage.setCookie("popup", "true", 1);
  });

  const [isLoading, setLoader] = useState(false);
  const toggleisLoader = (status: boolean) => {
    if (status === true) {
      setLoader(status);
    } else {
      setLoader(status);
    }
  };

  const [value, setValue] = useState(0); // Working fine
  const ForceUpdate = () => {
    setValue((value) => value + 1);
  };

  const [showverifyotp, setShowverifyotp] = useState(false);
  const toggleshowverifyotp = () => {
    if (showverifyotp === false) {
      setShowverifyotp(true);
    } else {
      setShowverifyotp(false);
    }
  };

  const redirectLoggedInUser = () => {
    // console.log(lmsUrl);
    if (lmsUrl !== "https://learn.techxr.co/s/store") {
      window.location.href = lmsUrl;
    }
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>TechXR - Sign Up Page</title>
          <meta
            name="title"
            content="Making Quality AR-VR Learning Easy - Affordable - Accessible"
          />
          <meta
            name="description"
            content="We are on a mission to democratize the access to emerging technologies, like Augmented, Virtual and Mixed Reality, where the main barrier to entry is the high cost of hardware and practically zero access for people with limited resources."
          />
          <meta
            property="image"
            content="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/uD41orAQL8hE2zva_2023-05-30T064448436786.Img web.png"
          />
        </Helmet>
      </HelmetProvider>
      {toggleLoader(isLoading)}
      <div className="main-section mx-w-1440">
        <div className="create-account py-100">
          <PageBackgroundLeft />
          {!isLoggedInUser ? (
            <>
              {showverifyotp ? (
                <>
                  {/* <h2>Verify OTP</h2> */}
                  <VerifyOTP
                    reRender={ForceUpdate}
                    isLoader={toggleisLoader}
                    hide={true}
                  />
                </>
              ) : (
                <>
                  <h2>Sign Up</h2>
                  <SignupComponent
                    hide={true}
                    reRender={ForceUpdate}
                    isLoader={toggleisLoader}
                    toggleCompo={toggleshowverifyotp}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <h4 style={{ textAlign: "center" }}>
                Looks like you're already logged-in!!. Redirecting you to our
                Learning Portal!!
              </h4>
              {redirectLoggedInUser()}
            </>
          )}
        </div>
      </div>
    </>
  );
}
export default Signup;
