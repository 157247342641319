import React from "react";
import { Link } from "react-router-dom";
import BgImage from "../../../assets/images/auth-pages-background/bg_png.png";
import BgImage2 from "../../../assets/images/auth-pages-background/bg_png2.png";

const Page404 = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="project-main mx-w-1440">
        <div className="project-container">
          <img src={BgImage} alt="" className="bg-overlay" />
          <img src={BgImage2} alt="" className="bg-overlay second" />
          <h1 style={{ justifyContent: "center" }}>
            Oops the page you're looking for isn't available. Go to{" "}
            <span>
              <Link to="/">Home Page</Link>
            </span>
          </h1>
        </div>
      </div>
    </>
  );
};

export default Page404;
