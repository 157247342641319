export class FaqsResponse extends Array<FaqsResponse> {
  constructor() {
    super();
    this.id = "0";
    this.question = "";
    this.answer = "";
  }
  public id: string;
  public question: string;
  public answer: string;
}
