export class CourseCardDetailResponse {
  constructor() {
    this.id = "0";
    this.price = 25000;
    this.discount = 15;
    this.actualPrice = 21250;
    this.entryOpenTill = "";
    this.imageUrl = "";
    this.enrollLink = "";
    this.demoLink = "";
    this.videoLink = "";
  }
  public id: string;
  public price: number;
  public discount: number;
  public actualPrice: number;
  public entryOpenTill: string;
  public imageUrl: string;
  public enrollLink: string;
  public demoLink: string;
  public videoLink: string | undefined;

}
