import { Document } from "./DocumentModel";
import { Type } from "class-transformer";

export class ProductMedia {
  public id?: string;
  @Type(() => Document)
  public document?: Document;
  public productId?: string;
  public mediaType?: string;
  public thumbnail?: boolean;
}
