import * as React from "react";
import { EventPageAbout } from "../../../../apiServices/Models/WebsiteContent/EventPageModels/EventPageAboutResponse";
import EventBg from "../../../../assets/images/our-events-img/eventbg.png";

interface AboutEventCompProps {
  eventAboutDetails: EventPageAbout;
}

export const AboutEventComp: React.FunctionComponent<AboutEventCompProps> = (
  props
) => {
  const eventAboutDetails = props.eventAboutDetails;
  return (
    <div className="Event_content_area py-80">
      <div className="common-container">
        <div className="common-row">
          <div className="event_details_col">
            <div className="about_event_box">
              <h2>About The Event</h2>
              {/* <p>dangerouslySetInnerHTML={{ __html: eventAboutDetails.description }}</p> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: eventAboutDetails.description,
                }}
              ></div>
            </div>

            <div className="key_takeaway">
              <h2>Key Takeaways</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: eventAboutDetails.keyTakeaways,
                }}
              ></div>
            </div>

            <div className="event_Schedule">
              <h2>Event Schedule</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: eventAboutDetails.eventSchedule,
                }}
                className="Schedule_content"
              ></div>
            </div>

            <div className="event_con_bg">
              <img src={EventBg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
