import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "swiper/css/effect-creative";
import { EffectFade, Navigation, Pagination, Autoplay } from "swiper";
import { OurAppsBannerResponse } from "../../../apiServices/Models/WebsiteContent/OurAppsModels/OurAppsBannerResponsive";
import { ContentServices } from "../../../apiServices/Services/ContentServices";

interface AppsBannerProps {}
const contentService = new ContentServices();
export const AppsBanner: React.FunctionComponent<AppsBannerProps> = (props) => {
  const [BannerappList, setBannerappList] = React.useState<
    OurAppsBannerResponse[]
  >([]);
  const getProjectsData = (): OurAppsBannerResponse[] => {
    return contentService.getAllBannerimage();
  };
  React.useEffect(() => {
    setBannerappList(getProjectsData());
  }, [setBannerappList]);

  return (
    <>
      <Swiper
        spaceBetween={30}
        navigation={true}
        loop={true}
        effect={"fade"}
        autoplay={{
          delay: 8000,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[EffectFade, Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {BannerappList?.map((Bannerimage, key) => {
          return (
            <SwiperSlide>
              <div className="apps-banner-img" key={key}>
                <img src={Bannerimage.BannerimageUrl} alt="" />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};
