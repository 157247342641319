export class LmsResponse {
    constructor() {
        this.id = -1;
        this.profileId = -1;
        this.lmsUrl = '';
        this.accountCreated = false
    }
    
    public id?: number = -1;
    public profileId: number = -1;
    public lmsUrl: string;
    public accountCreated: boolean = false;
}
