export class HomePageUsecasesResponse extends Array<HomePageUsecasesResponse> {
    constructor() {
      super();
      this.id = 0;
      this.categoryName = "";
      this.description = "";
      this.videoUrl = "";
      this.imageUrl = "";
    }
    public id: number;
    public categoryName: string;
    public description: string;
    public videoUrl: string;
    public imageUrl: string;
  }
  