import * as React from "react";
import { StudentProjectCardsComp } from "./StudentProjectCardsComp";

interface StudentProjectsMainCompProps {}

export const StudentProjectsMainComp: React.FunctionComponent<
  StudentProjectsMainCompProps
> = (props) => {
  return (
    <div className="compnent-4 py-100 ">
      {/* <h2 className="student-section-title">
        Student <span>Projects </span>
      </h2> */}

      <div className="common-heading-home">
        <h2 className="text-center">
          <span>Student</span>
          Projects
        </h2>
      </div>
      <div className="slider_section px-15">
        <StudentProjectCardsComp></StudentProjectCardsComp>
      </div>
    </div>
  );
};
