import { toTimestamp } from "../../../../apiServices/Services/CommonServices";

export const isEventInFuture = (eventDate: string): boolean => {
  let isEventInFuture: boolean = false;
  //   const currentTimestamp = toTimestamp(currentDate);
  if (eventDate !== "") {
    const eventTimestamp = toTimestamp(eventDate);
    // console.log(eventTimestamp);
    if (Date.now() > eventTimestamp) {
      return isEventInFuture;
    } else {
      isEventInFuture = true;
      return isEventInFuture;
    }
  }
  return isEventInFuture;
};
