import * as React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ParticlesCompo from "../SenseXR/Particles";
import { HomePageUsecasesResponse } from "../../../apiServices/Models/WebsiteContent/HomePageModels/HomePageUsecasesResponse";
import { ContentServices } from "../../../apiServices/Services/ContentServices";
import { Link } from "react-router-dom";
import { YoutubeVideoModal } from "../Header/YoutubeVideoModal";
import { OurPartnersComp } from "../HomePage/OurPartnersAndAcheivementsComponents/OurPartnersComp";
import { TestimoniesCardsComp } from "../HomePage/TestimoniesComponents/TestimoniesCardsComp";
import BookCallFormMainComp from "../HomePage/AboveFoldComponents/BookCallFormMainComp";
const contentService = new ContentServices();

interface LandingPageTwitterProps {}
const myVar2 = require("../../../assets/videos/finalvideo.mp4");
const LandingPageTwitter: React.FunctionComponent<LandingPageTwitterProps> = (
  props: any
) => {
  const videoModalRefsecond: any = React.useRef();
  const setProjectData = (link: string, projectName: string) => {
    videoModalRefsecond.current.setData(link, projectName);
  };

  const [projectsList, setProjects] = React.useState<
    HomePageUsecasesResponse[]
  >([]);
  const getProjectsData = (): HomePageUsecasesResponse[] => {
    return contentService.getAllUsecases();
  };
  React.useEffect(() => {
    setProjects(getProjectsData());
  }, [setProjects]);

  const [muted, setMuted] = React.useState(true);
  const handleToggleMute = () => setMuted((current) => !current);

  // model
  const closeModal = () => {
    (document.querySelector('a[class="openModal"]') as any).click();
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    const timer = setTimeout(() => {
      console.log("timer is up");
      closeModal();
      setOpen(true);
    }, 30000);
    return () => clearTimeout(timer);
  };
  React.useEffect(() => {
    handleOpen();
  }, []);
  // model

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>TechXR | Twitter Page</title>
          <meta name="title" content="TechXR | Landing Page" />
          <meta
            name="description"
            content="Maximize your tech experience with TechXR."
          />
          <link rel="stylesheet" type="text/css" href="./landing.css" />
        </Helmet>
      </HelmetProvider>

      <div className="sencexr-controler-main mx-w-1440 landing-page-1">
        <div className="sencexr-controler-inner">
          <div className="controller-banner">
            <div className="banner-block-main">
              <div className="some-content py-100">
                <div className="common-container">
                  <div className="inner-content-block">
                    <h1>
                      Become iHUB-IIT Roorkee Certified
                      <span className="text-dark-blue ">
                        Metaverse Creator/Developer
                      </span>
                    </h1>
                    <p>
                      Master the art of Metaverse creation from scratch
                      (CODING/NON-CODING)
                    </p>
                  </div>
                </div>
              </div>
              <div className="controller__video p-relative">
                <div className="common-container white_bg">
                  <video autoPlay muted={muted} loop id="myVideo">
                    <source src={myVar2} />
                  </video>
                  <div className="common-container">
                    <button
                      onClick={handleToggleMute}
                      className="controlButton"
                    >
                      <img
                        src={
                          muted
                            ? "https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/LrrCUNDJQlcnR6nB_2023-02-06T124848044546.Mute icon1.png"
                            : "https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/1wdESz3mLAvtnNC3_2023-02-06T124818065124.Unmute icon1.png"
                        }
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>

              <div className="program-logo white_bg py-50">
                <div className="common-container">
                  <div className="common-row">
                    <div className="common-col-4">
                      <div className="logo_program">
                        <img
                          src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/jHLM3RdXvSXCzpGa_2023-02-23T073157947847.unity.png"
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="common-col-4">
                      <div className="logo_program">
                        <img
                          src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/zBRM5q8OSHdX7TB0_2023-02-23T073801534914.csharp.png"
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="common-col-4">
                      <div className="logo_program">
                        <img
                          src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/9zYZamd49z3jqyhu_2023-02-23T073257686996.vuforia_logo.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="controller-bottom">
              <div className="controller_bg"></div>
            </div>
          </div>
        </div>

        <div className="course-short-details pt-100">
          <div className="common-container">
            <div className="inner-content-block">
              <h1>
                Path to Become
                <span className="text-dark-blue ">Metaverse Developer</span>
              </h1>
              <p>Every skill that you need to succeed</p>
            </div>

            <div className="jourey-stucture">
              <ul>
                <li>
                  <div className="span-before"></div>
                  <img
                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/SlSf5UH34MLk3PCx_2023-06-07T120911145161.Vectorbg.png"
                    alt=""
                    className="shape-img"
                  />
                  <div className="list-content">
                    <p>
                      <strong>12 weeks </strong>course
                    </p>
                  </div>
                </li>
                <li>
                  <div className="span-before"></div>
                  <img
                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/SlSf5UH34MLk3PCx_2023-06-07T120911145161.Vectorbg.png"
                    alt=""
                    className="shape-img"
                  />
                  <div className="list-content">
                    <p>
                      2 <strong>AR Industry-ready</strong> Projects.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="span-before"></div>
                  <img
                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/SlSf5UH34MLk3PCx_2023-06-07T120911145161.Vectorbg.png"
                    alt=""
                    className="shape-img"
                  />
                  <div className="list-content">
                    <p>
                      5 <strong>VR Industry-ready</strong> Projects.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="span-before"></div>
                  <img
                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/SlSf5UH34MLk3PCx_2023-06-07T120911145161.Vectorbg.png"
                    alt=""
                    className="shape-img"
                  />
                  <div className="list-content">
                    <p>
                      {" "}
                      <strong>Patented Hardware.</strong>{" "}
                    </p>
                  </div>
                </li>
                <li>
                  <div className="span-before"></div>
                  <img
                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/SlSf5UH34MLk3PCx_2023-06-07T120911145161.Vectorbg.png"
                    alt=""
                    className="shape-img"
                  />
                  <div className="list-content">
                    <p>
                      <strong>1:1 Live Doubt clearing </strong> support.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="span-before"></div>
                  <img
                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/SlSf5UH34MLk3PCx_2023-06-07T120911145161.Vectorbg.png"
                    alt=""
                    className="shape-img"
                  />
                  <div className="list-content">
                    <p>
                      <strong>100%</strong> job assistance
                    </p>
                  </div>
                </li>

                <li>
                  <div className="span-before"></div>
                  <img
                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/SlSf5UH34MLk3PCx_2023-06-07T120911145161.Vectorbg.png"
                    alt=""
                    className="shape-img"
                  />
                  <div className="list-content">
                    <p>
                      Weekly <strong> Live Online Classes</strong>
                    </p>
                  </div>
                </li>

                <li>
                  <div className="span-before"></div>
                  <img
                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/SlSf5UH34MLk3PCx_2023-06-07T120911145161.Vectorbg.png"
                    alt=""
                    className="shape-img"
                  />
                  <div className="list-content">
                    <p>
                      <strong> 24*7 Available </strong>pre-recorded Videos
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="new_courese_detail_block pb-50">
              <div className="inner-content-block pb-50">
                <h2>
                  Our
                  <span className="text-dark-blue ">courses</span>
                </h2>
              </div>
              <div className="common-row flex-box-new-design">
                <div className="common-col-4 common-md-6 common-xs-12">
                  <div className="new-course_inner">
                    <h3>Intermediate Course</h3>
                    <ul>
                      <li>
                        <span>Course Duration:</span> <strong>8 Weeks</strong>
                      </li>
                      <li>
                        <span>Number Of AR Projects:</span>{" "}
                        <strong>2 Projects</strong>
                      </li>
                      <li>
                        <span>Number Of VR Projects:</span>{" "}
                        <strong>2 Projects</strong>
                      </li>
                      <li>
                        <span>Hardware included</span> <strong>Yes</strong>
                      </li>
                    </ul>
                    <div className="price_box">
                      <strong>₹ 35000 /-</strong>
                    </div>
                    <div className="check-price">
                      <a href="courses/1">enquire now</a>
                    </div>
                  </div>
                </div>

                <div className="common-col-4 common-md-6 common-xs-12">
                  <div className="new-course_inner">
                    <h3>Advance Course</h3>
                    <ul>
                      <li>
                        <span>Course Duration:</span> <strong>12 Weeks</strong>
                      </li>
                      <li>
                        <span>Number Of AR Projects</span>{" "}
                        <strong>2 Projects</strong>
                      </li>
                      <li>
                        <span>Number Of VR Projects:</span>{" "}
                        <strong>4 Projects</strong>
                      </li>
                      <li>
                        <span>Hardware included</span> <strong>Yes</strong>
                      </li>
                    </ul>
                    <div className="price_box">
                      <strong>₹ 45000 /-</strong>
                    </div>
                    <div className="check-price">
                      <a href="courses/2">enquire now</a>
                    </div>
                  </div>
                </div>

                <div className="common-col-4 common-md-6 common-xs-12">
                  <div className="new-course_inner">
                    <h3>Expert Course</h3>
                    <ul>
                      <li>
                        <span>Course Duration:</span> <strong>16 Weeks</strong>
                      </li>
                      <li>
                        <span>Number Of AR Projects</span>{" "}
                        <strong>2 Projects</strong>
                      </li>
                      <li>
                        <span>Number Of VR Projects:</span>{" "}
                        <strong>4 Projects + 1 Metaverse</strong>
                      </li>
                      <li>
                        <span>Hardware included</span> <strong>Yes</strong>
                      </li>
                    </ul>
                    <div className="price_box">
                      <strong>₹ 75000 /-</strong>
                    </div>
                    <div className="check-price">
                      <a href="courses/3">enquire now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="NewLogForm pt-100 ">
          <div className="common-container">
            <div className="NewLogForm-main">
              <div className="some-content transprent_bg pb-50">
                <div className="common-container">
                  <div className="inner-content-block">
                    <h2>
                      Join The World's Top{" "}
                      <span className="text-dark-blue ">1%</span> Metaverse
                      Creators
                    </h2>
                    <p>Allow us to connect with you</p>
                  </div>
                  <BookCallFormMainComp
                    name={""}
                    email={""}
                    mobile={""}
                    source={"twitterAdvertisement"}
                  />
                </div>
              </div>

              <div className="common-container">
                <div className="form-inner"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="compnent-4 py-100  usecase-main">
          <div className="inner-content-block mb-50">
            <h2>
              Controller Usecases
              <span className="text-dark-blue "></span>
            </h2>
            <p>Increasing Scope of AR & VR amongst different Industries</p>
          </div>

          <div className="usecase-blocK common-container">
            <div className="common-row usecase_inner">
              {projectsList?.map((project, key) => {
                return (
                  <div
                    className="common-col-3 common-md-4 common-xs-12 mb-20"
                    key={key}
                  >
                    <div className="video_sectio_block">
                      <button
                        className="dropdown-item usecase__btn"
                        data-bs-toggle="modal"
                        data-bs-target="#youtubeVideoModal"
                        onClick={() =>
                          setProjectData(
                            project.videoUrl,
                            project.categoryName ? project.categoryName : ""
                          )
                        }
                      >
                        <img src={project.imageUrl} alt="StudentProjectImage" />
                        <h2>
                          {project.categoryName ? project.categoryName : ""}
                        </h2>
                        <p>{project.description ? project.description : ""}</p>
                        {/* {console.log(project.videoUrl + "vd url student project >>")} */}
                      </button>
                    </div>
                  </div>
                );
              })}
              <YoutubeVideoModal ref={videoModalRefsecond} />
            </div>

            <div className="bottom-usecase-button">
              <Link to="/courses">
                Explore our Courses <i className="arrow"> </i>
              </Link>
              <Link to="/ourapps">
                Explore our Apps <i className="arrow"> </i>
              </Link>
              {/* <a
                target="_blank"
                href="https://www.amazon.in/dp/B0BNKRMVL7"
                rel="noreferrer"
              >
                Buy Now <i className="arrow"> </i>
              </a> */}
            </div>
          </div>
          <div className="start-learning-section pt-50">
            <a href="#exampleModal5" data-bs-toggle="modal">
              Start Learning
            </a>
          </div>
        </div>

        {/* <WhyUsMainComp /> */}

        <div className="what_is_xr py-100 bg-light">
          <div className="inner-content-block mb-50">
            <h2>
              Our Educational
              <span className="text-dark-blue ">Partners</span>
            </h2>
          </div>
          <div className="common-container">
            <OurPartnersComp />
            <div className="start-learning-section pt-50">
              <a href="#exampleModal5" data-bs-toggle="modal">
                Start Learning
              </a>
            </div>
          </div>
        </div>

        <div className="Testimonies py-100 ">
          <div className="inner-content-block mb-50">
            <h2>
              Testimonies
              <span className="text-dark-blue "></span>
            </h2>
            <p>Hear it from Our students Itself</p>
          </div>
          <div className="common-container">
            <div className="test_section">
              <TestimoniesCardsComp />
            </div>
          </div>
        </div>

        <div className="Graph-main py-100 bg-light">
          <div className="common-container">
            <div className="inner-content-block pb-50 pb-xs-20 ">
              <h1>
                Metaverse/AR-VR{" "}
                <span className="text-dark-blue ">Market exponential</span>{" "}
                growth
              </h1>
              <p>Be an Early-Mover and grab your dream job</p>
            </div>
          </div>

          <div className="common-container">
            <div className="graph-section">
              <div className="common-row d-flex-center-center ">
                <div className="common-col-6 common-md-6 common-xs-12">
                  <div className="graph_img">
                    <img
                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/728wOuZO6HmocPnO_2023-02-24T052133555578.WhatsApp Image 2023-02-24 at 10.51.05 AM.jpeg"
                      alt=""
                    />
                  </div>
                </div>

                <div className="common-col-6 common-md-6 common-xs-12">
                  <div className="graph_List">
                    <ul>
                      <li>
                        Currently over 26 lac AR-VR related Jobs worldwide
                      </li>
                      <li>75 lac jobs by 2025</li>
                      <li>Domain to witness exponential growth</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="start-learning-section pt-50">
                <a href="#exampleModal5" data-bs-toggle="modal">
                  Start Learning
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="my_buynow start_learn">
          <a
            className="openModal"
            href="#exampleModal5"
            data-bs-toggle="modal"
            style={{
              background: "linear-gradient(277deg, #2675ba 26%, #63bee4 80%)",
              margin: "0 0 40px 0 !important",
              padding: "0 25px",
              cursor: " pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
              textTransform: "capitalize",
              transition: "0.5s all ease",
              position: "fixed",
              zIndex: "150 ",
              bottom: "10px",
              textAlign: "center",
              height: "50px",
              borderRadius: "25px",
              backgroundColor: "#2cabde",
              right: "55px",
            }}
          >
            <span> Sign-Up Now</span>
          </a>
        </div>

        <div
          className="NewLogForm-main newpopup modal fade"
          id="exampleModal5"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel5"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              {/* <div className="modal-header">
                <GrClose
                  type="button"
                  id="closeModal"
                  data-bs-dismiss="modal"
                />
              </div> */}
              <div className="modal-body">
                <BookCallFormMainComp
                  name={""}
                  email={""}
                  mobile={""}
                  source={"twitterAdvertisement"}
                />
              </div>
            </div>
          </div>

          <button
            style={{ display: "none" }}
            type="button"
            id="closeModal"
            data-bs-dismiss="modal"
          ></button>
        </div>
      </div>
    </>
  );
};

export default LandingPageTwitter;
