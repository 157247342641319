export class HomePageTestimoniesResponse extends Array<HomePageTestimoniesResponse> {
  constructor() {
    super();
    this.id = 0;
    this.name = "";
    this.title = "";
    this.testimonial = "";
    this.imageUrl = "";
  }
  public id: number;
  public name: string;
  public title: string;
  public testimonial: string;
  public imageUrl: string;
}
