import * as React from "react";
import "../../../assets/css/main.css";
import { ContentServices } from "../../../apiServices/Services/ContentServices";
import { SdkResponse } from "../../../apiServices/Models/WebsiteContent/SdkModels/Sdkmodels";
import { Helmet, HelmetProvider } from "react-helmet-async";

const contentService = new ContentServices();
const getFaqData = (): SdkResponse[] => {
  return contentService.getAllsdk();
};

interface AllSdkDownalod {}

export const SdkDownalod: React.FunctionComponent<AllSdkDownalod> = (
  props: any
) => {
  const [sdkList, setsdkLists] = React.useState<SdkResponse[]>(getFaqData());

  const getFaqStucture = (id: string) => {
    if (id) {
      var intId: number = +id + 1;
      return "faq-" + intId;
    }
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>TechXR - SDK Download</title>
          <meta name="title" content={sdkList[0]?.question} />
          <meta name="description" content={sdkList[0]?.answer} />
          <meta
            property="image"
            content="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/uD41orAQL8hE2zva_2023-05-30T064448436786.Img web.png"
          />
        </Helmet>
      </HelmetProvider>
      <div className="sdk-download py-100">
        <div className="common-container">
          <div className="sdk-download-inner">
            <div className="common-heading-home ">
              <h2 className=" text-center">
                <span>SDK</span> Download
              </h2>
            </div>

            {sdkList?.map((sdk) => (
              <div className="collapse_main" key={sdk.id}>
                <input type="checkbox" id={getFaqStucture(sdk.id)} />
                <div
                  className="collapse__title"
                  dangerouslySetInnerHTML={{ __html: sdk.question }}
                ></div>

                <div className="collapse_body">
                  {sdk.answer ? (
                    <div
                      className="collapse_body_inner"
                      dangerouslySetInnerHTML={{ __html: sdk.answer }}
                    ></div>
                  ) : (
                    <div className="clear"></div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
