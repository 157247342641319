import * as React from "react";

interface OurCoursesLeftCompProps {}

export const OurCoursesLeftComp: React.FunctionComponent<
  OurCoursesLeftCompProps
> = (props) => {
  return (
    <div className="courses-block-left w-25 mt-20 px-15">
      <div className="common-heading-home">
        <h2>
          <span>Our</span> <br />
          Courses
        </h2>
      </div>

      <div className="our-courses-block-text">
        TechXR Innovations provides AR-VR App Development Training with the
        world’s most affordable Developer Kit for 6 Degrees of freedom
        interactive XR App Development on a smartphone without any expensive
        hardware.
      </div>
      <div className="see-all-courses">
        <a href="/courses">
          <span>See All Courses</span>
          <i className="arrow"></i>
        </a>
        {/* <img src={ArrowIcon} className="arrow" alt="Arrow-Icon" /> */}
      </div>
    </div>
  );
};
