import * as React from "react";
import toast from "react-hot-toast";
import { ProductSummary } from "../../../../apiServices/Models/Products/ProductSummaryModel";
import { ProductService } from "../../../../apiServices/Services/ProductServices";
import { toggleLoader } from "../../../../apiServices/Services/CommonServices";
import SingleCardCompo from "./SingleCardCompo";

const objProductService = new ProductService();

interface ProductsCardsCompProps {}

const AllProductsCardsComp: React.FunctionComponent<ProductsCardsCompProps> = (
  props
) => {
  const [productList, setProducts] = React.useState<ProductSummary[]>([]);
  const [isLoading, setLoader] = React.useState(false);

  let allProducts = Array<ProductSummary>();

  const getAllProducts = () => {
    setLoader(true);
    const res = objProductService.getAllProducts();

    res.subscribe({
      next: (response: ProductSummary[]) => {
        response.map((item: ProductSummary) => {
          return allProducts.push(item);
        });
        setProducts(allProducts);
      },
      error: (err) => {
        if (err.response.data) {
          toast.error(err.response.data);
        } else if (err.response.status === 500) {
          toast.error("Service unavailable.");
        } else {
          toast.error("Something went wrong, please try again!");
        }
      },
      complete: () => {
        setLoader(false);
      },
    });
  };

  React.useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <>
      {toggleLoader(isLoading)}
      {productList.map((product, key) => {
        return <SingleCardCompo key={key} product={product} />;
      })}
    </>
  );
};

export default AllProductsCardsComp;
