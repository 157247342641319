import * as React from "react";
import SwiperCore, { EffectFade, FreeMode, Navigation, Thumbs } from "swiper";

import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/effect-fade";
import { Swiper, SwiperSlide } from "swiper/react";
import { ProductMedia } from "../../../../apiServices/Models/Products/ProductMediaModel";
import {
  getYouTubeEmbedLink,
  getYoutubeVideoId,
  isImage,
} from "../../../../apiServices/Services/CommonServices";

interface ProductSliderProps {
  mediaDto: ProductMedia[];
}

const ProductSlider: React.FunctionComponent<ProductSliderProps> = (props) => {
  const [thumbsSwiper, setThumbsSwiper] = React.useState<SwiperCore>();
  const mediaDto = props.mediaDto;

  const getProductImageSlide = (
    mediaDto: ProductMedia[],
    isThumbnail: boolean
  ) => {
    let imageUrl: string = "";
    const slidesArray: JSX.Element[] = [];
    if (mediaDto) {
      mediaDto = reorderListForThumbnail(mediaDto);
      mediaDto.map((media, key) => {
        if (media.document) {
          imageUrl = media.document.url;
          if (isImage(imageUrl)) {
            slidesArray.push(
              <SwiperSlide key={key}>
                {<img src={imageUrl} alt="project" key={key} />}
              </SwiperSlide>
            );
          } else {
            if (!isThumbnail) {
              slidesArray.push(
                <SwiperSlide key={key}>
                  {" "}
                  {
                    <iframe
                      width="100%"
                      height="500px"
                      src={getYouTubeEmbedLink(imageUrl)}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  }
                </SwiperSlide>
              );
            } else {
              slidesArray.push(
                <SwiperSlide key={key}>
                  {" "}
                  {
                    <img
                      src={
                        "https://img.youtube.com/vi/" +
                        getYoutubeVideoId(imageUrl) +
                        "/0.jpg"
                      }
                      alt="project"
                      key={key}
                    />
                  }
                </SwiperSlide>
              );
            }
          }
        }
        return (imageUrl = "");
      });
    } else {
      imageUrl = "";
    }
    return slidesArray;
  };

  return (
    <>
      <Swiper
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        effect={"fade"}
        fadeEffect={{ crossFade: true }}
        modules={[FreeMode, Navigation, Thumbs, EffectFade]}
        className="mySwiper2"
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
          },
          1024: {
            slidesPerView: 1,
          },
        }}
      >
        {getProductImageSlide(mediaDto, false)}
      </Swiper>
      <Swiper
        style={{ width: "100%" }}
        onSwiper={setThumbsSwiper}
        // loop={true}
        spaceBetween={0}
        slidesPerView={mediaDto.length}
        // freeMode={true}
        watchSlidesProgress={true}
        modules={[Thumbs]}
        className="mySwiper"
      >
        {getProductImageSlide(mediaDto, true)}
      </Swiper>
    </>
  );
};

export default ProductSlider;
function reorderListForThumbnail(mediaDtoList: ProductMedia[]): ProductMedia[] {
  let i: number = 0;
  for (; i < mediaDtoList.length; i++) {
    if (mediaDtoList[i].thumbnail) {
      //  Swap the element with first, and break the loop
      let temp: ProductMedia = mediaDtoList[i];
      mediaDtoList[i] = mediaDtoList[0];
      mediaDtoList[0] = temp;
      break;
    }
  }
  return mediaDtoList;
}
