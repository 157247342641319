import * as React from "react";

interface EventsLeftCompProps {}

const EventsLeftComp: React.FunctionComponent<EventsLeftCompProps> = (
  props
) => {
  return (
    <>
      {/* // <!--compnent-7-block Start--> */}
      <div className="compnent-7-block">
        {/* <!--compnent-7-block left Start--> */}
        <div className="compnent-7-block-left">
          {/* <!--Our Courses Title Start--> */}
          <div className="common-heading-home mt-20">
            <h2>
              <span>Our</span> <br />
              Events
            </h2>
          </div>
          {/* <!-- Our Courses Title End--> */}

          {/* <!--Our Courses Block Text Start--> */}

          <div className="compnent-7-block-text">
            TechXR Innovations organizes multiple online and offline events all
            over India which includes Seminars, Webinars, Workshops, Experience
            Zone, etc.
          </div>
          {/* <!--Our Courses Block Text End--> */}

          {/* <!--See All Events Start--> */}

          <div className="see-all-events">
            <a href="/events">
              <span>See All Events</span>
              <i className="arrow"></i>
            </a>
          </div>

          {/* <!--See All Events  End--> */}
        </div>

        {/* <!--Compnent 7 Block left End--> */}
      </div>
    </>
  );
};

export default EventsLeftComp;
