import * as React from "react";
import { EventsCardsComp } from "./EventsCardsComp";
import { ContentServices } from "../../../../apiServices/Services/ContentServices";
import EventsLeftComp from "./EventsLeftComp";
import { EventDetailsResponse } from "../../../../apiServices/Models/WebsiteContent/EventPageModels/EventPageMainResponse";

const contentService = new ContentServices();

interface EventsMainCompProps {
  id: string | undefined;
}

export const EventsMainComp: React.FunctionComponent<EventsMainCompProps> = (
  props
) => {
  // const [eventsList, setEvents] = React.useState<HomePageEventsResponse[]>([]);
  // const getEventsData = (): HomePageEventsResponse[] => {
  //   // console.log(contentService.getAllCourses());
  //   return contentService.getAllEvents();
  // };
  // // console.log(getCoursesData());
  // React.useEffect(() => {
  //   // setCourses(getCoursesData());
  //   // console.log(coursesList);
  //   setEvents(getEventsData());
  // }, [setEvents]);
  const [eventsList, setEvents] = React.useState<EventDetailsResponse[]>([]);
  const [isLoading, setLoader] = React.useState(false);
  const getEventsData = (): EventDetailsResponse[] => {
    setLoader(true);
    // console.log(contentService.getAllCourses());
    return contentService.getAllEvents();
  };
  // console.log(getCoursesData());
  React.useEffect(() => {
    // setCourses(getCoursesData());
    // console.log(coursesList);
    setEvents(getEventsData());
    setLoader(false);
  }, [setEvents]);

  return (
    <div className="compnent-7 py-100">
      <div className="common-container">
        <div className="common-row">
          <div className="common-col-3 common-sm-12">
            <EventsLeftComp />
          </div>
          <div className="common-col-9 common-sm-12">
            <EventsCardsComp
              eventsList={eventsList}
              limit={3}
            ></EventsCardsComp>
          </div>
        </div>
      </div>
    </div>
  );
};
